import axios from "axios";
import {
  getCognitoJWTTokens,
  getProductJWTTokens,
  refreshCognitoJWTTokens,
  refreshProductJWTTokens,
} from "./authHelpers";

const isTokenExpired = (token, type = "cognito") => {
  if (token) {
    const expiry = (JSON.parse(atob(token.split(".")[1])).exp * 1000) - 1000 * 60 * 3;
    const currentTime = Math.floor(new Date().getTime());

    return currentTime >= expiry;
  }

  return true;
};

const setJWTSessionToken = async (token) => {
  const baseUrl = localStorage.getItem("API_URL");
  if (baseUrl) {
    await axios({
      url: `${baseUrl}/DPFAPI/UserRequest`,
      method: "post",
      data: {
        actionType: "SetJWTSessionToken",
        token,
      },
    });
  }
};

const axiosDPFAPIClient = axios.create({
  baseURL: localStorage.getItem("API_URL"),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosDPFAPIClient.interceptors.request.use(async (config) => {
  // set access tokens to the request if they are available
  // precedence is product token -> cognito token

  let { accessToken: cognitoToken } = getCognitoJWTTokens();
  let { accessToken: productToken } = getProductJWTTokens();

  if (cognitoToken && isTokenExpired(cognitoToken, "cognito")) {
    await refreshCognitoJWTTokens();
    cognitoToken = getCognitoJWTTokens().accessToken;
  }

  const hasValidCognitoToken =
    cognitoToken && !isTokenExpired(cognitoToken, "cognito");
  const hasExpiredProductToken =
    productToken && isTokenExpired(productToken, "product");

  if (hasValidCognitoToken && hasExpiredProductToken) {
    await refreshProductJWTTokens();
    productToken = getProductJWTTokens().accessToken;
    console.log("Product token expired. Refreshed");

    await setJWTSessionToken(productToken);
    console.log("Set product token and session");
  }

  if (productToken) {
    config.headers = {
      ...config.headers,
      Authorization: productToken,
    };
    return config;
  }

  if (cognitoToken) {
    config.headers = {
      ...config.headers,
      Authorization: cognitoToken,
    };
    return config;
  }

  return config;
});

export default axiosDPFAPIClient;
