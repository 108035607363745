import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  connectToWebSocket,
  saveSocketConnection,
  getWebSocketConnectionId,
  disconnectWebSocket,
} from "../../Actions/WebSocketActions/WebSocketActions";

export const WebSocketConnector = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  const isProductionSigned = useSelector(
    (state) => state.user.loginAPIGatewayProductionSuccess
  );

  const isWebSocketConnected = useSelector(
    (state) => state.webSocket.webSocketConnectSuccess
  );

  const isWebSocketDisconnected = useSelector(
    (state) => state.webSocket.webSocketDisconnected
  );

  const message = useSelector(
    (state) => state.webSocket.webSocketMessageReceived
  );

  const getModuleFromLocation = () => {
    switch (location?.pathname?.toLowerCase()) {
      case "/timecards":
        return "timecard";
    }

    return "";
  };

  const isTokenExpired = (token, type = "cognito") => {
    if (token) {
      const expiry = JSON.parse(atob(token.split(".")[1])).exp;
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    }
    return true;
  };

  const hasValidUseSession = () => {
    const productAccessToken = localStorage.getItem("PRODUCT_ACCESS_TOKEN");
    const cognitoAccessToken = localStorage.getItem("COGNITO_ACCESS_TOKEN");

    if (productAccessToken && cognitoAccessToken) {
      if (!isTokenExpired(productAccessToken, "product")) {
        return true;
      }
    }

    return false;
  };

  const connect = () => {
    setTimeout(() => {
      dispatch(connectToWebSocket());
    }, 3000);
  };

  const handleMessages = () => {
    if (message?.action === "getConnection") {
      setTimeout(() => {
        const module = getModuleFromLocation();
        dispatch(saveSocketConnection(message.connectionId, module));
      }, 3000);
    }
  };

  useEffect(() => {
    if (isProductionSigned) {
      dispatch(connectToWebSocket());
    }
  }, [isProductionSigned]);

  useEffect(() => {
    if (isWebSocketConnected) {
      dispatch(getWebSocketConnectionId());
      console.log("Websocket connected");
    }
  }, [isWebSocketConnected]);

  useEffect(() => {
    if (isWebSocketDisconnected) {
      if (hasValidUseSession()) {
        console.log("Websocket disconnected. Auto reconnecting...");
        connect();
      }
    }
  }, [isWebSocketDisconnected]);

  useEffect(() => {
    handleMessages();
  }, [message]);

  useEffect(() => {
    if (hasValidUseSession()) {
      console.log("Auto connecting to WebSocket...");
      connect();
    }

    return function disconnect() {
      dispatch(disconnectWebSocket());
    };
  }, []);

  return null;
};
