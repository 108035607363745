import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import DisplayOrdersMoreDetails from "../DisplayOrdersMoreDetails/DisplayOrdersMoreDetails";
import { updatePoApprovalGroup } from "../../../Actions/POActions/POActions";
const OrdersMoreDetails = (props) => {
  const poData = useSelector((state) => state.poData);
  const [openOrderDisplay, setOpenOrderDisplay] = useState(false);
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let { showPOTallisTabPane, closeModal, updatePoSummaryState } = props;
  showPOTallisTabPane = showPOTallisTabPane
    ? showPOTallisTabPane.toLowerCase()
    : "";
  const dispatch = useDispatch();
  // changing approver group from  aproval select ,approval options is empty so couldnot figure out its options
  const periodOption = () => {
    if (props.state.approvalOptions?.length > 0) {
      let newArray = [];
      props.state.approvalOptions.map((item) =>
        newArray.push({ value: item.groupName, label: item.groupName })
      );
      return newArray;
    }
  };
  
  const handleChangeType = (data) => {
    setState((prev) => ({
      ...prev,
      approvalGroup: data,
    }));
  };
  // console.log(poData.updatePoApprovalGroupSuccess);
  // console.log(poData.updatePoApprovalGroupError);
  useEffect(()=>{
    if (poData.updateApprovalGroupSuccess){
      setIsLoading(false);
      closeModal("openOrdersMoreDetailsModal")
    }
  },[poData.updateApprovalGroupSuccess]);


  const onSave = async (e) => {
    e.preventDefault();

    let poSummary = {
      approvalGroup: state.approvalGroup.value,
      guid: state.selectedGuid,
    };

    // setIsLoading(true);

    await dispatch(updatePoApprovalGroup(poSummary));

    updatePoSummaryState();

    let { getPOList, activePO } = props.state;
    let activePoToUpdate = getPOList.find((fi) => fi.id === activePO);

    await props.getPO(activePoToUpdate, true);
    closeModal("openOrdersMoreDetailsModal");

    // setIsLoading(false);
  };

  const onChangeHandlerDate = (date) => {
    setState((prev) => ({
      ...prev,
      poDate: new Date(date).getTime(),
    }));
  };
  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...props?.state,
    }));
  }, [props]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={state?.openOrdersMoreDetailsModal}
        onHide={() => closeModal("openOrdersMoreDetailsModal")}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col-auto pl-0">
                            <h6 className="text-left def-blue">
                              Order Details
                            </h6>
                          </div>
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={onSave}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button>
                            <button
                              onClick={() =>
                                props.closeModal("openOrdersMoreDetailsModal")
                              }
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>
                            <Dropdown
                              alignRight="false"
                              drop="down"
                              className="border-0"
                            >
                              <Dropdown.Toggle id="dropdown-basic-two">
                                <img
                                  src="images/more.png"
                                  className="img-fluid"
                                  alt="user"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  variant="success"
                                  onClick={() => setOpenOrderDisplay(true)}
                                >
                                  <button
                                    type="button"
                                    className="btn-save def-blue"
                                  >
                                    <img
                                      src="images/display-icon.png"
                                      className="mr-2"
                                      alt="display-icon"
                                    />
                                    Display
                                  </button>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className=" col-12">
                          <div className="form-group custon_select">
                            <label htmlFor="id_dt">Date</label>
                            <div className="modal_input datePickerUP">
                              <DatePicker
                                name="poDate"
                                id="periodEndingDate"
                                tabIndex="3334"
                                dateFormat="d MMM yyyy"
                                autoComplete="off"
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandlerDate
                                    : null
                                }
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : ""
                                }
                                disabled
                                selected={ state && state?.poDate ?  new Date(state.poDate) : ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Approval Group</label>
                            <Select
                              className={
                                showPOTallisTabPane !== "draft"
                                  ? "disable_bg disable_border"
                                  : "width-selector"
                              }
                              value={state?.approvalGroup}
                              classNamePrefix="custon_select-selector-inner"
                              options={periodOption()}
                              onChange={(e) =>
                                showPOTallisTabPane === "draft"
                                  ? handleChangeType(e)
                                  : ""
                              }
                              isDisabled={showPOTallisTabPane !== "draft"}
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>PO# </label>
                            <div className="modal_input">
                              <input
                                name="poNumber"
                                type="text"
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="poNumber"
                                value={state?.poNumber || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Requested By</label>
                            <div className="modal_input">
                              <input
                                name="requestedBy"
                                type="text"
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="requestedBy"
                                value={state?.requestedBy || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Email Supplier</label>
                            <div className="modal_input">
                              <input
                                type="text"
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                value={state?.supplierEmail || ""}
                                name="supplierEmail"
                                id="po-email"
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Tran</label>
                            <div className="modal_input">
                              <input
                                name="tran"
                                type="text"
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="potran"
                                value={state?.tran || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        {/* department  */}
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Department</label>
                            <div className="modal_input">
                              <input
                                type="text"
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                name="department"
                                value={state?.requestedDepartment || ""}
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                                id="po-department"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Description</label>
                            <div className="modal_input">
                              <input
                                name="orderDescription"
                                type="text"
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="po-desc"
                                value={state?.orderDescription || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>SupplierCode</label>
                            <div className="modal_input">
                              <input
                                name="supplierCode"
                                type="text"
                                className={
                                  showPOTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="po-suppliercode"
                                value={state?.supplierCode || ""}
                                onChange={
                                  showPOTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DisplayOrdersMoreDetails
        data={props}
        setOpenOrderDisplay={setOpenOrderDisplay}
        openOrderDisplay={openOrderDisplay}
      />
    </>
  );
};
export default OrdersMoreDetails;
