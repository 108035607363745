import axiosAPIGatewayClient from "../../Utils/axiosAPIGatewayClient";

let wsClient;

export const connectToWebSocket = () => async (dispatch) => {
  wsClient = new WebSocket(localStorage.getItem("WEBSOCKET_API_URL"));

  wsClient.onopen = function open() {
    dispatch({
      type: "WEB_SOCKET_CONNECTED",
    });
  };

  wsClient.onclose = function close() {
    dispatch({
      type: "WEB_SOCKET_DISCONNECTED",
    });
  };

  wsClient.onmessage = function incoming(event) {
    dispatch({
      type: "WEB_SOCKET_MESSAGE_RECEIVED",
      payload: JSON.parse(event.data),
    });
  };
};

export const disconnectWebSocket = () => async (dispatch) => {
  if (wsClient?.readyState === WebSocket.OPEN) {
    wsClient.close();
  }
};

export const getWebSocketConnectionId = () => (dispatch) => {
  wsClient.send(
    JSON.stringify({
      action: "getConnection",
    })
  );
  dispatch({
    type: "SOCKET_GET_CONNECTION_SUCCESS",
  });
};

export const saveSocketConnection =
  (connectionId, module) => async (dispatch) => {
    const url = "web-socket/connection";
    const payload = {
      connectionId: connectionId,
      module: module,
    };

    try {
      const response = await axiosAPIGatewayClient.post(url, payload);
      if (response && response.data) {
        dispatch({
          type: "SOCKET_CONNECTION_SAVED_SUCCESS",
        });
      }
    } catch (ex) {
      console.log("Save socket connection error ", ex);
      dispatch({
        type: "SOCKET_CONNECTION_SAVED_FAILED",
        payload: ex.message ?? "Failed to login",
      });
    }
  };

export const setWebSocketActiveModule = (module) => (dispatch) => {
  const send = () => {
    wsClient.send(
      JSON.stringify({
        action: "setModule",
        message: JSON.stringify({ module: module }),
      })
    );
  };

  if (wsClient?.readyState !== WebSocket.OPEN) {
    let attempt = 0;
    const interval = setInterval(() => {
      if (attempt < 3) {
        console.log("Socket retry sending websocket message");
        if (wsClient?.readyState === WebSocket.OPEN) {
          send();
          clearInterval(interval);
        }
        attempt++;
      } else {
        console.log("Unable to active set module.");
        clearInterval(interval);
      }
    }, 1500);
  } else {
    send();
  }
};
