import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import * as POActions from "../../../Actions/POActions/POActions";
import * as InvoiceActions from "../../../Actions/InvoiceActions/InvoiceActions";
import * as ExpenseActions from "../../../Actions/ExpenseActions/ExpenseActions";

import { handleAPIErr } from "../../../Utils/Helpers";
import { toast } from "react-toastify";

class ImportLines extends Component {
  constructor() {
    super();
    this.state = {
      excelData: "",
      formErrors: {
        excelData: "",
      },
    };
  }

  handleExcelData = (e) => {
    let formErrors = this.state.formErrors;
    let value = e.target.value;
    if (value.length < 1) {
      formErrors.excelData = "This Field is Required.";
    } else {
      formErrors.excelData = "";
    }
    this.setState({
      excelData: value,
      formErrors: formErrors,
    });
  };

  onSave = async () => {
    let formErrors = this.state.formErrors;

    if (!this.state.excelData.trim()) {
      formErrors.excelData = "This Field is Required.";
    }
    this.setState({
      formErrors: formErrors,
    });
    if (!formErrors.excelData) {
      let { excelData } = this.state;
      if (this.props.import === "importexpense") {
        this.importExpense(excelData);
        this.props.closeModal("openImportLinesModal");
      } else if (this.props.import === "importinvoice") {
        this.importInvoiceLines(excelData);
        this.props.closeModal("openImportLinesModal");
      } else if (this.props.tran) {
        this.props.importPOLines(excelData);
        this.props.closeModal("openImportLinesModal");
      } else {
        await this.props.importLines(excelData);
        this.props.closeModal("openImportLinesModal");
      }
    }
  };

  onCancel = () => {
    this.setState({
      excelData: "",
      formErrors: {
        excelData: "",
      },
    });
    this.props.closeModal("openImportLinesModal");
  };

  // importPOLines = async (excelData) => {
  //   this.setState({ isLoading: true });
  //   let { tran } = this.props;
  //   if (excelData) {
  //     await this.props.importPOLines(excelData, tran);
  //   }
  //   //success case of Import PO Lines
  //   if (this.props.poData.importPOLineseSuccess) {
  //     toast.success(this.props.poData.importPOLineseSuccess);
  //     let poLines = this.props.poData.importPOLinese?.poLines || [];
  //     let subTotal = 0.0;
  //     poLines.map((poLine, i) => {
  //       poLine.errorMessageSort = "";
  //       poLine.errorMessageCode = "";
  //       poLine.errorInvalidCode = "";
  //       poLine.id = uuidv1();
  //       poLine.checked = false;
  //       poLine.amount = Number(poLine.amount).toFixed(2) || 0.0;
  //       subTotal = Number(subTotal) + Number(poLine.amount);
  //       // if (poLine.startTime && poLine.startTime != "0") {
  //       //   poLine.startTime *= 1000;
  //       // }
  //       // if (poLine.endTime && poLine.endTime != "0") {
  //       //   poLine.endTime *= 1000;
  //       // }
  //       poLine.toDate = poLine.endDate;
  //       poLine.fromDate = poLine.startDate;
  //       return poLine;
  //     });

  //     /*
  //         client- I've added a poLine with lineNo -1 to the responses for
  //         GetPOLines and GetPO to provide the custom fields for the lines.
  //         Can these be hidden on the front end so users don't accidently try to add/edit these lines?
  //       */
  //     let dummyPOLine = poLines.find((line) => line.lineNo === -1);
  //     let customFields = (dummyPOLine && dummyPOLine.customFields) || [];
  //     let filteredLines = poLines.filter((line) => line.lineNo != -1); // remove line with lineNo = -1

  //     let data = {
  //       poLines: filteredLines || [],
  //       customFields,
  //       subTotal,
  //     };

  //     this.props.closeModal("openImportLinesModal", data);
  //   }
  //   //error case of Import PO Lines
  //   if (this.props.poData.importPOLineseError) {
  //     handleAPIErr(this.props.poData.importPOLineseError, this.props);
  //   }

  //   this.props.clearPOStates();
  //   this.setState({ isLoading: false });
  // };

  importInvoiceLines = async (excelData) => {
    debugger;
    this.setState({ isLoading: true });
    let { tran } = this.props;
    if (excelData) {
      await this.props.importInvoiceLines(excelData);
    }
    //success case of Import PO Lines
    if (this.props.invoiceData.importInvoicLineseSuccess) {
      toast.success(this.props.invoiceData.importInvoicLineseSuccess);
    }
    //error case of Import PO Lines
    if (this.props.invoiceData.importInvoicLineseError) {
      handleAPIErr(this.props.invoiceData.importInvoicLineseError, this.props);
    }
    this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  importExpense = async (excelData) => {
    debugger;
    this.setState({ isLoading: true });
    let { tran } = this.props;
    if (excelData) {
      await this.props.importList(excelData);
    }
    //success case of Import PO Lines
    if (this.props.expenseData.importListSuccess) {
      toast.success(this.props.expenseData.importListSuccess);
    }
    //error case of Import PO Lines
    if (this.props.expenseData.importListError) {
      handleAPIErr(this.props.expenseData.importListError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };
  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openImportLinesModal}
          onHide={this.onCancel}
          className="forgot_email_modal modal_704 mx-auto"
        >
          <Modal.Body>
            <div className="container-fluid ">
              <div className="main_wrapper p-10">
                <div className="row d-flex h-100">
                  <div className="col-12 justify-content-center align-self-center form_mx_width">
                    <div className="forgot_form_main">
                      <div className="forgot_header">
                        <div className="modal-top-header">
                          <div className="row bord-btm">
                            <div className="col-auto pl-0">
                              <h6 className="text-left def-blue">Import</h6>
                            </div>
                            <div className="col d-flex justify-content-end s-c-main">
                              <button
                                onClick={() => this.onSave()}
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-check"></span>
                                Save
                              </button>
                              <button
                                onClick={this.onCancel}
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-ban"></span>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="forgot_body w-100">
                    <div className="row no-gutters mt-4">
                      <div className="col-12 mt-1">
                        <div className="form-group custon_select text-center mb-0 w-100 ">
                          <textarea
                            rows="4"
                            className="w-100"
                            name="comment"
                            form="usrform"
                            placeholder="Paste Spreadsheet Data Here..."
                            value={this.state.excelData}
                            onChange={this.handleExcelData}
                          />
                        </div>
                        <div className="text-danger error-12">
                          {this.state.formErrors.excelData !== ""
                            ? this.state.formErrors.excelData
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  invoiceData: state.invoice,
  poData: state.poData,
  expenseData: state.expense,
});

export default connect(mapStateToProps, {
  clearPOStates: POActions.clearPOStates,
  clearInvoiceStates: InvoiceActions.clearInvoiceStates,
  importInvoiceLines: InvoiceActions.importInvoiceLines,
  importList: ExpenseActions.importList,
  clearExpenseStates: ExpenseActions.clearExpenseStates,
})(ImportLines);
