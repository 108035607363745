import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { updateApprovalGroup } from "../../../Actions/DistChangesActions/DistChangesActions";
import { useDispatch } from "react-redux";
import DisplayDistchangeMoreDetail from "../DisplayDistchangeMoreDetail/DisplayDistchangeMoreDetail";

const DistChangesMoreDetailsModal = (props) => {
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    openDisplayDistchangeMoreDetailsModal,
    setopenDisplayDistchangeMoreDetailsModal,
  ] = useState(false);

  const {
    closeModal,
    distChangesMoreDetailsOpenModal,
    showDistChangeTabPane,
    updateDistchangeSummaryState,
    getDistchangeSummary,
  } = props;

  const dispatch = useDispatch();

  const onSave = async (e) => {
    e.preventDefault();

    let distChangeSummary = {
      approvalGroup: state.approvalGroup.value,
      id: state.id,
    };

    setIsLoading(true);

    await dispatch(updateApprovalGroup(distChangeSummary));

    updateDistchangeSummaryState();

    let { getDistchangeList, activeDistchange } = props.state;
    let activeDistchangeToUpdate = getDistchangeList.find(
      (fi) => fi.id === activeDistchange
    );

    await getDistchangeSummary(activeDistchangeToUpdate, true);
    closeModal("distChangesMoreDetailsOpenModal");

    setIsLoading(false);
  };

  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeType = (data) => {
    setState((prev) => ({
      ...prev,
      approvalGroup: data,
    }));
  };

  const periodOption = () => {
    if (props.state.approvalOptions.length > 0) {
      let newArray = [];
      props.state.approvalOptions.map((item) =>
        newArray.push({ value: item.option, label: item.option })
      );
      return newArray;
    }
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...props?.state,
    }));
  }, [props]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={distChangesMoreDetailsOpenModal}
        onHide={() => closeModal("distChangesMoreDetailsOpenModal")}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <h6 className="text-left def-blue">
                            Distchange Details
                          </h6>
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={onSave}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button>
                            <button
                              onClick={() =>
                                closeModal("distChangesMoreDetailsOpenModal")
                              }
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>

                            <div className="order-display-main ">
                              <Dropdown
                                alignRight="false"
                                drop="down"
                                className="border-0"
                              >
                                <Dropdown.Toggle id="dropdown-basic-two">
                                  <img
                                    src="images/more.png"
                                    className="img-fluid"
                                    alt="user"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    variant="success"
                                    onClick={() =>
                                      setopenDisplayDistchangeMoreDetailsModal(
                                        true
                                      )
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn-save def-blue"
                                    >
                                      <img
                                        src="images/display-icon.png"
                                        className="mr-2"
                                        alt="display-icon"
                                      />
                                      Display
                                    </button>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="form-group custon_select"></div>
                        </div>
                        {/* <div className=" col-12">
                          <div className="form-group custon_select">
                            <label htmlFor="id_dt">Date</label>
                            <div className="modal_input datePickerUP">
                              <DatePicker
                                name="poDate"
                                id="periodEndingDate"
                                tabIndex="3334"
                                dateFormat="d MMM yyyy"
                                autoComplete="off"
                                onChange={
                                  showDistChangeTabPane === "Draft"
                                    ? onChangeHandlerDate
                                    : null
                                }
                                className={
                                  showDistChangeTabPane !== "Draft"
                                    ? "disable_bg disable_border"
                                    : ""
                                }
                                disabled={showDistChangeTabPane !== "Draft"}
                                selected={state?.poDate || ""}
                              />
                            </div>
                          </div>
                        </div>  */}

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Approval Group</label>
                            <Select
                              className={
                                showDistChangeTabPane !== "Draft"
                                  ? "disable_bg disable_border"
                                  : "width-selector"
                              }
                              value={state?.approvalGroup}
                              classNamePrefix="custon_select-selector-inner"
                              options={periodOption()}
                              onChange={(e) =>
                                showDistChangeTabPane === "Draft"
                                  ? handleChangeType(e)
                                  : ""
                              }
                              isDisabled={showDistChangeTabPane !== "Draft"}
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-12"></div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>User Name </label>
                            <div className="modal_input">
                              <input
                                name="userName"
                                type="text"
                                className={
                                  showDistChangeTabPane !== "Draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="distchange-userName"
                                value={state?.userName || ""}
                                onChange={
                                  showDistChangeTabPane === "Draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={showDistChangeTabPane !== "Draft"}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Tran</label>
                            <div className="modal_input">
                              <input
                                name="tran"
                                type="text"
                                className={
                                  showDistChangeTabPane !== "Draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showDistChangeTabPane === "Draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="distchange-tran"
                                value={state?.tran || ""}
                                disabled={showDistChangeTabPane !== "Draft"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Description</label>
                            <div className="modal_input">
                              <input
                                name="description"
                                type="text"
                                className={
                                  showDistChangeTabPane !== "Draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showDistChangeTabPane === "Draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="usr"
                                value={state?.description || ""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DisplayDistchangeMoreDetail
        openDisplayDistchangeMoreDetailsModal={
          openDisplayDistchangeMoreDetailsModal
        }
        setopenDisplayDistchangeMoreDetailsModal={
          setopenDisplayDistchangeMoreDetailsModal
        }
      />
    </>
  );
};

export default DistChangesMoreDetailsModal;
