import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import DisplayInvoiceMoreDetails from "../DisplayInvoiceMoreDetails/DisplayInvoiceMoreDetails";
import { updateApprovalGroup } from "../../../Actions/InvoiceActions/InvoiceActions";
import { handleAPIErr } from "../../../Utils/Helpers";
import { toast } from "react-toastify";
const InvoiceMoreDetails = (props) => {
  const [
    openDisplayInvoiceMoreDetailsModal,
    setopenDisplayInvoiceMoreDetailsModal,
  ] = useState(false);

  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableSupplierEmail] = useState(localStorage.getItem('blockSupplier')?.toUpperCase() === 'Y')
  let { showInvoiceTallisTabPane, updateInvoiceSummaryState, closeModal } =
    props;
  const dispatch = useDispatch();
  const expenseData = useSelector((state) => state.invoice);
  showInvoiceTallisTabPane = showInvoiceTallisTabPane
    ? showInvoiceTallisTabPane.toLowerCase()
    : "";

  const periodOption = () => {
    if (props.state.approvalOptions.length > 0) {
      let newArray = [];
      props.state.approvalOptions.map((item) =>
        newArray.push({ value: item.groupName, label: item.groupName })
      );
      return newArray;
    }
  };

  const onSave = async (e) => {
    e.preventDefault();

    let invoiceSummary = {
      approvalGroup: state.approvalGroup.value,
      guid: state.selectedGuid,
      emailAddress: state.supplierEmail
    };

    setIsLoading(true);

    await dispatch(updateApprovalGroup(invoiceSummary));

    updateInvoiceSummaryState();

    let { getInvoicesList, activeInvoice } = props.state;
    let activeTimeCardToUpdate = getInvoicesList.find(
      (fi) => fi.id === activeInvoice
    );

    await props.getInvoice(activeTimeCardToUpdate, true);
    closeModal("openInvoiceMoreDetailsModal");

    setIsLoading(false);
  };

  const handleChangeType = (data) => {
    setState((prev) => ({
      ...prev,
      approvalGroup: data,
    }));
  };
  const onChangeHandlerDate = (date) => {
    setState((prev) => ({
      ...prev,
      invoiceDate: new Date(date).getTime(),
    }));
  };
  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...props?.state,
    }));
  }, [props]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openInvoiceMoreDetailsModal}
        onHide={() => props.closeModal("openInvoiceMoreDetailsModal")}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={onSave}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button>
                            <button
                              onClick={() =>
                                props.closeModal("openInvoiceMoreDetailsModal")
                              }
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>
                            <Dropdown
                              alignRight="false"
                              drop="down"
                              className="border-0"
                            >
                              <Dropdown.Toggle id="dropdown-basic-two">
                                <img
                                  src="images/more.png"
                                  className="img-fluid"
                                  alt="user"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  variant="success"
                                  onClick={() =>
                                    setopenDisplayInvoiceMoreDetailsModal(true)
                                  }
                                >
                                  <button
                                    type="button"
                                    className="btn-save def-blue"
                                  >
                                    <img
                                      src="images/display-icon.png"
                                      className="mr-2"
                                      alt="display-icon"
                                    />
                                    Display
                                  </button>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className=" col-12">
                          <div className="form-group custon_select">
                            <label htmlFor="id_dt">Date</label>
                            <div className="modal_input datePickerUP">
                              <DatePicker
                                name="invoiceDate"
                                id="invoice-Date"
                                tabIndex="3334"
                                dateFormat="d MMM yyyy"
                                autoComplete="off"
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandlerDate
                                    : null
                                }
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                disabled
                                selected={state?.invoiceDate || ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Approved</label>
                            <div className="modal_input">
                              <input
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="invoice-approved"
                                onChange={() => {}}
                                value={state?.approved || ""}
                                disabled
                                name="approved"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Approval Group</label>
                            <Select
                              className={
                                showInvoiceTallisTabPane !== "draft"
                                  ? "disable_bg disable_border"
                                  : "width-selector"
                              }
                              value={state?.approvalGroup}
                              classNamePrefix="custon_select-selector-inner"
                              options={periodOption()}
                              onChange={(e) =>
                                showInvoiceTallisTabPane === "draft"
                                  ? handleChangeType(e)
                                  : ""
                              }
                              isDisabled={showInvoiceTallisTabPane !== "draft"}
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Invoice# </label>
                            <div className="modal_input">
                              <input
                                name="invoiceNumber"
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="invoice-num"
                                value={state?.invoiceNumber || ""}
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Supplier Email</label>
                            <div className="modal_input">
                              <input
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                value={state?.supplierEmail || ""}
                                name="supplierEmail"
                                id="invoice-email"
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={disableSupplierEmail}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Supplier Name</label>
                            <div className="modal_input">
                              <input
                                name="supplierName"
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="invoice-supplier"
                                value={state?.supplierName || ""}
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Tran</label>
                            <div className="modal_input">
                              <input
                                name="tran"
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="invoice-tran"
                                value={state?.tran || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        {/* department  */}
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Department</label>
                            <div className="modal_input">
                              <input
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                name="department"
                                value={state?.department || ""}
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                                id="department"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Description</label>
                            <div className="modal_input">
                              <input
                                name="description"
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="invoice-desc"
                                value={state?.description || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>supplierCode</label>
                            <div className="modal_input">
                              <input
                                name="supplierCode"
                                type="text"
                                className={
                                  showInvoiceTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="invoice-supcode"
                                value={state?.supplierCode || ""}
                                onChange={
                                  showInvoiceTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DisplayInvoiceMoreDetails
        openDisplayInvoiceMoreDetailsModal={openDisplayInvoiceMoreDetailsModal}
        setopenDisplayInvoiceMoreDetailsModal={
          setopenDisplayInvoiceMoreDetailsModal
        }
        data={props}
      />
    </>
  );
};
export default InvoiceMoreDetails;
