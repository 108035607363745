import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CognitoUser } from "amazon-cognito-identity-js";
import { getCognitoUserPoolWrapper } from "../../Config/CognitoConfig";
import { toast } from "react-toastify";
import { setupBackendRegion } from "../../Utils/Helpers";
import PinCode from "../Modals/PinCode/PinCode";
import {
  checkCognitoStatusInTenant,
  requestPinCode,
} from "../../Actions/UserActions/UserActions";
import { useDispatch, useSelector } from "react-redux";

const passwordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})"
);

const ResetPasswordModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [userLogin, setUserLogin] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1); // 1 => initial screen, 2 => verification code and password screen
  const [cognitoUser, setCognitoUser] = useState(null);
  const [codeDeliveryData, setCodeDeliveryData] = useState("");
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(true);
  const [isPinCodeModalOpen, setIsPinCodeModalOpen] = useState(false);
  const [errors, setErrors] = useState({
    userLogin: null,
    companyID: null,
    verificationCode: null,
    password: null,
    confirmPassword: null,
  });

  /**
   * Triggers when the cognito user is created and initiate the
   * cognito forgot password flow
   */
  useEffect(() => {
    if (!cognitoUser) return;

    setIsLoading(true);
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        setCodeDeliveryData(data?.CodeDeliveryDetails?.Destination);
        setStep(2);
        setIsLoading(false);
      },
      onFailure: (err) => {
        toast.error(
          err.name === "UserNotFoundException"
            ? "Invalid user login"
            : err.message
        );
        setIsLoading(false);
      },
    });
  }, [cognitoUser]);

  /**
   * Handle pin code sent from the clarion
   */
  useEffect(() => {
    if (userState.reqPinCodeSuccess) {
      setIsResetPasswordModalOpen(false);
      setIsPinCodeModalOpen(true);
    }
    if (userState.reqPinCodeError) {
      toast.error(userState.reqPinCodeError);
    }
  }, [userState]);

  /**
   * Initiates the forgot password flow by creating the
   * cognito user
   */
  const initiateForgotPassword = async () => {
    if (!validateInput()) {
      return;
    }

    // check if the company id is valid and setup the backend urls
    const configSetupCompleted = await setupBackendRegion({companyID});
    if (!configSetupCompleted) {
      return;
    }

    const cognitoStatus = await checkCognitoStatusInTenant(companyID);
    if (!cognitoStatus || !cognitoStatus.isCognitoEnabled) {
      // cognito is disabled, continue clarion flow
      initiateNonCognitoForgotPassword();
      return;
    }

    setCognitoUser(
      new CognitoUser({
        Username: `${userLogin.toLowerCase()}_${companyID}`,
        Pool: getCognitoUserPoolWrapper(),
      })
    );
  };

  /**
   * Sends the pin code through clarion
   */
  const initiateNonCognitoForgotPassword = async () => {
    const pinCodeData = {
      actionType: "RequestPinCode",
      userLogin,
      companyID,
    };
    dispatch(requestPinCode(pinCodeData)); //call request pin code api
  };

  /**
   * Submits the new password along with the verification
   * code to Cognito
   */
  const submitNewPassword = () => {
    if (!validateInput()) {
      return;
    }

    setIsLoading(true);
    cognitoUser.confirmPassword(
      verificationCode,
      newPassword,
      {
        onSuccess: () => {
          toast.success("Updated your password successfully");
          setIsLoading(false);
          onClose();
        },
        onFailure: (err) => {
          toast.error(err.message);
          setIsLoading(false);
        },
      },
      {
        userLogin,
        companyID,
        newPassword,
        betaEnv: `${window.config?.betaEnv}`,
      }
    );
  };

  /**
   * Validate the inputs based on the step and return true if all the
   * inputs are valid and false if at least one input is invalid
   */
  const validateInput = () => {
    setErrors({
      companyID: null,
      userLogin: null,
      verificationCode: null,
      password: null,
      confirmPassword: null,
    });

    let newErrors = {};

    if (step === 1) {
      newErrors = {
        userLogin: !userLogin ? "User Login is required" : null,
        companyID: !companyID ? "Company Id is required" : null,
      };
    }

    if (step === 2) {
      newErrors = {
        verificationCode: !verificationCode
          ? "Verification code is required"
          : null,
        password: validatePassword(),
        confirmPassword:
          newPassword !== confirmPassword
            ? "Confirm password must match password"
            : null,
      };
    }

    setErrors({ ...errors, ...newErrors });

    if (Object.keys(newErrors).find((key) => newErrors[key] !== null)) {
      return false;
    }
    return true;
  };

  /**
   * Validates the password field
   */
  const validatePassword = () => {
    if (!newPassword) {
      return "Password is required";
    }

    if (!passwordRegex.test(newPassword)) {
      return "Password length should be 10 or greater and it must contain special characters, uppercase, lowercase and numbers";
    }

    return null;
  };

  return (
    <>
      {isLoading && <div className="se-pre-con"></div>}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isResetPasswordModalOpen}
        className="forgot_email_modal modal_704 mx-auto"
        backdrop="static"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col-auto pl-0">
                            <h6 className="text-left def-blue">
                              Reset Password
                            </h6>
                          </div>
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={onClose}
                              type="button"
                              className="btn-save"
                            >
                              <span className="fa fa-ban"></span>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      {step === 1 && (
                        <div className="mt-3 d-flex flex-column">
                          <div className="form-group mt-1">
                            <input
                              className="form-control"
                              name="reset_user_login"
                              placeholder="User Login"
                              value={userLogin}
                              onChange={(e) => setUserLogin(e.target.value)}
                            />
                            {errors.userLogin && (
                              <div className="text-danger error-12">
                                {errors.userLogin}
                              </div>
                            )}
                          </div>

                          <div className="form-group mt-1">
                            <input
                              className="form-control"
                              name="reset_company_id"
                              placeholder="Company Id"
                              value={companyID}
                              onChange={(e) => setCompanyID(e.target.value)}
                            />
                            {errors.companyID && (
                              <div className="text-danger error-12">
                                {errors.companyID}
                              </div>
                            )}
                          </div>

                          <button
                            disabled={isLoading}
                            onClick={initiateForgotPassword}
                            className="btn btn-primary login_blue"
                          >
                            Reset password
                          </button>
                        </div>
                      )}
                      {step === 2 && (
                        <div className="mt-3 d-flex flex-column">
                          <p>
                            Enter the verification code sent to your email{" "}
                            {codeDeliveryData ?? ""} and the new password below
                          </p>

                          <div className="form-group mt-1">
                            <input
                              className="form-control"
                              name="reset_verification_code"
                              placeholder="Verification code"
                              value={verificationCode}
                              onChange={(e) =>
                                setVerificationCode(e.target.value)
                              }
                            />
                            {errors.verificationCode && (
                              <div className="text-danger error-12">
                                {errors.verificationCode}
                              </div>
                            )}
                          </div>

                          <div className="form-group mt-1">
                            <input
                              name="reset_password"
                              className="form-control"
                              placeholder="Password"
                              type="password"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                            {errors.password && (
                              <div className="text-danger error-12">
                                {errors.password}
                              </div>
                            )}
                          </div>

                          <div className="form-group mt-1">
                            <input
                              name="reset_password_confirm"
                              className="form-control"
                              placeholder="Confirm Password"
                              type="password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                            {errors.confirmPassword && (
                              <div className="text-danger error-12">
                                {errors.confirmPassword}
                              </div>
                            )}
                          </div>

                          <button
                            disabled={isLoading}
                            onClick={submitNewPassword}
                            className="btn btn-primary login_blue"
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PinCode
        openModal={() => setIsResetPasswordModalOpen(true)}
        closeModal={() => setIsPinCodeModalOpen(false)}
        openResetPasswordModal={() => setIsResetPasswordModalOpen(true)}
        openPinCodeModal={isPinCodeModalOpen}
        userLogin={userLogin}
        companyID={companyID}
        clearStates={() => {}}
        endFlow={onClose}
      />
    </>
  );
};

export default ResetPasswordModal;
