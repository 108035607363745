import DPFClient from "../../Utils/axiosDPFAPIClient";

export const getPrimeApprovalTransfer = () => async (dispatch) => {
  dispatch({
    type: "PRIME_APPROVAL_TRANSFER_INIT",
  });

  const url = "/DPFAPI/UserRequest";

  try {
    let { data } = await DPFClient.post(url, {
      actionType: "PrimeApprovalTransfer",
    });

    dispatch({
      type: "PRIME_APPROVAL_TRANSFER_SUCCESS",
      payload: {
        userList: data.UserResponse.userList,
        modules: data.UserResponse.modules,
      },
    });
  } catch (e) {
    dispatch({
      type: "PRIME_APPROVAL_TRANSFER_FAILED",
    });
  }
};

export const getApprovalTransferTallies = (oldUser) => async (dispatch) => {
  dispatch({
    type: "GET_APPROVAL_TRANSFER_TALLIES_INIT",
  });

  const url = "/DPFAPI/UserRequest";

  try {
    let { data } = await DPFClient.post(url, {
      actionType: "GetApprovalTransferTallies",
      oldUser: oldUser,
      stage: ["Pending", "Approve"],
    });

    dispatch({
      type: "GET_APPROVAL_TRANSFER_TALLIES_SUCCESS",
      payload: {
        loading: false,
        modules: data.UserResponse.modules ?? [],
      },
    });
  } catch (e) {
    dispatch({
      type: "GET_APPROVAL_TRANSFER_TALLIES_FAILED",
    });
  }
};

//TransferApprovals
export const transferApprovals =
  (oldUser, newUser, modules) => async (dispatch) => {
    dispatch({
      type: "TRANSFER_APPROVALS_INIT",
    });

    const url = "/DPFAPI/UserRequest";

    try {
      await DPFClient.post(url, {
        actionType: "TransferApprovals",
        oldUser: oldUser,
        newUser: newUser,
        stage: ["Approve", "Decline"],
        modules: modules,
      });

      dispatch({
        type: "TRANSFER_APPROVALS_SUCCESS",
      });
    } catch (e) {
      dispatch({
        type: "TRANSFER_APPROVALS_FAILED",
      });
    }
  };
