const INITIAL_STATE = {
  loading: false,
  emails: [],
  totalRecords: 0,
  index: 0,
  hasError: false,
  sort: [],
  attachments: null,
  downloadFail: false,
};

export default function EmailsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "EMAIL_INBOX_RESET":
      return INITIAL_STATE;
    case "EMAIL_INBOX_INIT":
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case "EMAIL_INBOX_FETCHED":
      return {
        ...state,
        emails: action.payload.emailRecords,
        totalRecords: action.payload.totalRecords,
        index: action.payload.page,
        loading: false,
        hasError: false,
      };
    case "AUDIT_LOG_FETCH_FAILED":
      return {
        ...state,
        loading: false,
        hasError: true,
      };

    case "INCOMING_EMAILS_OPTION_INIT":
    case "EMAIL_INBOX_OPTIONS_FETCHED_FAIL":
      return {
        ...state,
        sort: [],
      };
    case "EMAIL_INBOX_OPTIONS_FETCHED":
      return {
        ...state,
        sort: action.payload.sort,
      };
    case "EMAIL_INBOX_DOWNLOAD_ATTACHMENT_INIT":
      return {
        ...state,
        downloadFail: false,
        attachments: null,
      };
    case "EMAIL_INBOX_DOWNLOAD_ATTACHMENT_FETCHED":
      return {
        ...state,
        attachments: action.payload.zipAttachment,
      };
    case "EMAIL_INBOX_DOWNLOAD_ATTACHMENT_FETCHED_FAIL":
      return {
        ...state,
        attachments: null,
        downloadFail: true,
      };
    default:
      return state;
  }
}
