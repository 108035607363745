import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes/Routes";

import "./App.css";
import { Provider } from "react-redux";
import store from "./Store/index";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ToastContainer, toast } from "react-toastify";
import { WebSocketConnector } from "./Containers/WebSocket/WebSocketConnector";
import "rc-time-picker/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import FileUploadStatus from "./Components/FileUploadStatus/FileUploadStatus";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingWorker: {},
    };
  }

  onServiceWorkerUpdate = (registration) => {
    //const { enqueueSnackbar } = this.props;
    this.setState(
      {
        waitingWorker: registration && registration.waiting,
      },
      () => {
        toast.success(this.refreshAction(), {
          position: "bottom-left",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          toastId: "sw-msg-01",
        });
      }
    );
  };

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
  };

  refreshAction = (key) => {
    //render the snackbar button
    return (
      <Fragment>
        <div className="sw-msg">
          <i className="fa fa-check-circle"></i> A new version was released
          <button className="sw-refresh-btn" onClick={this.updateServiceWorker}>
            {"refresh"}
          </button>
        </div>
      </Fragment>
    );
  };

  async componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      serviceWorkerRegistration.register({
        onUpdate: this.onServiceWorkerUpdate,
      });
    }
  }

  render() {
    const childProps = {};
    return (
      <Provider store={store}>
        <Routes childProps={childProps} />
        <ToastContainer />
        <WebSocketConnector />
        <FileUploadStatus />
      </Provider>
    );
  }
}

export default withRouter(App);
