import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";

// ---------------------****Documents Actions****-----------------------------

//----------------------****Get Document Tallies*****-------------------------
export const getDocumentTallies = (trans) => async (dispatch) => {
  dispatch({
    type: "GET_DOCUMENT_TALLIES_INIT",
  });
  const url = `/DPFAPI/DocumentRequest?actionType=GetDocumentTallies`;

  try {
    let response = await axiosDPFAPIClient.get(url);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "GET_DOCUMENT_TALLIES_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Document Tallies.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_DOCUMENT_TALLIES_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_DOCUMENT_TALLIES_FAIL",
        payload: "Error While Getting Document Tallies.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Document Tallies.";
    dispatch({
      type: "GET_DOCUMENT_TALLIES_FAIL",
      payload: error,
    });
  }
};

//----------------------**** MoveToNextApprover*****------------------------------
export const moveToNextApprover = (data) => async (dispatch) => {
  dispatch({
    type: "DOCUMENT_MOVE_TO_NEXT_APPROVER_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let obj = {
    actionType: "MoveToNextApprover",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DOCUMENT_MOVE_TO_NEXT_APPROVER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DOCUMENT_MOVE_TO_NEXT_APPROVER_FAIL",
          payload:
            resp.result[0].description ||
            "EError While Moving To Next Approver.",
        });
      }
    } else {
      dispatch({
        type: "DOCUMENT_MOVE_TO_NEXT_APPROVER_FAIL",
        payload: "Error While Moving To Next Approver.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving To Next Approver.";
    dispatch({
      type: "DOCUMENT_MOVE_TO_NEXT_APPROVER_FAIL",
      payload: error,
    });
  }
};

//----------------------**** MoveToNextApprover*****------------------------------
export const moveToPrevApprover = (data) => async (dispatch) => {
  dispatch({
    type: "DOCUMENT_MOVE_TO_PREV_APPROVER_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let obj = {
    actionType: "MoveToPrevApprover",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DOCUMENT_MOVE_TO_PREV_APPROVER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DOCUMENT_MOVE_TO_PREV_APPROVER_FAIL",
          payload:
            resp.result[0].description ||
            "EError While Moving To Prev Approver.",
        });
      }
    } else {
      dispatch({
        type: "DOCUMENT_MOVE_TO_PREV_APPROVER_FAIL",
        payload: "Error While Moving To Prev Approver.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving To Prev Approver.";
    dispatch({
      type: "DOCUMENT_MOVE_TO_PREV_APPROVER_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Documents List*****---------------------------
export const getDocumentsList = (doc) => async (dispatch) => {
  dispatch({
    type: "GET_DOCUMENTS_LIST_INIT",
  });

  let url =

    `/DPFAPI/DocumentRequest?actionType=GetDocumentsList&docState=${doc.docState}&teamDocuments=${doc.teamDocuments}`;

  try {
    let response = await axiosDPFAPIClient.get(url);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "GET_DOCUMENTS_LIST_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Documents List.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_DOCUMENTS_LIST_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_DOCUMENTS_LIST_FAIL",
        payload: "Error While Getting Documents List.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Documents List.";
    dispatch({
      type: "GET_DOCUMENTS_LIST_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get New Documents List*****---------------------------
export const getNewDocumentsList = (data) => async (dispatch) => {
  dispatch({
    type: "GET_NEW_DOCUMENTS_LIST_INIT",
  });

  const url = "/DPFAPI/DocumentRequest";
  let obj = {
    actionType: "GetNewDocumentsList",
    ...data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.Document_response) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_NEW_DOCUMENTS_LIST_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_NEW_DOCUMENTS_LIST_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Documents List.",
        });
      }
    } else {
      dispatch({
        type: "GET_NEW_DOCUMENTS_LIST_FAIL",
        payload: "Error While Getting Documents List.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Documents List.";
    dispatch({
      type: "GET_NEW_DOCUMENTS_LIST_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Single Document *****-------------------------
export const getDocument = (guid) => async (dispatch) => {
  dispatch({
    type: "GET_DOCUMENT_INIT",
  });
  const url = `/DPFAPI/DocumentRequest?actionType=GetDocument&guid=${guid}`;

  try {
    let response = await axiosDPFAPIClient.get(url);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "GET_DOCUMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_DOCUMENT_FAIL",
        payload: "Error While Getting Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Document.";
    dispatch({
      type: "GET_DOCUMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Add Document *****--------------------------------
export const addDocument = () => async (dispatch) => {
  dispatch({
    type: "ADD_DOCUMENT_INIT",
  });

  const url = "/DPFAPI/DocumentRequest";
  let obj = {
    actionType: "AddDocument",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "ADD_DOCUMENT_FAIL",
          payload: resp.result[0].description || "Error While Adding Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "ADD_DOCUMENT_FAIL",
        payload: "Error While Adding Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Document.";
    dispatch({
      type: "ADD_DOCUMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Update Document *****-----------------------------
export const updateDocument = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_DOCUMENT_INIT",
  });

  const url = "/DPFAPI/DocumentRequest";
  let obj = {
    actionType: "UpdateDocument",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "UPDATE_DOCUMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "UPDATE_DOCUMENT_FAIL",
        payload: "Error While Updating Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Document.";
    dispatch({
      type: "UPDATE_DOCUMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Delete Document*****------------------------------
export const deleteDocument = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "DELETE_DOCUMENT_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "DeleteDocument",
    guid: selectedGuid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "DELETE_DOCUMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Deleting The Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "DELETE_DOCUMENT_FAIL",
        payload: "Error While Deleting The Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting The Document.";
    dispatch({
      type: "DELETE_DOCUMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Update Approval Group*****--------------------------

export const updateApprovalGroup = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_APPROVAL_DOCUMENTS_GROUP_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let obj = {
    actionType: "UpdateApprovalGroup",
    ...data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.Document_response) || "";

    if (resp && resp.result.length > 0) {
      console.log("enter");
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_APPROVAL_DOCUMENTS_GROUP_SUCCESS",
          payload: resp,
        });
      } else {
        console.log("enter");
        dispatch({
          type: "UPDATE_APPROVAL_DOCUMENTS_GROUP_FAIL",
          payload:
            resp.result[0].description || "Error While Update Approval Group.",
        });
      }
    } else {
      console.log("enter");
      dispatch({
        type: "UPDATE_APPROVAL_DOCUMENTS_GROUP_FAIL",
        payload: "Error While Update Approval Group.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Update Approval Group.";
    console.log("enter");
    dispatch({
      type: "UPDATE_APPROVAL_DOCUMENTS_GROUP_FAIL",
      payload: error,
    });
  }
};

//----------------------****Add Comment *****---------------------------------
export const addComment = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_COMMENT_INIT",
  });

  const url = "/DPFAPI/DocumentRequest";
  let obj = {
    actionType: "AddComment",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "ADD_COMMENT_FAIL",
          payload: resp.result[0].description || "Error While Adding Comment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_COMMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "ADD_COMMENT_FAIL",
        payload: "Error While Adding Comment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Comment.";
    dispatch({
      type: "ADD_COMMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Send For Approval Document*****-------------------
export const sendDocForApproval = (tranData) => async (dispatch) => {
  dispatch({
    type: "SEND_DOC_FOR_APPROVAL_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "SendDocForApproval",
    ...tranData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "SEND_DOC_FOR_APPROVAL_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Sending Document For Approval.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SEND_DOC_FOR_APPROVAL_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "SEND_DOC_FOR_APPROVAL_FAIL",
        payload: "Error While Sending Document For Approval.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending Document For Approval.";
    dispatch({
      type: "SEND_DOC_FOR_APPROVAL_FAIL",
      payload: error,
    });
  }
};

//----------------------****Approve Document*****-----------------------------
export const approveDocument = (tranData) => async (dispatch) => {
  dispatch({
    type: "APPROVE_DOCUMENT_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "ApproveDocument",
    ...tranData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "APPROVE_DOCUMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Approving Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "APPROVE_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "APPROVE_DOCUMENT_FAIL",
        payload: "Error While Approving Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Approving Document.";
    dispatch({
      type: "APPROVE_DOCUMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Decline Document*****-----------------------------
export const declineDocument = (selectedGuid, comment) => async (dispatch) => {
  dispatch({
    type: "DECLINE_DOCUMENT_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "DeclineDocument",
    guid: selectedGuid,
    comment,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "DECLINE_DOCUMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Declining The Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DECLINE_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "DECLINE_DOCUMENT_FAIL",
        payload: "Error While Declining The Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Declining The Document.";
    dispatch({
      type: "DECLINE_DOCUMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Hold Document*****--------------------------------
export const holdDocument = (tranData) => async (dispatch) => {
  dispatch({
    type: "HOLD_DOCUMENT_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "HoldDocument",
    ...tranData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "HOLD_DOCUMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Holding The Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "HOLD_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "HOLD_DOCUMENT_FAIL",
        payload: "Error While Holding The Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Holding The Document.";
    dispatch({
      type: "HOLD_DOCUMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Move Document*****--------------------------------
export const moveDocument = (tranData) => async (dispatch) => {
  dispatch({
    type: "MOVE_DOCUMENT_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "MoveDocument",
    ...tranData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "MOVE_DOCUMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Moving The Document.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "MOVE_DOCUMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "MOVE_DOCUMENT_FAIL",
        payload: "Error While Moving The Document.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving The Document.";
    dispatch({
      type: "MOVE_DOCUMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Add Document Attachments*****---------------------
export const addDocAttachments = (attachment) => async (dispatch) => {
  dispatch({
    type: "ADD_DOC_ATTACHMENTS_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "AddAttachment",
    ...attachment,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "ADD_DOC_ATTACHMENTS_FAIL",
          payload:
            resp.result[0].description || "Error While Uploading Attachments.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_DOC_ATTACHMENTS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "ADD_DOC_ATTACHMENTS_FAIL",
        payload: "Error While Uploading Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Uploading Attachments.";
    dispatch({
      type: "ADD_DOC_ATTACHMENTS_FAIL",
      payload: error,
    });
  }
};


//----------------------****Add Document Attachments*****---------------------
export const downloadDocAttachments = (payload) => async (dispatch) => {
  dispatch({
    type: "ADD_DOC_ATTACHMENTS_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "DownloadAttachments",
    ...payload,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "DOWNLOAD_DOC_ATTACHMENTS_FAIL",
          payload:
            resp.result[0].description || "Error While Downloading Attachments.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DOWNLOAD_DOC_ATTACHMENTS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "DOWNLOAD_DOC_ATTACHMENTS_FAIL",
        payload: "Error While Downloading Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Downloading Attachments.";
    dispatch({
      type: "DOWNLOAD_DOC_ATTACHMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Update Primary Document*****----------------------
export const updatePrimaryDocument =
  (selectedGuid, recordID) => async (dispatch) => {
    dispatch({
      type: "UPDATE_TO_PRIMARY_DOC_DOCUMENTS_INIT",
    });
    const url = "/DPFAPI/DocumentRequest";
    let data = {
      actionType: "UpdatePrimaryDocument",
      guid: selectedGuid,
      recordID,
    };

    try {
      let response = await axiosDPFAPIClient.post(url, data);
      let resp =
        (response && response.data && response.data.ExpenseResponse) || "";
      if (resp && resp.result.length > 0) {
        if (resp.result[0] && resp.result[0].status === "Failed") {
          dispatch({
            type: "UPDATE_TO_PRIMARY_DOC_DOCUMENTS_FAIL",
            payload:
              resp.result[0].description ||
              "Error While Updating Primary Document.",
          });
        }
        if (resp.result[0] && resp.result[0].status === "Success") {
          dispatch({
            type: "UPDATE_TO_PRIMARY_DOC_DOCUMENTS_SUCCESS",
            payload: resp,
          });
        }
      } else {
        dispatch({
          type: "UPDATE_TO_PRIMARY_DOC_DOCUMENTS_FAIL",
          payload: "Error While Updating Primary Document.",
        });
      }
    } catch (err) {
      const error = err.message || "Error While Updating Primary Document.";
      dispatch({
        type: "UPDATE_TO_PRIMARY_DOC_DOCUMENTS_FAIL",
        payload: error,
      });
    }
  };
//----------------------****Get Document Attachment*****----------------------
export const getAttachment = (fileID) => async (dispatch) => {
  dispatch({
    type: "GET_DOC_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "GetAttachment",
    recordID: fileID
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "GET_DOC_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Attachment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_DOC_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_DOC_ATTACHMENT_FAIL",
        payload: "Error While Getting Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Attachment.";
    dispatch({
      type: "GET_DOC_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Delete Document Attachment*****-------------------
export const deleteAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "DELETE_DOC_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/DocumentRequest";
  let data = {
    actionType: "DeleteAttachment",
    recordID,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "DELETE_DOC_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Deleting Attachment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_DOC_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "DELETE_DOC_ATTACHMENT_FAIL",
        payload: "Error While Deleting Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Attachment.";
    dispatch({
      type: "DELETE_DOC_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Regenerate Document Signatures*****-------------------

export const regenerateDocumentsSignature = (tran) => async (dispatch) => {
  dispatch({
    type: "REGENERATE_DOCUMENTS_INIT",
  });
  let url =

    `/DPFAPI/DocumentRequest`;
  let data = {
    actionType: 'RegenerateSignatures',
    guid: tran
  }

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "REGENERATE_DOCUMENTS_FAILED",
          payload: resp.result[0].description || "Regenearte documents Failed",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "REGENERATE_DOCUMENTS_SUCCESS",
          payload: resp || "Signature regenerated",
        });
      }
    } else {
      dispatch({
        type: "REGENERATE_DOCUMENTS_FAILED",
        payload: "Regenerate expense failed",
      });
    }
  } catch (err) {
    const error = err.message || "Regenerate documents failed";
    dispatch({
      type: "REGENERATE_Documents_FAILED",
      payload: error,
    });
  }
};

export const transferDocuments = (payload) => async (dispatch) => {
  dispatch({
    type: "TRANSFER_DOCUMENTS_INIT",
  });
  let url =

    `/DPFAPI/DocumentRequest`;
  let data = {
    actionType: 'TransferDocument',
    ...payload
  }

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let resp =
      (response && response.data && response.data.Document_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "TRANSFER_DOCUMENTS_FAILED",
          payload: resp.result[0].description || "Transfer documents Failed",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "TRANSFER_DOCUMENTS_SUCCESS",
          payload: resp || "Documents transferred",
        });
      }
    } else {
      dispatch({
        type: "TRANSFER_DOCUMENTS_FAILED",
        payload: "Documents transfer failed",
      });
    }
  } catch (err) {
    const error = err.message || "Documents transfer failed";
    dispatch({
      type: "REGENERATE_DOCUMENTS_FAILED",
      payload: error,
    });
  }
};

//----------------------****Clear Documents states****------------------------
export function clearDocumentStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_DOCUMENTS_STATES",
    });
  };
}
