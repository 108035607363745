import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import $ from "jquery";
import { connect } from "react-redux";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import Attachments from "../Modals/Attachments/Attachments";
import CreateSendEFT from "../Modals/CreateSendEFT/CreateSendEFT";
import ResendSTPFile from "../Modals/ResendSTPFile/ResendSTPFile";
import SendYearEndSTPFile from "../Modals/SendYearEndSTPFile/SendYearEndSTPFile";
import TimeCardMoreDetail from "../Modals/TimeCardMoreDetail/TimeCardMoreDetail";
import DeclineTimeCard from "../Modals/DeclineTimeCard/DeclineTimeCard";
import Import from "../Modals/Import/Import";
import Report from "../Modals/Report/Report";
import Dropdown from "react-bootstrap/Dropdown";
import TimecardActivity from "../Modals/Activity/TimecardActivity";

import Changes from "../Modals/Changes/Changes";
import Comments from "../Modals/Comments/Comments";
import Move from "../Modals/Move/Move";
import { options } from "../../Constants/Constants";
import CheckApproval from "../Modals/CheckApproval/CheckApproval";
import ModifyTimecard from "../Modals/Modify/ModifyTimecard";

import {
  handleAPIErr,
  zoomIn,
  zoomOut,
  handleDropdownZooming,
  downloadAttachments,
} from "../../Utils/Helpers";
import ModileResponsiveMenu from "../../Components/modileResponsiveMenu";
import * as TimecardActions from "../../Actions/TimecardActions/TimecardActions";
import * as SetupActions from "../../Actions/SetupRequest/SetupAction";
import * as PaginationActions from "../../Actions/paginationActions/paginationActions";
import { getAccountDetails } from "../../Actions/UserActions/UserActions";
import * as WebSocketActions from "../../Actions/WebSocketActions/WebSocketActions";
// import ModifyTimeCard from "../Modals/ModifyTimeCard/ModifyTimeCard";

import "./Timecards.css";
import { TransferRecords } from "../Modals/TransferRecords/TransferRecords";
import {
  downloadFileFromPresignedUrl,
  getBase64FromPreSignedS3Url,
  isRemoteStorage,
  uploadFileListToS3
} from "../../Utils/S3StorageHelper";


const uuidv1 = require("uuid/v1");

class Timecards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      allowEdit: "N", //to add the edit timecard button on approve tab if it is Y
      tcType: "", //timecard type -> draft, pending, declined, all etc
      employeeList: [],
      tran: "", //tran of current selected Timecard
      multipleTrans: [], //when user selects multiple Timecard to perform different functionality
      timecardTallies: [], //e.g Draft, Pending, Approved, etc
      timecardListSearch: props.pageData?.searchTerm ?? "", //search on timecard list
      timecardsList: [], //side menu (left side) timecard list data
      clonedTimecardsList: [], //a copy of  timecardsList
      filteredTimecardList: [], //this contains filterd list and used for searching on it
      activeTimecard: "", //to add class active in lists of getting timecards (in left side )
      activeTimecardTallis: "1", //to add class active on timecards tallis
      showTallisTabPane: "Draft", //to add class active on timecards tallis below tab pane
      timecardType: "", //draft, pending, declined, all etc
      viewTeam: "N",
      employeeCode: "",
      employeeName: "",
      weekEndingDate: "",
      department: "",
      position: "",
      email: "",
      approverGroup: "",
      approverOptions: [],
      approvers: [],
      stateTaxFlag: "",
      group: "",
      currentApprover: "",
      approved: "",
      batchDesc: "",
      batchNo: "", //batch no of current selected batch
      batchList: [],
      batchListOptions: [], //restructured batch listfor the drop-down when Move popup opens
      totalHours: "",
      page: props.pageData?.pageNumber ?? 1, //The page to retrieve. Returns the first page if left as zero.
      resetPage: true,
      pageStart: "",
      pageEnd: "",
      totalTimecards: "",
      totalPages: "", //getNewTimecardList return total num of pages
      display: localStorage.getItem("orderDPR") || 10, //The number of Timecards to display.
      previews: [],
      numPages: null,
      pageNumber: 1,
      numPagesArr: [], //it contains number of pages of each PDF
      scaling: 3.4,
      dropdownZoomingValue: { label: "40%", value: "40%" },
      rotate: 0,
      toggleRightSidebar: true,
      zero: false,
      close: false,
      order: "Descend",
      timeCardUserName: "",

      sortTCListKeyName: props.pageData?.sortKey ?? "employeeName",
      sortTCListOrder: props.pageData?.sortOrder ?? "ASC",

      attachments: [],
      attachmentSize: 0, //default 0 Bytes,  attachments should always less than 29.5 MB
      exceptions: [],
      comments: [],
      activity: [],

      openAttachmentsModal: false,
      openCommentsModal: false,
      openActivityModal: false,
      openChangesModal: false,
      openReportModal: false,
      openEmployeeLookupModal: false,
      openCheckApprovalModal: false,
      openModifyModal: false,

      downloadOptions: {
        includeTimeSheets: true,
        includePayslips: false,
        includeOther: false,
      },
      typeMove: "",
      useTCPageLoading: localStorage.getItem("useTCPageLoading"),
      allSelected: false,
      guid: null,
      activePage: null,
      checked: false,
      timeCardAdded: null,
      selectedGuid: null,
      selectedMultipleGuid: [],
      previouslyEdited: false,
      collapseIcon: {
        tphPayroll: false,
        approvals: false,
        download: false,
        batchList: false,
        export: false,
        activity: false,
        changes: false,
        exception: false,
      },
      showTransferRecordsModal: false,
      selectedBatchNos:[],
      clonedBatchList:[],
      moveAction:null
    };
  }
  async componentDidMount() {

    this.props.setWebSocketActiveModule("timecard");
    //right hand side bar setting with PO Image
    window.addEventListener(
      "resize",
      () => {
        $(".mm_ordr1").addClass("mm_order_pdfMain");

        if ($(window).width() > 991) {
          this.setState({ toggleRightSidebar: true });
        }
        if ($(window).width() <= 991) {
          this.setState({ toggleRightSidebar: false });
        }
        if ($(window).width() >= 2045) {
          $(".invoice_pdf_new").addClass("invoice_pdf_new1");
        }
        if ($(window).width() < 2045) {
          $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
        }
      },
      false
    );
    // end
    //Team Timecard Check
    let viewTeam = localStorage.getItem("teamTimecard");
    if (viewTeam) {
      this.setState({ viewTeam });
    }
    let sortTCListKeyName = localStorage.getItem("sortTCListKeyName") || "";
    let sortTCListOrder = localStorage.getItem("sortTCListOrder") || ""; //ASC | DESC
    if (sortTCListKeyName && sortTCListOrder) {
      this.setState({ sortTCListKeyName, sortTCListOrder });
    }

    $(document).ready(function () {
      var vw = $(window).width();
      var nav = $(".navbar.fixed-top").height();
      var underNav = $(".order-tabs").height();
      var wh = $(window).height();
      var h = wh - nav - 60;
      var rsb = wh - nav - underNav - 20;
      // var pdfDiv = wh - nav - underNav - 80
      var pdfDiv = wh - nav;
      var wh = wh - nav - underNav;
      $("#order--dynamic--height").css("height", h);
      $(".side-attachments,.side-attachments-2").css("height", rsb);
      $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

      $(window).resize(function () {
        var vw = $(window).width();
        // if (vw > 1930) {
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        // var pdfDiv = wh - nav - underNav - 80
        var wh = wh - nav - underNav;
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
        // }
      });
      if ($(window).width() >= 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
      if ($(window).width() < 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".invoice_carousel").toggleClass("expand_invoice_img");
    });
    $(".export_crd").click(function () {
      $(".export_crd .sideBarAccord1").toggleClass("rotate_0");
    });
    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".order_pdf_new").toggleClass("order_left_auto");
    });
    // sideBarAccord
    $(".invoice-inherit").click(function () {
      $(".invoice-inherit .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".sideBarAccord").click(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord1").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".invoice-inherit2").click(function () {
      $(".sideBarAccord2 ").toggleClass("rotate_0");
    });
    $(".invoice-batchlist").click(function () {
      $(".invoice-batchlist .sideBarAccord1").toggleClass("rotate_0");
    });
    // end

    let {
      tallies,
      dashboard,
      tallType,
      addEditTimecardCheck,
      addEditTimecardTran,
      guid,
      activePage,
      timeCardAdded,
      previouslyEdited,
      selectedGuid,
      isPrimeTimeCardCreated
    } =
    (this.props.history &&
      this.props.history.location &&
      this.props.history.location.state) ||
    "";
    if (previouslyEdited) {
      this.setState({ previouslyEdited: previouslyEdited });
    }
    if (guid) {
      this.setState({ guid: guid });
    }
    if (activePage) {
      this.setState({ activePage: activePage });
    }
    if (timeCardAdded) {
      this.setState({ timeCardAdded: timeCardAdded });
    }

    if(selectedGuid){
      this.setState({ selectedGuid: selectedGuid});
    }
    if(isPrimeTimeCardCreated){
      this.setState({isPrimeTimeCardCreated: isPrimeTimeCardCreated})
    }
    if (dashboard && tallType) {
      //when user click on Timecard Tallies on Dashboard
      await this.getTimecardTallies(tallType, true);
    } else if (
      tallies &&
      (tallies === "Draft" || tallies === "Approve") &&
      addEditTimecardCheck &&
      addEditTimecardTran
    ) {
      /*Check When Edit Timecard and then user Save or Cancel that edit,
    then load the same Timecard user just edited?.*/
      await this.getTimecardTallies(tallies, true);
    } else {
      await this.getTimecardTallies();
    }

    this.getBtachList();
    if (!localStorage.getItem("getAccountDetails")) {
      await this.props.getAccountDetails();
    }
  }

  async componentDidUpdate(previousProps) {
    const message = this.props.ws.webSocketMessageReceived;
    if(message?.module === "timecard") {
      const previousMessage = previousProps.ws.webSocketMessageReceived;
      if(`${previousMessage?.guid}_${previousMessage?.status}` !== `${message.guid}_${message.status}`) {
        const updatedTimecards = this.getUpdatedTimecardCodeStatus(message);

        this.setState({timecardsList: updatedTimecards});
      }
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedBatchNos !== this.state.selectedBatchNos) {
      if (
        localStorage.getItem("usePageLoading") === "Y" ||
        localStorage.getItem("useTCPageLoading") === "Y"
      ) {
        // when page loading is enabled
        this.getNewTimecardList(
          {
            type: this.state.showTallisTabPane,
            id: this.state.activeTimecardTallis,
          },
          false,
          false
        );
      }
      this.getNewTimecardList();
    }
  }


  getUpdatedTimecardCodeStatus(message) {
    return this.state.timecardsList.map(timecard => {
      if (timecard.guid === message.guid) {
        switch (message.status) {
          case 'TIMECARD_IN_PROCESSING_QUEUE':
            timecard.codeStatus = "red";
            break;
          case 'TIMECARD_PROCESSING':
            timecard.codeStatus = "orange";
            break;
          case 'TIMECARD_PROCESSING_COMPLETED':
            timecard.codeStatus = "green";
            break;
          case 'TIMECARD_PROCESSING_FAILED':
            timecard.codeStatus = "black";
            break;
          default:
            timecard.codeStatus = "black";
        }
        console.log('TO TIMECARD', timecard);
      }
      return timecard;
    });
  }

  componentWillUnmount() {
    this.props.setWebSocketActiveModule("");
    if (this.state.resetPage) {
      this.props.resetLastPage("timecards");
    }
  }

  clearStates = (clearBatchNumber = true) => {
    this.setState({
      allowEdit: "N", //to add then edit timecardd button on approve tab if it is equal to Y
      tcType: "", //timecard type -> draft, pending, declined, all etc
      isLoading: false,
      tran: "", //tran of current selected Timecard
      multipleTrans: [], //when user selects multiple Timecard to perform different functionality
      // timecardListSearch: "", //search on timecard list
      timecardsList: [], //side menu (left side) timecard list data
      clonedTimecardsList: [], //a copy of  timecardsList
      filteredTimecardList: [], //this contains filterd list and used for searching on it
      activeTimecard: "", //to add class active in lists of getting timecards (in left side )
      activeTimecardTallis: "1", //to add class active on timecards tallis
      showTallisTabPane: "Draft", //to add class active on timecards tallis below tab pane
      timecardType: "", //draft, pending, declined, all etc
      employeeCode: "",
      employeeName: "",
      weekEndingDate: "",
      department: "",
      position: "",
      email: "",
      approverGroup: "",
      approverOptions: [],
      approvers: [],
      stateTaxFlag: "",
      group: "",
      currentApprover: "",
      approved: "",
      batchDesc: "",
      batchNo: clearBatchNumber ? "" : this.state.batchNo, //batch no of current selected batch
      totalHours: "",
      previews: [],
      numPages: null,
      pageNumber: 1,
      numPagesArr: [], //it contains number of pages of each PDF
      attachments: [],
      exceptions: [],
      comments: [],
      activity: [],
      downloadOptions: {
        includeTimeSheets: true,
        includePayslips: false,
        includeOther: false,
      },
      useTCPageLoading: "",
      openAttachmentsModal: false,
      openCommentsModal: false,
      openActivityModal: false,
      openChangesModal: false,
      openReportModal: false,
      // selectedGuid: null,
    });
  };

  handleTimecardTallies =async (type, check) =>{
    this.clonedBatchListGenerate();
    this.setState({ selectedBatchNos:[] });
    this.getTimecardTallies(type, check);
    this.setState({
      allSelected: false
    })
  }
  //get Timecard talleis
  getTimecardTallies = async (type, check) => {

    //check -> when a user Perform some actions like send for approval, Approve, Declined OR after creating new Timecard etc then update Timecard Tallies
    this.setState({ isLoading: true , checked: false, batchNo:"",selectedMultipleGuid:[] });
    let isTCTallies = false; //to check if redux store containe timecard tallies then dont call API again

    let _timecardTallies = this.props.timecard.timecardTallies || [];

    if (_timecardTallies.length === 0 || check) {
      await this.props.getTimecardTallies(); // get timecardTallies
    } else {
      isTCTallies = true;
    }
    let tcTally = ""; //contains single tally info e.g. {type: draft, count: 3}
    let timecardTallies = [];
    let { activeTimecardTallis, showTallisTabPane } = this.state;

    //success case of Timecard tallies
    if (this.props.timecard.timecardTalliesSuccess || isTCTallies) {
      // toast.success(this.props.timecard.timecardTalliesSuccess);
      timecardTallies = this.props.timecard.timecardTallies || [];

      let _type = "";

      if (type) {
        _type = type;
      } else if (timecardTallies.length > 0) {
        _type = timecardTallies[0].type;
      }

      timecardTallies.map( (s, i) => {
        s.id = i +1;
        if (s.type === _type) {
          tcTally = s;
          activeTimecardTallis = s.id;
          showTallisTabPane = s.type;
        }
        return s;
      });
    }
    //error case of Timecard tallies
    if (this.props.timecard.timecardTalliesError) {
      handleAPIErr(this.props.timecard.timecardTalliesError, this.props);
    }
    this.setState({
      isLoading: false,
      timecardTallies,
      activeTimecardTallis,
      showTallisTabPane,
    });

    if (tcTally) {
      await this.checkTCList_API(tcTally);
    }
    this.props.clearTimecardStates();
  };

  //check GetTimecardList OR GetNewTimecardList API should be called
  checkTCList_API = (data, check) => {
    if (
      localStorage.getItem("usePageLoading") == "Y" &&
      localStorage.getItem("useTCPageLoading") == "Y"
    ) {
      this.getNewTimecardList(data, check);
    } else if (
      localStorage.getItem("usePageLoading") == "N" &&
      localStorage.getItem("useTCPageLoading") == "N"
    ) {
      this.getTimecardList(data, check);
    } else if (
      localStorage.getItem("usePageLoading") == "Y" &&
      localStorage.getItem("useTCPageLoading") == "N"
    ) {
      this.getNewTimecardList(data, check);
    } else if (
      localStorage.getItem("usePageLoading") == "N" &&
      localStorage.getItem("useTCPageLoading") == "Y"
    ) {
      this.getNewTimecardList(data, check);
    }
  };

  //getting the timecard list when click on Draft || Pending || Approved etc
  getTimecardList = async (data, check) => {
    let { zero, close } = this.state;

    if (check === "tallies") {
      zero = false;
      close = false;
    }
    this.clearStates();
    this.setState({
      isLoading: true,
      activeTimecardTallis: data.id,
      showTallisTabPane: data.type,
      timecardListSearch: "",
      zero,
      close,
    });
    let { activeTimecard, viewTeam } = this.state;
    let teamTimecardCheck = viewTeam;

    if (teamTimecardCheck) {
      data.teamTimecard = teamTimecardCheck;
    }
    await this.props.getTimecardList(data); // get PO list
    // await this.props.getTimecardList(data); // get Timecard list

    let firstTC = ""; //first timecard

    //success case of Timecard List

    if (this.props.timecard.getTimecardListSuccess) {
      // toast.success(this.props.timecard.getTimecardListSuccess);
      let timecardsList = this.props.timecard.getTimecardList || [];

      let sortTCListKeyName = this.state.sortTCListKeyName || "";
      let sortTCListOrder = this.state.sortTCListOrder || "";
      let valueA = "";
      let valueB = "";
      timecardsList
        .sort((a, b) => {
          if (sortTCListKeyName === "tran") {
            valueA = Number(a[sortTCListKeyName]);
            valueB = Number(b[sortTCListKeyName]);
          } else if (sortTCListKeyName === "date") {
            valueA = new Date(a.date);
            valueB = new Date(b.date);
          } else if (sortTCListKeyName) {
            valueA = a[sortTCListKeyName].toString().toUpperCase();
            valueB = b[sortTCListKeyName].toString().toUpperCase();
          }
          //for ascending order
          if (sortTCListOrder === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        })
        .map((tc, i) => {
          let id = uuidv1();
          tc.id = id;
          tc.checked = false;
          firstTC = tc;
          tc.weekEndingDate = moment(tc.weekEndingDate, "DD/M/YYYY")
            .format("DD MMM YYYY")
            .toUpperCase();
          activeTimecard = id;
          return tc;
        });

      // setting up the first timecard to active and call getTimecardSummary API accordingly
      if (timecardsList.length > 0) {
        firstTC = timecardsList[0];
        activeTimecard = timecardsList[0].id;
      }

      /*Check When Add/Edit timecard and then user Save or Cancel that edit,
        then load the same  timecard user just edited/created?.*/
      let { addEditTimecardTran, tallies, addEditTimecardCheck } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";

      if (
        tallies &&
        (tallies === "Draft" || tallies === "Approve") &&
        addEditTimecardCheck &&
        addEditTimecardTran
      ) {
        let checkTC = timecardsList.find((l) => l.tran === addEditTimecardTran);
        if (checkTC) {
          firstTC = checkTC;
          activeTimecard = checkTC.id; //now first time replaced with the last modified or canceled timecard from the add edit timecard page
        }
      }
      this.setState({
        activeTimecard,
        timecardsList,
        clonedTimecardsList: timecardsList,
        filteredTimecardList: timecardsList,
      });
    }
    //error case of Timecard List
    if (this.props.timecard.getTimecardListError) {
      handleAPIErr(this.props.timecard.getTimecardListError, this.props);
    }
    this.props.clearTimecardStates();


    if (firstTC) {
      //to call get timecard baseed on first timecard in timecard list
      await this.getTimecardSummary(firstTC, true);
    }
    await this.props.clearTimecardStates();

    // scroll to active timecard
    var elmnt = document.getElementById(this.state.activeTimecard);
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  };

  //getting the new Timecard list when usePageLoading = Y
  getNewTimecardList = async (data, check, clearBatchNumber = true) => {
    let activeTimecard = "";
    let timecardsList = [];
    let totalPages = "";
    let pageStart = "";
    let pageEnd = "";
    let totalTimecards = "";

    let {
      zero,
      close,
      page,
      display,
      order,
      sortTCListKeyName,
      viewTeam,
      timecardListSearch,
      batchNo,
      previouslyEdited,
      guid,
      selectedGuid,
      selectedBatchNos
    } = this.state;

    if (check === "tallies") {
      zero = false;
      close = false;
    }
    if (check) {
      page = this.props.pageNumber;
    }
    console.log(data.id);
    this.clearStates(clearBatchNumber);
    this.setState({
      isLoading: true,
      activeTimecardTallis: data.id,
      showTallisTabPane: data.type,
      zero,
      close,
      page,
    });

    let obj = {
      timecardType: data.type,
      displayRecords: display,
      order: order,
      sort: sortTCListKeyName,
      search: timecardListSearch,
      batchList: selectedBatchNos,
      teamTimecards: viewTeam,
    };
    let firstTC = "";
    // determines if the clonedTransferList (N) or getNewTransferList (Y) is used.
    if ((previouslyEdited && selectedGuid) || data.activeGuid ) {
      if (data.activeGuid) {
        await this.props.getNewTimecardList({ ...obj, guid: data.activeGuid }); // get PO list
      }else if (selectedGuid) {
        await this.props.getNewTimecardList({ ...obj, guid: guid || selectedGuid }); // get PO list
      }
    }
    else {
      // this.getPOTransferList(data, check);
      await this.props.getNewTimecardList({ ...obj, page: page }); // get PO list
    }

    //success case of Timecard List
    if (
      this.props.timecard.getNewTimecardListSuccess ||
      this.props.timecard.getTimecardList.length > 0
    ) {
      // toast.success(this.props.timecard.getNewTimecardListSuccess);
      let listData = this.props.timecard.getNewTimecardList || "";
      timecardsList = listData.timecardList || [];
      totalPages = listData.totalPages || "";
      pageStart = listData.pageStart || "";
      pageEnd = listData.pageEnd || "";
      totalTimecards = listData.totalTimecards || "";
      if (this.state.timeCardAdded || data.activeGuid) {
        this.setState({ page: listData.page });
      }
      // when a user comes form Search page then show the Timecard comming from Search page
      let { addEditTimecardTran, tallies, addEditTimecardCheck } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";
      let searchPOTran = "";

      timecardsList.map((tc, i) => {
        if (i === 0) {
          let id = uuidv1();
          tc.id = id;
          firstTC = tc;
          tc.checked = false;
          activeTimecard = id;
        } else {
          tc.id = uuidv1();
          tc.checked = false;
        }
        return tc;
      });

      this.setState({timecardsList: timecardsList});
      // check if timecard comming from search page
      let checkTC = timecardsList.find((l) => l.trans === searchPOTran);
      if (checkTC) {
        firstTC = checkTC;
        activeTimecard = checkTC.id;
      }

      // setting up the first timecard to active and call getTimecardSummary API accordingly
      if (timecardsList.length > 0) {
        firstTC = timecardsList[0];
        activeTimecard = timecardsList[0].id;
      }

      /*Check When Add/Edit timecard and then user Save or Cancel that edit,
    then load the same  timecard user just edited/created?.*/
      if (
        tallies &&
        (tallies === "Draft" || tallies === "Approve") &&
        addEditTimecardCheck &&
        addEditTimecardTran &&
        this.state.guid
      ) {
        let checkTC = timecardsList.find((l) => l.tran === addEditTimecardTran);
        if (checkTC) {
          firstTC = checkTC;
          activeTimecard = checkTC.id; //now first time replaced with the last modified or canceled timecard from the add edit timecard page
        }
      }
    }

    if (data.activeGuid) {
      const activeTimeCardRecord = timecardsList.find(l => l.guid === data.activeGuid);
      if (activeTimeCardRecord) {
        firstTC = activeTimeCardRecord;
        activeTimecard = activeTimeCardRecord.id;
      }
    }

    //error case of Timecard List
    if (this.props.timecard.getNewTimecardListError) {
      handleAPIErr(this.props.timecard.getNewTimecardListError, this.props);
    }

    await this.props.clearTimecardStates();
    this.setState({
      activeTimecard,
      timecardsList,
      clonedTimecardsList: timecardsList,
      filteredTimecardList: timecardsList,
      totalPages,
      pageStart,
      pageEnd,
      totalTimecards,
      isLoading: false,
    });

    if (firstTC) {
      //to call get timecard baseed on first timecard in timecard list
      await this.getTimecardSummary(firstTC, true);
    }
    await this.props.clearTimecardStates();

    //Can you ensure that checkboxes on the right hand side stay ticked when in the same section for Exclude?
    if (zero || close) {
      this.handleExclude();
    }
    // scroll to active tc
    // var elmnt = document.getElementById(this.state.activeTimecard);
    // if (elmnt) {
    //   elmnt.scrollIntoView();
    // }
  };

  handleExclude = (e) => {
    let name = e.target.name;
    let checked = e.target.checked;
    this.setState(
      {
        [name]: checked,
      },
      async () => {
        let { zero, close, fullyApproved } = this.state;

        let check = !zero && !close && !fullyApproved ? true : false; //all checkboxes are uncheck
        const clonedTimecardsList = JSON.parse(
          JSON.stringify(this.state.clonedTimecardsList)
        );

        if (check) {
          //all checkboxes are uncheck
          this.setState(
            {
              timecardsList: clonedTimecardsList,
              filteredTimecardList: clonedTimecardsList,
            },
            () => {
              this.handleSortTimecardList(this.state.sortTCListKeyName);
            }
          );
        } else {
          //checkbox chcek case
          let filterdData = [];
          let excludeFilters = [];
          if (zero) {
            excludeFilters = ["close", "fullyApproved", ""];
          }
          if (close) {
            excludeFilters = ["zero", "fullyApproved", ""];
          }
          if (fullyApproved) {
            excludeFilters = ["zero", "close", ""];
          }
          if (zero && close) {
            excludeFilters = ["fullyApproved", ""];
          }

          if (zero && fullyApproved) {
            excludeFilters = ["close", ""];
          }

          if (close && fullyApproved) {
            excludeFilters = ["zero", ""];
          }

          if (close && fullyApproved && zero) {
            excludeFilters = [""];
          }

          excludeFilters.map((f, i) => {
            let InvoiceListFilterdData = [];

            InvoiceListFilterdData = clonedTimecardsList.filter((c) => {
              // if (c.excludeStatus.toUpperCase()) {
              return c.excludeStatus.toUpperCase() === f.toUpperCase();
              // }
            });
            filterdData.push(...InvoiceListFilterdData);
          });

          this.setState(
            {
              timecardsList: filterdData,
              filteredTimecardList: filterdData,
            },
            () => {
              this.handleSortTimecardList(this.state.sortTCListKeyName);
            }
          );
        }
      }
    );
  };

  //Get Timecard Summary
  getTimecardSummary = async (tc, check) => {
    let activeTC;
    this.setState({selectedGuid:  this.state.selectedGuid});
    if (this.state.activeTimecard != tc.id || check) {
      this.clearTCStates(tc);

      if (this.state.previouslyEdited){
        if (this.state.isPrimeTimeCardCreated){
          this.setState({ isLoading: true});
          await this.props.getTimecardSummary(tc.guid);
          this.setState({ isPrimeTimeCardCreated: false, isLoading: false });
        }else {
          this.setState({ isLoading: true});
          await this.props.getTimecardSummary(this.state.selectedGuid );
          this.setState({isLoading: false })
        }
        activeTC = this.state.timecardsList.find(items => items.guid === this.state.selectedGuid);
        this.setState({activeTimecard: activeTC.id})
      }else {
        this.setState({ isLoading: true});
        await this.props.getTimecardSummary(tc.guid ? tc.guid : this.state.selectedGuid );
      }
      this.setState({ previouslyEdited: false });
      //success case of Get Timecard Summary
      if (this.props.timecard.getTimecardSummarySuccess) {
        // toast.success(this.props.timecard.getTimecardSummarySuccess);
        this.setState({ isLoading: false});
        let tcSummary = _.cloneDeep(this.props.timecard.getTimecardSummary);
        let tran = tcSummary.tran || "";
        let allowEdit = tcSummary.allowEdit || "N";
        let tcType = tcSummary.type || "";
        let batchDesc = tcSummary.batchDesc || "";
        let approved = tcSummary.approved || "";
        let currentApprover = tcSummary.currentApprover || "";
        let group = tcSummary.group || "";
        let stateTaxFlag = tcSummary.stateTaxFlag || "";
        let approverGroup = tcSummary.approverGroup || "";
        let approverOptions = tcSummary.approverOptions || [];
        let approvers = tcSummary.approvers || [];
        let department = tcSummary.department || "";
        let position = tcSummary.position || "";
        let email = tcSummary.email || "";
        let periodEndingDate = tcSummary.periodEndingDate || "";
        let employeeName = tcSummary.employeeName || "";
        let employeeCode = tcSummary.employeeCode || "";
        let totalHours = tcSummary.totalHours || "";
        let activity = tcSummary.activity || [];
        let comments = tcSummary.comments || [];
        let guid = tcSummary.guid || [];
        const timeCardUserName = tcSummary.userName ?? "";

        //comments.map((c) => (c.comment = c.status));

        let attachments = tcSummary.attachments || [];

        let attachmentSize = 0;
        attachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });

        let previews = tcSummary.previews || [];

        if (isRemoteStorage()) {
          previews = await Promise.all(previews.map(async (p) => {
            p.file = await getBase64FromPreSignedS3Url(p.documentPresignedURL);
            return p;
          }));
        }

        let exceptions = tcSummary.exceptions || [];

        approverOptions.map((a, i) => {
          a.checked = false;
          a.id = uuidv1();
          return a;
        });
        this.setState({
          tcType,
          tran,
          allowEdit,
          batchDesc,
          approved,
          currentApprover,
          group,
          stateTaxFlag,
          approverGroup,
          approverOptions,
          approvers,
          department,
          position,
          email,
          periodEndingDate,
          employeeName,
          employeeCode,
          totalHours,
          activity,
          comments,
          attachments,
          attachmentSize,
          previews,
          exceptions,
          timeCardUserName,
          guid,
          selectedGuid:guid,
        });
      }
      //error case of Get Timecard Summary
      if (this.props.timecard.getTimecardSummaryError) {
        handleAPIErr(this.props.timecard.getTimecardSummaryError, this.props);
      }

      this.props.clearTimecardStates();
      let timecardZoom = localStorage.getItem("timecardZoom");
      if (timecardZoom) {
        this.handleDropdownZooming({ value: timecardZoom });
      }
    }
  };

  //just clear Timecard related states
  clearTCStates = (tc) => {
    this.setState({
      tcType: "", //timecard type -> draft, pending, declined, all etc
      activeTimecard: tc.id,
      timecardType: "",
      teamTimecard: "N",
      employeeCode: "",
      employeeName: "",
      weekEndingDate: "",
      department: "",
      position: "",
      email: "",
      approverGroup: "",
      approverOptions: [],
      approvers: [],
      stateTaxFlag: "",
      group: "",
      currentApprover: "",
      approved: "",
      batchDesc: "",
      batchNo: this.state.batchNo ?? "",
      totalHours: "",
      tran: "",
      previews: [],
      rotate: 0,
      numPages: null,
      pageNumber: 1,
      numPagesArr: [], //it contains number of pages of each PDF
      attachments: [],
      attachmentSize: 0,
      exceptions: [],
      comments: [],
      activity: [],
    });
  };

  //call getTimecardList API
  toggleTeamIcon = (check) => {
    localStorage.setItem("teamTimecard", check);
    this.setState({ viewTeam: check }, () => {
      let { activeTimecardTallis, showTallisTabPane } = this.state;
      let obj = {
        id: activeTimecardTallis,
        type: showTallisTabPane,
      };
      this.checkTCList_API(obj);
    });
  };

  // move to previous timecard
  moveToPrevTimecard = async () => {
    let { timecardsList, activeTimecard } = this.state;
    let foundIndex = timecardsList.findIndex((l) => l.id === activeTimecard);

    if (foundIndex != -1 && foundIndex != 0) {
      let tc = timecardsList[foundIndex - 1];
      if (tc) {
        await this.getTimecardSummary(tc);
      }
    }
  };

  // move to next timecard
  moveToNextTimecard = async () => {
    let { timecardsList, activeTimecard } = this.state;
    let foundIndex = timecardsList.findIndex((l) => l.id === activeTimecard);

    if (foundIndex != -1) {
      let tc = timecardsList[foundIndex + 1];
      if (tc) {
        await this.getTimecardSummary(tc);
      }
    }
  };

  //delete timecard
  deleteTimecard = async () => {
    let {
      tran,
      multipleTrans,
      timecardsList,
      activeTimecard,
      activeTimecardTallis,
      showTallisTabPane,
      timecardTallies,
      clonedTimecardsList,
      filteredTimecardList,
      batchList,
      selectedGuid,
    } = this.state;

    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        toast.error("Only One Timecard can be Delete at a Time!");
      }
    } else {
      _trans = tran;
    }
    if (_trans) {
      let activeTimeCard = timecardsList.find((tc) => tc.tran === _trans) || "";
      let batchObject =
        batchList.find(
          (batchItem) =>
            Number(batchItem.batchNo) === Number(activeTimeCard.batchNo)
        ) || "";

      if (batchObject.lock === "Locked") {
        toast.error("Selected Timecard Batch is Locked");
        return;
      }

      this.setState({
        isLoading: true,
      });

      await this.props.deleteTimecard(selectedGuid); // delete timecard
      //success case of delete Timecard
      if (this.props.timecard.deleteTimecardSuccess) {
        // toast.success(this.props.timecard.deleteTimecardSuccess);
        // When deleting an Timecard --- Can it just highlight the Timecard above the deleted one?

        if (timecardsList.length === 1) {
          await this.clearStates();
          //decrease the tallies count also
          timecardTallies.map((t, i) => {
            if (
              t.type.toLowerCase() === "draft" //delete button only appears in draft section
            ) {
              t.count = 0;
            }
            return t;
          });
          timecardsList = [];
          clonedTimecardsList = [];
          multipleTrans = [];
          filteredTimecardList = [];
        } else if (timecardsList.length > 1) {
          if (_trans === tran) {
            //when user delete the current selected Timecard
            //there are two cases if the user deletes the first Timecard in the list  then active the very next otherwise highlight Timecard above the deleted Timecard
            let foundIndex = timecardsList.findIndex(
              (l) => l.id === activeTimecard
            );
            if (foundIndex != -1 && foundIndex === 0) {
              let tc = timecardsList[foundIndex + 1];
              if (tc) {
                await this.getTimecardSummary(tc);
              }
            } else {
              let tc = timecardsList[foundIndex - 1];
              if (tc) {
                await this.getTimecardSummary(tc);
              }
            }
            let list = timecardsList.filter((l) => l.tran != _trans);
            //decrease the tallies count also
            timecardTallies.map((t, i) => {
              if (t.type.toLowerCase() === "draft") {
                t.count = list.length;
              }
              return t;
            });

            timecardsList = list;
            clonedTimecardsList = list;
            filteredTimecardList = list;
            multipleTrans = [];
          } else {
            //when user delete other timecard by checking the check box
            let list = timecardsList.filter((l) => l.tran != _trans);
            //decrease the tallies count also
            timecardTallies.map((t, i) => {
              if (t.type.toLowerCase() === "draft") {
                t.count = list.length;
              }
              return t;
            });
            timecardsList = list;
            clonedTimecardsList = list;
            filteredTimecardList = list;
            multipleTrans = [];
          }
        }
      }
      //error case of delete Timecard
      if (this.props.timecard.deleteTimecardError) {
        handleAPIErr(this.props.timecard.deleteTimecardError, this.props);
      }
      this.setState({
        isLoading: false,
        timecardsList,
        clonedTimecardsList,
        filteredTimecardList,
        activeTimecardTallis,
        multipleTrans,
        showTallisTabPane,
        timecardTallies,
      });
      this.props.clearTimecardStates();
    }
  };

  //move Timecard
  moveTimecard = async () => {
    let {
      tran,
      multipleTrans,
      showTallisTabPane,
      timecardsList,
      batchList,
      selectedGuid,
      selectedMultipleGuid
    } = this.state;

    if ( selectedGuid || (selectedMultipleGuid && selectedMultipleGuid.length > 0)) {
      let _trans = "";
      if (selectedMultipleGuid.length > 0) {
        _trans = selectedMultipleGuid;
      }else {
        _trans = selectedGuid;
      }

      if (typeof _trans !== "object") {
        let activeTimeCard =
          timecardsList.find((tc) => tc.guid === _trans) || "";
        let batchObject =
          batchList.find(
            (batchItem) =>
              Number(batchItem.batchNo) === Number(activeTimeCard.batchNo)
          ) || "";

        if (batchObject.lock === "Locked") {
          toast.error("Selected Timecard batch is locked");
          return;
        }
      } else {
        let anyTimecardLocked = false;
        _trans.map((tran) => {
          let filteredObj = timecardsList.find((tc) => tc.guid === tran) || "";
          if (filteredObj) {
            let lockedBatch =
              batchList.find(
                (batchObj) =>
                  Number(batchObj.batchNo) === Number(filteredObj.batchNo) &&
                  batchObj.lock === "Locked"
              ) || "";
            if (lockedBatch) {
              anyTimecardLocked = true;
              return;
            }
          }
          return;
        });
        if (anyTimecardLocked) {
          toast.error("One of selected Timecard batch is locked");
          return;
        }
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.moveTimecard({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.moveTimecard(_trans); // move timecard
      }

      //success case of move timecard
      if (this.props.timecard.moveTimecardSuccess) {
        toast.success(this.props.timecard.moveTimecardSuccess);
        await this.getTimecardTallies(showTallisTabPane, true); //to refresh the list
      }
      //error case of move timecard
      if (this.props.timecard.moveTimecardError) {
        handleAPIErr(this.props.timecard.moveTimecardError, this.props);
      }

      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  handleMoveTimeCard = (type) => {
    let userType = localStorage.getItem("userType")?.toLowerCase();
    if (type === "pending"){
      if (userType && userType.toLowerCase() === "operator"){
        this.setState({moveAction: 'moveToDraftByPendingOperator'});
        this.openModal("openModifyModal")
      }else {
        this.moveTimecard();
      }
    }else {
      this.setState({moveAction: 'moveToDraft'});
      this.openModal("openModifyModal")
    }
  }

  handleMoveToApproveTimecard = () => {
    this.setState({moveAction: 'moveToApprove'})
    this.openModal("openModifyModal")
  }

  //move to approve
  moveToApproveTimecard = async () => {
    let {
      tran,
      multipleTrans,
      showTallisTabPane,
      timecardsList,
      batchList,
      selectedGuid,
      selectedMultipleGuid
    } = this.state;

    if ( selectedGuid || (selectedMultipleGuid && selectedMultipleGuid.length > 0)) {
      let _trans = "";
      if (selectedMultipleGuid.length > 0) {
        _trans = selectedMultipleGuid;
      }else {
        _trans = selectedGuid;
      }

      if (typeof _trans !== "object") {
        let activeTimeCard =
          timecardsList.find((tc) => tc.guid === _trans) || "";
        let batchObject =
          batchList.find(
            (batchItem) =>
              Number(batchItem.batchNo) === Number(activeTimeCard.batchNo)
          ) || "";

        if (batchObject.lock === "Locked") {
          toast.error("Selected Timecard batch is locked");
          return;
        }
      } else {
        let anyTimecardLocked = false;
        _trans.map((tran) => {
          let filteredObj = timecardsList.find((tc) => tc.guid === tran) || "";
          if (filteredObj) {
            let lockedBatch =
              batchList.find(
                (batchObj) =>
                  Number(batchObj.batchNo) === Number(filteredObj.batchNo) &&
                  batchObj.lock === "Locked"
              ) || "";
            if (lockedBatch) {
              anyTimecardLocked = true;
              return;
            }
          }
          return;
        });
        if (anyTimecardLocked) {
          toast.error("One of selected Timecard batch is locked");
          return;
        }
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.moveToApproverTimecard({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.moveToApproverTimecard(_trans); // move timecard
      }

      //success case of move timecard
      if (this.props.timecard.moveTimecardSuccess) {
        toast.success(this.props.timecard.moveTimecardSuccess);
        await this.getTimecardTallies(showTallisTabPane, true); //to refresh the list
      }
      //error case of move timecard
      if (this.props.timecard.moveTimecardError) {
        handleAPIErr(this.props.timecard.moveTimecardError, this.props);
      }

      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  //approve Timecard
  approveTimecard = async () => {
    let {  showTallisTabPane,  selectedMultipleGuid, selectedGuid } = this.state;
    if ( selectedGuid || (selectedMultipleGuid && selectedMultipleGuid.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (selectedMultipleGuid.length > 0) {
        _trans = selectedMultipleGuid;
      }else {
        _trans = [selectedGuid];
      }


      if (this.state.allSelected) {
        await this.props.approveTimecard({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.approveTimecard({ guid: _trans }); // approve timecard
      }

      //success case of approve timecard
      if (this.props.timecard.approveTimecardSuccess) {
        toast.success(this.props.timecard.approveTimecardSuccess);
        await this.getTimecardTallies(showTallisTabPane, true); //to refresh the list
      }
      //error case of approve timecard
      if (this.props.timecard.approveTimecardError) {
        handleAPIErr(this.props.timecard.approveTimecardError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  //hold Timecard
  holdTimecard = async () => {
    let {  showTallisTabPane,  selectedMultipleGuid, selectedGuid} = this.state;
    if ( selectedGuid || (selectedMultipleGuid && selectedMultipleGuid.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (selectedMultipleGuid.length > 0) {
        _trans = selectedMultipleGuid;
      }else {
        _trans = [selectedGuid];
      }

      if (this.state.allSelected) {
        await this.props.holdTimecard({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.holdTimecard({ guid: _trans }); // hold timecard
      }

      //success case of hold timecard
      if (this.props.timecard.holdTimecardSuccess) {
        toast.success(this.props.timecard.holdTimecardSuccess);
        await this.getTimecardTallies(showTallisTabPane, true); //to refresh the list
      }
      //error case of hold timecard
      if (this.props.timecard.holdTimecardError) {
        handleAPIErr(this.props.timecard.holdTimecardError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  declineTimeCard = async () => {
    const { tran, timecardsList, batchList } = this.state;
    let activeTimeCard = timecardsList.find((tc) => tc.tran === tran) || "";
    let batchObject =
      batchList.find(
        (batchItem) =>
          Number(batchItem.batchNo) === Number(activeTimeCard.batchNo)
      ) || "";

    if (batchObject.lock === "Locked") {
      toast.error("Selected Timecard batch is locked");
      return;
    } else {
      this.setState({
        openDeclineTimeCardmodal: true,
      });
    }
  };

  // sendForApproval Timecard =>Draft -> send
  sendForApproval = async () => {
    let {
      tran,
      multipleTrans,
      showTallisTabPane,
      timecardsList,
      batchList,
      selectedGuid,
      selectedMultipleGuid
    } = this.state;
    if (selectedGuid|| (selectedMultipleGuid && selectedMultipleGuid.length > 0)) {
      let _trans = "";
      if (selectedMultipleGuid.length > 0) {
        _trans = selectedMultipleGuid;
      }else {
        _trans = [selectedGuid];
      }
      if (typeof _trans !== "object") {
        let activeTimeCard =
          timecardsList.find((tc) => tc.guid === _trans) || "";

        if (Number(activeTimeCard.batchNo) === 0) {
          toast.error("Selected Timecard batch is 0");
          return;
        }

        let batchObject =
          batchList.find(
            (batchItem) =>
              Number(batchItem.batchNo) === Number(activeTimeCard.batchNo)
          ) || "";
        if (batchObject.lock === "Locked") {
          toast.error("Selected Timecard batch is locked");
          return;
        }
      } else {
        let anyTimecardLocked = false;
        _trans.map((tran) => {
          let filteredObj = timecardsList.find((tc) => tc.guid === tran);
          if (filteredObj) {
            if (Number(filteredObj.batchNo) === 0) {
              toast.error("Selected Timecard batch is 0");
              return;
            }
            let lockedBatch = batchList.find(
              (batchObj) =>
                Number(batchObj.batchNo) === Number(filteredObj.batchNo) &&
                batchObj.lock === "Locked"
            );
            if (lockedBatch) {
              anyTimecardLocked = true;
              return;
            }
          }
          return;
        });
        if (anyTimecardLocked) {
          toast.error("One of selected Timecard batch is locked");
          return;
        }
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.sendForApproval({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.sendForApproval({ guid: _trans }); // send For Approval Timecard
      }

      //success case of send For Approval Timecard
      if (this.props.timecard.sendForApprovalTimecardSuccess) {
        this.setState({ activePage: null });
        // toast.success(this.props.timecard.sendForApprovalTimecardSuccess);
        await this.getTimecardTallies(showTallisTabPane, true); //to refresh the list
      }
      //error case of send For Approval Timecard
      if (this.props.timecard.sendForApprovalTimecardError) {
        handleAPIErr(
          this.props.timecard.sendForApprovalTimecardError,
          this.props
        );
      }
      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  resendEmail = async () => {
    this.setState({ isLoading: true });

    let { selectedGuid } = this.state;

    await this.props.resendEmail(selectedGuid);
    if (this.props.timecard.resendEmailSuccess) {
      toast.success(this.props.timecard.resendEmailSuccess);
    }
    if (this.props.timecard.resendEmailError) {
      handleAPIErr(this.props.timecard.resendEmailError, this.props);
    }
    this.setState({ isLoading: false });
  };
  handleCheckbox = (e, data) => {
    this.setState({ checked: !this.state.checked });
    let { timecardsList, multipleTrans, selectedMultipleGuid } = this.state;
    let { name, checked } = e.target;
    let allSelected = false;

    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      let multipleGuidCopy = [];
      if (checked) {
        allSelected = true;
        timecardsList.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.tran);
          multipleGuidCopy.push(m.guid);
          return m;
        });
      } else {
        allSelected = false;
        timecardsList.map((m) => {
          m.checked = false;
          return m;
        });
      }
      multipleTrans = [...multipleTransCopy];
      selectedMultipleGuid = [...multipleGuidCopy];
    } else {
      if (checked) {
        timecardsList.map((m) => {
          if (m.id === data.id) {
            m.checked = true;
          }
          return m;
        });
        multipleTrans.push(data.tran);
        selectedMultipleGuid.push(data.guid);
      } else {
        timecardsList.map((m) => {
          if (m.id === data.id) {
            m.checked = false;
          }
          return m;
        });
        multipleTrans = multipleTrans.filter((m) => m != data.tran);
        selectedMultipleGuid = selectedMultipleGuid.filter(
          (m) => m != data.guid
        );
      }
    }
    this.setState({
      timecardsList,
      multipleTrans,
      allSelected,
      selectedMultipleGuid,
    });
  };

  //add attachment
  addAttachment = async (fileList,) => {
    let { tran, selectedGuid } = this.state;
    if (selectedGuid) {
      this.setState({ isLoading: true });
      let data = {
        fileName: fileList[0].fileName,
        attachment: fileList[0].attachment,
        guid: selectedGuid,
        documentGUID:"",
      };
      if (isRemoteStorage()) {
        await uploadFileListToS3(fileList, async (uploadedFiles) => {
          if (uploadedFiles) {
            let attachmentData = {
              guid: selectedGuid,
              fileName: uploadedFiles[0]?.fileName,
              documentGUID: uploadedFiles[0]?.documentGuid,
            }
            await this.props.addAttachment(attachmentData);
            if (this.props.timecard.addAttachmentsSuccess) {
              toast.success(this.props.timecard.addAttachmentsSuccess);
              let attachments = this.props.timecard.addAttachments || [];
              let attachmentSize = 0;
              attachments.map((a, i) => {
                attachmentSize += Number(a.fileSize) || 0;
              });
              this.setState({ attachments, attachmentSize });
            }
            if (this.props.timecard.addAttachmentsError) {
              handleAPIErr(this.props.timecard.addAttachmentsError, this.props);
            }
            this.props.clearTimecardStates();
            this.setState({ isLoading: false });
          }
        })}
      else {
        await this.props.addAttachment(data);
      }

      if (this.props.timecard.addAttachmentsSuccess) {
        toast.success(this.props.timecard.addAttachmentsSuccess);
        let attachments = this.props.timecard.addAttachments || [];
        let attachmentSize = 0;
        attachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });
        this.setState({ attachments, attachmentSize });
      }
      if (this.props.timecard.addAttachmentsError) {
        handleAPIErr(this.props.timecard.addAttachmentsError, this.props);
      }
      this.props.clearTimecardStates();
      this.setState({ isLoading: false });
    } else {
      toast.error("Please Select an Timecard");
    }
  };

  //get Attachment
  getAttachment = async (recordID, type, fileName) => {
    this.setState({ isLoading: true });

    await this.props.getAttachment(recordID);
    if (this.props.timecard.getAttachmentSuccess) {
      let resp = this.props.timecard.getAttachment;

      if (resp.downloadPresignedUrl) {
        downloadFileFromPresignedUrl(resp.downloadPresignedUrl);
      }else {
        downloadAttachments(resp, fileName);
      }

    }
    if (this.props.timecard.getAttachmentError) {
      handleAPIErr(this.props.timecard.getAttachmentError, this.props);
    }
    this.props.clearTimecardStates();
    this.setState({ isLoading: false });
  };

  //get Attachments List
  getAttachmentsList = async () => {
    this.setState({ isLoading: true });
    let { tran, selectedGuid } = this.state;
    if (selectedGuid) {
      await this.props.getAttachmentsList(selectedGuid);

      if (this.props.timecard.getAttachmentsListSuccess) {
        let attachments = this.props.timecard.getAttachmentsList || [];
        let attachmentSize = 0;
        attachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });
        toast.success(this.props.timecard.getAttachmentsListSuccess);
        this.setState({ attachments, attachmentSize });
      }
      if (this.props.timecard.getAttachmentsListError) {
        handleAPIErr(this.props.timecard.getAttachmentError, this.props);
      }
      this.props.clearTimecardStates();
    } else {
      toast.error("Timecard List is Empty.");
    }
    this.setState({ isLoading: false });
  };

  //Download copy of TimeCards
  downloadPreview = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isLoading: true });

    let params = {};
    if (this.state.selectedBatchNos) {
      params.batchList = this.state.selectedBatchNos;
    }
    params = {
      ...params,
      downloadFilters: [
        {
          includeTimesheets: this.state.downloadOptions.includeTimeSheets
            ? 1
            : 0,
          includePayslips: this.state.downloadOptions.includePayslips ? 1 : 0,
          includeOther: this.state.downloadOptions.includeOther ? 1 : 0,
        },
      ],
    };

    if (this.state.allSelected) {
      params = {
        ...params,
        selectAll: "Y",
        timecardType: this.state.showTallisTabPane,
        search: this.state.timecardListSearch,
        teamTimecards: this.state.teamTimecard,
        downloadFilters: params.downloadFilters,
      }
      await this.props.downloadTimecard(params);
    } else {
      params = {
        ...params,
        guid:
          this.state.selectedMultipleGuid.length > 0
            ? this.state.selectedMultipleGuid
            : [this.state.selectedGuid],
      }
      await this.props.downloadTimecard(params);
    }

    if (this.props.timecard.downloadTimecardSuccess) {
      let linkSource;
      if (localStorage.getItem('storageType') === "remote"){
        linkSource = this.props.timecard.downloadTimecardSuccess.downloadPresignedUrl;
      }else {
        linkSource = `data:text/plain;base64,${this.props.timecard.downloadTimecardSuccess.zipAttachment}`;
      }
      const downloadLink = document.createElement("a");
      const fileName = "attachment.zip";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else if (this.props.timecard.downloadTimecardError) {
      toast.error(this.props.timecard.downloadTimecardError);
    } else {
      toast.error("Failed to download timecards");
    }
    this.setState({ isLoading: false });
  };

  //add comment
  addComment = async (comment, params) => {
    let { tran, comments, selectedGuid } = this.state;
    if (selectedGuid) {
      if (comment) {
        this.setState({ isLoading: true });
        let data = {
          comment,
          ...params,
          guid: selectedGuid,
        };
        await this.props.addComment(data);
        if (this.props.timecard.addCommentSuccess) {
          // toast.success(this.props.timecard.addCommentSuccess);
          comments = this.props.timecard.addComments || comments;
        }
        if (this.props.timecard.addCommentError) {
          handleAPIErr(this.props.timecard.addCommentError, this.props);
        }
        this.props.clearTimecardStates();
        this.setState({ isLoading: false, comments });
      } else {
        toast.error("Please Enter Comment!");
      }
    } else {
      toast.error("Please Select an Timecard");
    }
  };

  hanldeTPHpayoll = (name) => {
    if (
      name === "Create and Send EFT/STP Files" ||
      name === "Resend STP File" ||
      name === "Send Year End STP File"
    ) {
      if (name === "Create and Send EFT/STP Files") {
        let { batchNo, batchList } = this.state;

        if (batchNo || batchNo === 0) {
          let batch = batchList.find((b) => b.batchNo === batchNo);

          if (batch && batch.lock === "Locked") {
            this.openModal("openSendEFTmodal");
          } else {
            toast.error(
              "BBatch must be locked to Create EFT and Send STP Files"
            );
          }
        } else {
          toast.error("Please select Batch Form Batch List!");
        }
      } else if (name === "Resend STP File") {
        this.openModal("openResendSTPFilemodal");
      } else {
        this.openModal("openSendYearEndSTPFilemodal");
      }
    } else if (name === "Send Reports") {
      this.sendReports();
    } else if (name === "Lock & Invoice") {
      this.lockAndInvoice();
    } else if (name === "Send Payslips to employees") {
      this.sendPayslips();
    } else if (name === "Post & Upload Data") {
      this.postData();
    }
  };

  //Sned Repors
  sendReports = async () => {
    let { batchNo } = this.state;
    if (batchNo || batchNo === 0) {
      this.setState({ isLoading: true });

      await this.props.sendReports(batchNo);

      if (this.props.timecard.sendReportsSuccess) {
        toast.success(this.props.timecard.sendReportsSuccess);
      }

      if (this.props.timecard.sendReportsError) {
        handleAPIErr(this.props.timecard.sendReportsError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Batch Form Batch List!");
    }
  };

  //Lock And Invoice
  lockAndInvoice = async () => {
    let { batchNo, batchList, tcType } = this.state;
    if (tcType === "Approved" || tcType === "approved") {
      if (batchNo || batchNo === 0) {
        let batch = batchList.find((b) => b.batchNo === batchNo);

        if (batch && batch.lock === "Locked") {
          toast.error("Batch is already Locked");
        } else {
          this.setState({ isLoading: true });

          await this.props.lockAndInvoice(batchNo);

          if (this.props.timecard.lockAndInvoiceSuccess) {
            toast.success(this.props.timecard.lockAndInvoiceSuccess);
            let ind = batchList.findIndex((b) => b.batchNo === batchNo);
            if (ind > -1) {
              batch = { ...batch, lock: "Locked" };
              batchList[ind] = batch;
            }
          }

          if (this.props.timecard.lockAndInvoiceError) {
            handleAPIErr(this.props.timecard.lockAndInvoiceError, this.props);
          }
          this.setState({ isLoading: false, batchList });
          this.props.clearTimecardStates();
        }
      } else {
        toast.error("Please select Batch Form Batch List!");
      }
    } else {
      toast.error(
        "Timecard has not been fully approved so the batch can not be locked and Invoiced!"
      );
    }
  };

  //Send Payslips to Employees
  sendPayslips = async () => {
    let { batchNo, batchList } = this.state;

    if (batchNo || batchNo === 0) {
      let batch = batchList.find((b) => b.batchNo === batchNo);

      if (batch && batch.lock === "Locked") {
        this.setState({ isLoading: true });

        await this.props.sendPayslips(batchNo);

        if (this.props.timecard.sendPaySlipSuccess) {
          toast.success(this.props.timecard.sendPaySlipSuccess);
        }

        if (this.props.timecard.sendPaySlipError) {
          handleAPIErr(this.props.timecard.sendPaySlipError, this.props);
        }
        this.setState({ isLoading: false });
        this.props.clearTimecardStates();
      } else {
        toast.error("Batch must be locked to Send Payslips");
      }
    } else {
      toast.error("Please select Batch Form Batch List!");
    }
  };

  //Post Data
  postData = async () => {
    let { batchNo, batchList } = this.state;

    if (batchNo || batchNo === 0) {
      let batch = batchList.find((b) => b.batchNo === batchNo);

      if (batch && batch.lock === "Locked") {
        this.setState({ isLoading: true });

        await this.props.postData(batchNo);

        if (this.props.timecard.postDataSuccess) {
          toast.success(this.props.timecard.postDataSuccess);
        }

        if (this.props.timecard.postDataError) {
          handleAPIErr(this.props.timecard.postDataError, this.props);
        }
        this.setState({ isLoading: false });
        this.props.clearTimecardStates();
      } else {
        toast.error("Batch must be locked to Post and Upload");
      }
    } else {
      toast.error("Please select Batch Form Batch List!");
    }
  };

  openModal = (name, type) => {
    this.setState({ [name]: true });
    if (type) {
      this.setState({ typeMove: type });
    }
  };

  closeModal = (name) => {
    this.setState({ [name]: false });
  };

  handlePageClick = ({ selected }) => {
    this.setState({ guid: null, activePage: null });
    let { activeTimecardTallis, showTallisTabPane } = this.state;
    let obj = {
      id: activeTimecardTallis,
      type: showTallisTabPane,
    };

    this.setState(
      { page: selected + 1, pageStart: "", pageEnd: "", totalTimecards: "" },
      () => {
        this.savePageState();
        if (
          localStorage.getItem("usePageLoading") == "Y" &&
          localStorage.getItem("useTCPageLoading") == "Y"
        ) {
          this.getNewTimecardList(obj);
        } else if (
          localStorage.getItem("usePageLoading") == "N" &&
          localStorage.getItem("useTCPageLoading") == "N"
        ) {
          this.getTimecardList(obj);
        } else if (
          localStorage.getItem("usePageLoading") == "Y" &&
          localStorage.getItem("useTCPageLoading") == "N"
        ) {
          this.getNewTimecardList(obj);
        } else if (
          localStorage.getItem("usePageLoading") == "N" &&
          localStorage.getItem("useTCPageLoading") == "Y"
        ) {
          this.getNewTimecardList(obj);
        }
      }
    );
  };

  savePageState = () => {
    const { page, timecardListSearch, sortTCListKeyName, sortTCListOrder } =
      this.state;
    this.props.setLastPage("timecards", {
      pageNumber: page,
      searchTerm: timecardListSearch,
      sortKey: sortTCListKeyName,
      sortOrder: sortTCListOrder,
    });
  };
  handleRowsSetting = (e) => {
    let { activeTimecardTallis, showTallisTabPane } = this.state;

    let obj = {
      id: activeTimecardTallis,
      type: showTallisTabPane,
    };
    localStorage.setItem("orderDPR", e.target.value); // no of display order pages

    this.setState({ display: e.target.value }, () => {
      if (
        localStorage.getItem("usePageLoading") == "Y" &&
        localStorage.getItem("useTCPageLoading") == "Y"
      ) {
        this.getNewTimecardList(obj);
      } else if (
        localStorage.getItem("usePageLoading") == "N" &&
        localStorage.getItem("useTCPageLoading") == "N"
      ) {
        this.getTimecardList(obj);
      } else if (
        localStorage.getItem("usePageLoading") == "Y" &&
        localStorage.getItem("useTCPageLoading") == "N"
      ) {
        this.getNewTimecardList(obj);
      } else if (
        localStorage.getItem("usePageLoading") == "N" &&
        localStorage.getItem("useTCPageLoading") == "Y"
      ) {
        this.getNewTimecardList(obj);
      }
    });
  };

  //sorting on timecard list
  handleSortTimecardList = async (name) => {
    let storeGuid = this.state.guid;
    this.setState({ guid: null });
    name = name || "";
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    let order = "Descend";
    if (usePageLoading === "Y") {
      if (this.state.sortTCListKeyName === name) {
        if (this.state.order === "Descend") {
          order = "Ascend ";
        }
        if (this.state.order === "Ascend ") {
          order = "Descend";
        }
      }
      this.setState(
        {
          sortTCListKeyName: name,
          order,
        },
        () => {
          this.savePageState();
          let { activeTimecardTallis, showTallisTabPane, sortTCListOrder } =
            this.state;

          let obj = {
            id: activeTimecardTallis,
            type: showTallisTabPane,
          };
          localStorage.setItem("sortTCListKeyName", name);
          localStorage.setItem("sortTCListOrder", sortTCListOrder);

          if (
            localStorage.getItem("usePageLoading") == "Y" &&
            localStorage.getItem("useTCPageLoading") == "Y"
          ) {
            this.getNewTimecardList(obj);
          } else if (
            localStorage.getItem("usePageLoading") == "N" &&
            localStorage.getItem("useTCPageLoading") == "N"
          ) {
            this.getTimecardList(obj);
          } else if (
            localStorage.getItem("usePageLoading") == "Y" &&
            localStorage.getItem("useTCPageLoading") == "N"
          ) {
            this.getNewTimecardList(obj);
          } else if (
            localStorage.getItem("usePageLoading") == "N" &&
            localStorage.getItem("useTCPageLoading") == "Y"
          ) {
            this.getNewTimecardList(obj);
          }
        }
      );
    } else {
      let { sortTCListOrder, filteredTimecardList, activeTimecard } =
        this.state;
      if (this.state.sortTCListKeyName != name) {
        sortTCListOrder = "DESC";
      }
      if (sortTCListOrder === "DESC") {
        sortTCListOrder = "ASC";
      } else {
        sortTCListOrder = "DESC";
      }
      this.savePageState();
      localStorage.setItem("sortTCListKeyName", name);
      localStorage.setItem("sortTCListOrder", sortTCListOrder);

      filteredTimecardList = _.cloneDeep(filteredTimecardList);

      let tcListFilterdData = [];
      if (name === "tran") {
        tcListFilterdData = filteredTimecardList.sort(function (a, b) {
          let valueA = Number(a[name]);
          let valueB = Number(b[name]);
          //for ascending order
          if (sortTCListOrder === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      } else if (name === "date") {
        tcListFilterdData = filteredTimecardList.sort(function (a, b) {
          let valueA = "";
          let valueB = "";

          if (name === "date") {
            valueA = new Date(a.weekEndingDate);
            valueB = new Date(b.weekEndingDate);
          } else {
            valueA = new Date(a.approvalDate);
            valueB = new Date(b.approvalDate);
          }

          //for ascending order
          if (sortTCListOrder === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
          // codes must be equal
        });
      } else if (name) {
        tcListFilterdData = filteredTimecardList.sort(function (a, b) {
          let valueA = a[name].toString().toUpperCase();
          let valueB = b[name].toString().toUpperCase();
          //for ascending order
          if (sortTCListOrder === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      }
      this.setState({
        timecardsList: tcListFilterdData,
        sortTCListKeyName: name,
        sortTCListOrder,
      });

      // scroll to active timecard
      var elmnt = document.getElementById(activeTimecard);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }
  };

  handleChangeNewTCListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ timecardListSearch: searchedText }, () => {
      this.savePageState();
      if (!searchedText) {
        let { activeTimecardTallis, showTallisTabPane } = this.state;
        let obj = {
          id: activeTimecardTallis,
          type: showTallisTabPane,
        };
        if (
          localStorage.getItem("usePageLoading") == "Y" &&
          localStorage.getItem("useTCPageLoading") == "Y"
        ) {
          this.getNewTimecardList(obj);
        } else if (
          localStorage.getItem("usePageLoading") == "N" &&
          localStorage.getItem("useTCPageLoading") == "N"
        ) {
          this.getTimecardList(obj);
        } else if (
          localStorage.getItem("usePageLoading") == "Y" &&
          localStorage.getItem("useTCPageLoading") == "N"
        ) {
          this.getNewTimecardList(obj);
        } else if (
          localStorage.getItem("usePageLoading") == "N" &&
          localStorage.getItem("useTCPageLoading") == "Y"
        ) {
          this.getNewTimecardList(obj);
        }
      }
    });
  };

  //when a user searches on Timecard list
  handleChangeTCListSearch = async (e) => {
    let searchedText = e.target.value;
    this.setState({ timecardListSearch: searchedText }, () => {
      let { filteredTimecardList, sortTCListOrder, sortTCListKeyName } =
        this.state;

      if (!searchedText) {
        //if user removes all searched text then diaplay all list back
        filteredTimecardList = _.cloneDeep(filteredTimecardList);

        if (sortTCListOrder === "ASC") {
          sortTCListOrder = "DESC";
        } else {
          sortTCListOrder = "ASC";
        }
        this.setState(
          { timecardsList: filteredTimecardList, sortTCListOrder },
          () => this.handleSortTimecardList(sortTCListKeyName)
        );
      }
    });
  };

  onNewTCListSearch = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let searchedText = e.target.value;
      let usePageLoading = localStorage.getItem("usePageLoading") || "N";
      if (usePageLoading === "Y") {
        this.setState(
          {
            timecardListSearch: searchedText,
            page: 1,
          },
          () => {
            let { activeTimecardTallis, showTallisTabPane } = this.state;

            let obj = {
              id: activeTimecardTallis,
              type: showTallisTabPane,
            };
            if (
              localStorage.getItem("usePageLoading") == "Y" &&
              localStorage.getItem("useTCPageLoading") == "Y"
            ) {
              this.getNewTimecardList(obj);
            } else if (
              localStorage.getItem("usePageLoading") == "N" &&
              localStorage.getItem("useTCPageLoading") == "N"
            ) {
              this.getTimecardList(obj);
            } else if (
              localStorage.getItem("usePageLoading") == "Y" &&
              localStorage.getItem("useTCPageLoading") == "N"
            ) {
              this.getNewTimecardList(obj);
            } else if (
              localStorage.getItem("usePageLoading") == "N" &&
              localStorage.getItem("useTCPageLoading") == "Y"
            ) {
              this.getNewTimecardList(obj);
            }
          }
        );
      }
    }
  };

  onTCListSearch = async (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let timecardListSearch = this.state.timecardListSearch.trim();

      if (timecardListSearch) {
        let { filteredTimecardList } = this.state;

        filteredTimecardList = _.cloneDeep(filteredTimecardList);
        let tcListFilterdData = [];
        tcListFilterdData = filteredTimecardList.filter((c) => {
          return (
            c.employeeName
              .toUpperCase()
              .includes(timecardListSearch.toUpperCase()) ||
            c.position
              .toUpperCase()
              .includes(timecardListSearch.toUpperCase()) ||
            c.department
              .toUpperCase()
              .includes(timecardListSearch.toUpperCase())
          );
        });
        this.setState({ timecardsList: tcListFilterdData });
      }
    }
  };

  refreshEmployees = async () => {
    let { employeeList } = this.state;
    this.setState({
      isLoading: true,
    });

    await this.props.refreshEmployees();

    if (this.props.timecard.refreshEmployeesSuccess) {
      // toast.success(this.props.timecard.refreshEmployeesSuccess);
      employeeList = this.props.timecard.refreshEmployees || [];
    }
    if (this.props.timecard.refreshEmployeesError) {
      handleAPIErr(this.props.timecard.refreshEmployeesError, this.props);
    }
    this.setState({
      isLoading: false,
      employeeList,
    });
    this.props.clearTimecardStates();
  };

  getEmployeeList = async () => {
    let { employeeList } = this.state;
    this.setState({
      isLoading: true,
    });

    await this.props.getEmployeeList();

    if (this.props.timecard.getEmployeeListSuccess) {
      // toast.success(this.props.timecard.getEmployeeListSuccess);
      employeeList = this.props.timecard.getEmployeeList || [];
    }
    if (this.props.timecard.getEmployeeListError) {
      handleAPIErr(this.props.timecard.getEmployeeListError, this.props);
    }
    this.setState({
      isLoading: false,
      employeeList,
    });
    this.props.clearTimecardStates();
  };

  clickHandlerMoredetails = async (event, parmData) => {
    let { showTallisTabPane } = this.state;

    showTallisTabPane = showTallisTabPane
      ? showTallisTabPane.toLowerCase()
      : "";

    if (showTallisTabPane === "draft") {
      // this.getEmployeeList();
    }

    this.setState({
      openTimeCardMoreDetail: true,
    });
  };

  updateTimecardSummaryState = async (param) => {
    let { previews } = this.state;
    if (this.props.timecard.updateTimecardSummarySuccess) {
      toast.success(this.props.timecard.updateTimecardSummarySuccess);

      let filteredTimecardsList = this.state.timecardsList.find(
        (element) => element.tran === param.tran
      );

      let indexToUpdate = this.state.timecardsList.findIndex(
        (element) => element === filteredTimecardsList
      );
      let newemplyeeList = this.state.timecardsList;
      newemplyeeList[indexToUpdate] = {
        ...newemplyeeList[indexToUpdate],
        weekEndingDate: moment(param.periodEndingDate).format("DD MMM YYYY"),
        tran: param.tran,
        department: param.department,
        employeeName: param.employeeName,
        group: param.group,
        position: param.position,
      };

      await this.props.getTimecardSummary(this.state.selectedGuid);
      if (this.props.timecard.getTimecardSummarySuccess) {
        let tcSummary = _.cloneDeep(this.props.timecard.getTimecardSummary);
        previews = tcSummary.previews || [];
      }

      if (this.props.timecard.getTimecardSummaryError) {
        handleAPIErr(this.props.timecard.getTimecardSummaryError, this.props);
      }

      this.setState({
        param,
        previews,
        periodEndingDate: param.periodEndingDate,
      });
    } else {
      toast.error(this.props.timecard.updateTimecardSummaryError);
    }
  };

  // Timecard Previews
  zoomIn = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue, zoom } = zoomIn(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        localStorage.setItem("timecardZoom", zoom);

        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  zoomOut = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue, zoom } = zoomOut(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        localStorage.setItem("timecardZoom", zoom);
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleDropdownZooming = async (data) => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let value = data.value;

    localStorage.setItem("timecardZoom", value);

    let { scale, dropdownZoomingValue } = handleDropdownZooming(value);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          value == "25%" ||
          value == "20%" ||
          value == "15%" ||
          value == "10%" ||
          value == "5%"
        ) {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleRightSidebar = () => {
    this.setState((prevState, props) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };

  handleHorizontalArrow = () => {
    $(".invoice_pdf_new").addClass("invoice_carousel_pdf");

    $(".invoice_pdf_new").removeClass("full_screen_convas");

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  handleHorizontalCross = () => {
    $(".mm_invoice_div").addClass("over_auto_remove");
    $(".invoice_pdf_new").addClass("full_screen_convas");

    var vw = $(window).width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;

    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  onLoadSuccessPage = ({ numPages }) => {
    var vw = $(window).width();
    var vw = $(".side-attachments").width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var h = wh - nav - 60;
    var rsb = wh - nav - underNav - 20;
    // var pdfDiv = wh - nav - underNav - 80
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;
    $("#order--dynamic--height").css("height", h);
    $(".side-attachments,.side-attachments-2").css("height", rsb);
    // $('#maped_image').css('height', pdfDiv)
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
  };

  onDocumentLoadSuccess = (data, index) => {
    let numPages = data.numPages;
    let { numPagesArr } = this.state;
    numPagesArr[index] = numPages;

    if (index === 0) {
      this.setState({ numPages });
    }
    this.setState({ numPagesArr }, () => this.settPreviewArrows());
  };

  goToPrevPage = async () => {
    let { pageNumber } = this.state;
    if (pageNumber - 1 >= 1) {
      this.setState({ pageNumber: pageNumber - 1 });
    }
  };

  goToNextPage = () => {
    let { pageNumber, numPages } = this.state;
    if (pageNumber + 1 <= numPages) {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

  onSlideChange = () => {
    //carusal get active slide
    this.setState({ pageNumber: 1, rotate: 0 });
    //it takes time to render in DOM
    setTimeout(() => {
      var currentIndex = $(".carousel-item.active").attr("id");
      let numPages = this.state.numPagesArr[currentIndex] || 1;
      this.setState({ numPages });

      this.settPreviewArrows();
    }, 700);
  };

  settPreviewArrows = () => {
    // Make the arrows be a bit smarter. Dont appear
    //if only one attachment, hide the left or right arrow if on "first" or "last" document.
    if ($(".carousel-item.active").attr("id") === "0") {
      $(".carousel-control-prev").hide();
      $(".carousel-control-next").show();
    } else if ($(".carousel-inner .carousel-item:last").hasClass("active")) {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").hide();
    } else {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").show();
    }
  };

  handlePDFRotate = () => {
    let { rotate } = this.state;
    this.setState({ rotate: rotate + 90 });
  };

  clonedBatchListGenerate = () => {
    let batchListOptions = [];
    let batchList = [];
    let clonedBatchList = [];
    batchList = this.props.setup.getBatchList || [];
    let unlockBatchList = batchList.filter((b) => b.lock != "Locked");

    unlockBatchList.map((b) =>
      batchListOptions.push({
        label: b.description + " (" + b.batchNo + ")",
        value: b.batchNo,
      })
    );
    clonedBatchList = batchList.map((batch) => ({
      ...batch,
      checked: false,
    }));
    this.setState({ batchList, batchListOptions, clonedBatchList, });
  }

  // END
  // Batch List Start
  getBtachList = async () => {
    await this.props.getBtachList("Timecard");
    if (this.props.setup.getBatchListSuccess) {
      // toast.success(this.props.setup.getBatchListSuccess)
      this.clonedBatchListGenerate()
    }
    if (this.props.setup.getBatchListError) {
      handleAPIErr(this.props.setup.getBatchListError, this.props);
    }

    this.props.clearSetupStates();

  };

  handleChangeBatchFields = (e, batch, index) => {
    let { batchList } = this.state;
    let { name, value } = e.target;
    batch[name] = value;
    batchList[index] = batch;
    this.setState({ batchList });
  };

  handleBatchCheckbox = (e, bNo) => {
    let { timecardsList, filteredTimecardList, clonedBatchList , selectedBatchNos} = this.state;
    let batchNo = "";
    // Toggle the 'checked' property based on the checkbox state
    clonedBatchList = clonedBatchList.map((batch) =>
      batch.batchNo === bNo ? { ...batch, checked: e.target.checked } : batch
    );

    // Update the selectedBatchNos array based on the checkbox state
    selectedBatchNos = e.target.checked ? [...selectedBatchNos, bNo] : selectedBatchNos.filter((batch) => batch !== bNo);

    this.setState({ batchNo: bNo })

    const clonedTimecardsList = JSON.parse(
      JSON.stringify(this.state.clonedTimecardsList)
    );

    if (e.target.checked) {
      batchNo = bNo;

      let filterdData = clonedTimecardsList.filter((c) => {
        return Number(c.batchNo) === Number(bNo);
      });

      timecardsList = filterdData;
      filteredTimecardList = filterdData;
    } else {
      //uncheck checkbox
      timecardsList = clonedTimecardsList;
      filteredTimecardList = clonedTimecardsList;
    }
    this.setState({
      batchNo,
      timecardsList,
      filteredTimecardList,
      clonedBatchList,
      selectedBatchNos,
    });
  };

  insertBatch = async (event) => {
    let { batchList } = this.state;

    let obj = _.maxBy(batchList, "batchNo"); //to fine the highest batch no
    let batchNo = Number(obj ? obj.batchNo : "") + 1;
    batchList = [
      ...batchList,
      {
        batchNo,
        description: "",
        type: "PR",
        notes: "",
        insertBatch: true,
      },
    ];
    this.setState({ batchList });
  };

  deleteBatch = async () => {
    let {
      batchList,
      batchNo,
      timecardsList,
      filteredTimecardList,
      clonedTimecardsList,
    } = this.state;

    let batch = batchList.find((b) => b.batchNo === batchNo);
    if (batch && batch.insertBatch) {
      //just remove the newly inserted batch, no need to call API
      batchList = batchList.filter((c) => c.batchNo !== batchNo);
      batchNo = "";

      this.setState({ batchList, batchNo });
    } else if (batchNo === 0 || batchNo) {
      this.setState({ isLoading: true });
      await this.props.deleteBatch(batchNo);
      if (this.props.setup.deleteBatchSuccess) {
        toast.success(this.props.setup.deleteBatchSuccess);

        batchList = batchList.filter((c) => c.batchNo !== batchNo);
        batchNo = "";

        clonedTimecardsList = _.cloneDeep(clonedTimecardsList);

        timecardsList = clonedTimecardsList;
        filteredTimecardList = clonedTimecardsList;
      }
      if (this.props.setup.deleteBatchError) {
        handleAPIErr(this.props.setup.deleteBatchError, this.props);
      }
      this.props.clearSetupStates();
      this.setState({
        isLoading: false,
        batchList,
        batchNo,
        timecardsList,
        filteredTimecardList,
      });
    } else {
      toast.error("Please Select Batch First!");
    }
  };

  addUpdateBatch = async (e, batch, index) => {
    let { batchList, batchListOptions } = this.state;

    let { name, value } = e.target;

    batch[name] = value;

    let bch = batchList[index];

    if (bch.insertBatch) {
      //insert new batch case

      let { batchNo } = batch;

      batchNo = batchNo.toString();
      batchNo = batchNo.trim();
      batchNo = batchNo ? Number(batchNo) : "";

      if (batchNo === 0 || batchNo) {
        let exists = batchList.find(
          (b) => b.batchNo === batchNo && !b.insertBatch
        ); //in case if batch no is already exists

        if (!exists) {
          this.setState({ isLoading: true });

          await this.props.insertBatch({ batch });

          if (this.props.setup.insertBatchSuccess) {
            toast.success(this.props.setup.insertBatchSuccess);
            batch.insertBatch = false;
            batchListOptions.push({
              label: batch[name] + " (" + batchNo + ")",
              value: batchNo,
            });
          }
          if (this.props.setup.insertBatchError) {
            handleAPIErr(this.props.setup.insertBatchError, this.props);
          }
          this.props.clearSetupStates();

          this.setState({ isLoading: false, batchListOptions });
        } else {
          toast.error("Batch No Already Exists!");
        }
      } else {
        toast.error("Please Enter Btach No!");
      }
    } else {
      //update batch case

      this.setState({ isLoading: true });

      await this.props.updateBatch({ batch });
      if (this.props.setup.updateBatchSuccess) {
        toast.success(this.props.setup.updateBatchSuccess);
        batchList[index] = batch;
      }
      if (this.props.setup.updateBatchError) {
        handleAPIErr(this.props.setup.updateBatchError, this.props);
      }

      this.props.clearSetupStates();
      this.setState({ isLoading: false, batchList });
    }
  };

  // END
  //approvals filter
  handleApprovalsFilters = (e, obj) => {
    let checked = e.target.checked;
    obj.checked = checked;

    let {
      approverOptions,
      clonedTimecardsList,
      timecardsList,
      filteredTimecardList,
    } = this.state;

    let foundIndex = approverOptions.findIndex((a) => a.id == obj.id);
    approverOptions[foundIndex] = obj;

    let check = false;
    let count = 0;
    approverOptions.map((a, i) => {
      if (!a.checked) {
        count += 1;
      }
    });
    if (approverOptions.length === count) {
      check = true;
    }
    clonedTimecardsList = _.cloneDeep(clonedTimecardsList);

    if (check) {
      //all checkboxes are uncheck
      timecardsList = clonedTimecardsList;
      filteredTimecardList = clonedTimecardsList;
    } else {
      let filterdData = [];

      approverOptions.map((a, i) => {
        let tcListFilterdData = [];
        if (a.checked) {
          tcListFilterdData = clonedTimecardsList.filter((c) => {
            return (
              c.approvalGroup &&
              c.approvalGroup.toUpperCase() === a.option.toUpperCase()
            );
          });
        }
        filterdData.push(...tcListFilterdData);
      });

      timecardsList = filterdData;
      filteredTimecardList = filterdData;
    }

    this.setState(
      {
        timecardsList,
        filteredTimecardList,
        approverOptions,
      },
      () => {
        this.handleSortTimecardList(this.state.sortTCListKeyName);
      }
    );
  };

  //Regenerate the preview of a timecard.
  regenerateTimecard = async () => {
    let { tran, previews, selectedGuid } = this.state;
    if (selectedGuid) {
      this.setState({
        isLoading: true,
      });

      await this.props.regenerateTimecard(selectedGuid);
      //success case of regenerate timecard
      if (this.props.timecard.regenerateTimecardSuccess) {
        toast.success(this.props.timecard.regenerateTimecardSuccess);
        previews = this.props.timecard.regenerateTimecard || [];
      }
      //error case of regenerate timecard
      if (this.props.timecard.regenerateTimecardError) {
        handleAPIErr(this.props.timecard.regenerateTimecardError, this.props);
      }
      this.setState({ isLoading: false, previews });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  hanldeExport = (name) => {
    if (name === "Excel Timecard") {
      this.exportTimeCard();
    } else if (name === "Excel Distribution") {
      this.exportDistribution();
    } else if (
      name === "TPH Payroll" &&
      this.state.showTallisTabPane?.toLowerCase() !== "draft"
    ) {
      this.exportTPHPayroll();
    }
  };

  //Export Timecard
  exportTimeCard = async () => {
    let { tran, multipleTrans, selectedGuid } = this.state;
    //deprecated
    if (tran || (multipleTrans && multipleTrans.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (multipleTrans.length > 0) {
        if (multipleTrans.length == 1) {
          _trans = multipleTrans[0];
        } else {
          _trans = multipleTrans;
        }
      } else {
        _trans = tran;
      }

      if (this.state.allSelected) {
        await this.props.exportTimecard({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.exportTimecard({ guid: selectedGuid });
      }

      this.setState({ isLoading: false });

      if (this.props.timecard.exportTimecardSuccess) {
        toast.success(this.props.timecard.exportTimecardSuccess);

        let obj = {
          contentType: "application/vnd.ms-excel",
          attachment: this.props.timecard.exportTimecard || "",
        };
        downloadAttachments(obj, "timecard");
      }

      if (this.props.timecard.exportTimecardError) {
        handleAPIErr(this.props.timecard.exportTimecardError, this.props);
      }
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  //Export Distribution
  exportDistribution = async () => {
    let { tran, multipleTrans, selectedGuid } = this.state;
    if (tran || (multipleTrans && multipleTrans.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (multipleTrans.length > 0) {
        if (multipleTrans.length == 1) {
          _trans = multipleTrans[0];
        } else {
          _trans = multipleTrans;
        }
      } else {
        _trans = tran;
      }

      if (this.state.allSelected) {
        await this.props.exportDistribution({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.exportDistribution({ guid: selectedGuid });
      }

      this.setState({ isLoading: false });

      if (this.props.timecard.exportDistributionSuccess) {
        toast.success(this.props.timecard.exportDistributionSuccess);

        let obj = {
          contentType: "application/vnd.ms-excel",
          attachment: this.props.timecard.exportDistribution || "",
        };
        downloadAttachments(obj, "distribution");
      }

      if (this.props.timecard.exportDistributionError) {
        handleAPIErr(this.props.timecard.exportDistributionError, this.props);
      }
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  //Export TPH Payroll
  exportTPHPayroll = async () => {
    let { tran, multipleTrans, selectedGuid } = this.state;
    if (tran || (multipleTrans && multipleTrans.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (multipleTrans.length > 0) {
        if (multipleTrans.length == 1) {
          _trans = multipleTrans[0];
        } else {
          _trans = multipleTrans;
        }
      } else {
        _trans = tran;
      }

      if (this.state.allSelected) {
        await this.props.exportTPHPayroll({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
        });
      } else {
        await this.props.exportTPHPayroll({ guid: selectedGuid });
      }

      if (this.props.timecard.exportTPHPayrollSuccess) {
        toast.success(this.props.timecard.exportTPHPayrollSuccess);
      }
      if (this.props.timecard.exportTPHPayrollError) {
        handleAPIErr(this.props.timecard.exportTPHPayrollError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  //Import Timecard
  importTimecard = async (excelData) => {
    this.setState({ isLoading: true });

    await this.props.importTimecard(excelData);

    if (this.props.timecard.importTimecardSuccess) {
      toast.success(this.props.timecard.importTimecardSuccess);
    }
    if (this.props.timecard.importTimecardError) {
      handleAPIErr(this.props.timecard.importTimecardError, this.props);
    }
    this.setState({ isLoading: false });
    this.props.clearTimecardStates();
  };

  //Move Batch
  moveBatch = async (batchNo) => {
    let { tran, multipleTrans, timecardsList, selectedGuid,  selectedMultipleGuid } = this.state;
    if ( selectedGuid || (multipleTrans && multipleTrans.length > 0)) {
      this.setState({ isLoading: true });

      let _trans = "";
      if (multipleTrans.length > 0) {
        _trans = selectedMultipleGuid;
      }else{
        _trans = [selectedGuid];
      }

      if (this.state.allSelected) {
        await this.props.moveBatch({
          selectAll: "Y",
          timecardType: this.state.showTallisTabPane,
          search: this.state.timecardListSearch,
          teamTimecards: this.state.teamTimecard,
          batchNo,
        });
      } else {
        await this.props.moveBatch({ guid: _trans, batchNo });
      }

      if (this.props.timecard.moveTimecardBatchSuccess) {
        toast.success(this.props.timecard.moveTimecardBatchSuccess);

        if (Array.isArray(_trans)) {
          timecardsList.map((tl) => {
            _trans.map((t) => {
              if (tl.guid === t) {
                tl.batchNo = batchNo;
              }
            });
          });
        } else {
          let tc = timecardsList.find((el) => el.guid === _trans) || "";
          tc.batchNo = batchNo;
        }
      }

      if (this.props.timecard.moveTimecardBatchError) {
        handleAPIErr(this.props.timecard.moveTimecardBatchError, this.props);
      }
      this.setState({ isLoading: false, timecardsList });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  //Balance Tax
  balanceTax = async () => {
    this.setState({ isLoading: true });
    await this.props.taxBalance();

    let timecardSuccess = this.props.timecard.taxBalanceSuccess;
    let timecardFail = this.props.timecard.taxBalanceError;

    this.props.clearTimecardStates();
    this.setState({ isLoading: false });

    if (timecardSuccess) {
      return toast.success(timecardSuccess);
    } else {
      return toast.error(timecardFail);
    }
  };

  modifyTimecard = async () => {
    let { tran, multipleTrans, showTimecardTallisTabPane, selectedGuid } =
      this.state;
    if (selectedGuid) {
      this.setState({
        isLoading: true,
      });
      await this.props.modifyTimecard(selectedGuid); // modify po
      //success case of modify Timecard
      if (this.props.timecard.modifyTimecardSuccess) {
        // toast.success(this.props.timecard.modifyTimecardSuccess);
        await this.getTimecardTallies(showTimecardTallisTabPane, true); //to refresh the list
      }
      //error case of modify Timecard
      if (this.props.timecard.modifyTimecardError) {
        handleAPIErr(this.props.timecard.modifyTimecardError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearTimecardStates();
    } else {
      toast.error("Please select Timecard First!");
    }
  };

  //Creating New Timecard

  //Update Timecard
  editTimecard = () => {
    //If Timecard belongs to a batch where lock = Locked disable timecard edit or approval\decline\move\delete
    let {
      tran,
      batchList,
      timecardsList,
      showTallisTabPane,
      guid,
      selectedGuid,
    } = this.state;
    if (tran || selectedGuid) {
      let activeTimeCard = timecardsList.find((tc) => tc.tran === tran) || "";
      let batchObject =
        batchList.find(
          (batchItem) =>
            Number(batchItem.batchNo) === Number(activeTimeCard.batchNo)
        ) || "";
      if (batchObject.lock === "Locked") {
        toast.error(
          "Timecard cannot be updated because its batchNo is locked!"
        );
        return;
      }
      this.setState({ ...this.state, resetPage: false }, () => {
        this.props.history.push("/add-edit-timecard", {
          tran,
          tallies: showTallisTabPane,
          guid,
          selectedGuid,
          newTc:false
        });
      });
    }
  };

  handleDownloadFilterChange = (filterName, value) => {
    this.setState({
      downloadOptions: {
        ...this.state.downloadOptions,
        [filterName]: value,
      },
    });
  };
  //moving to next tab
  moveToNextApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { tran, selectedGuid } = this.state;
    let data = {
      guid: selectedGuid,
      comment,
      email,
    };
    if (selectedGuid) {
      await this.props.moveToNextApprover(data);
      if (this.props.timecard.moveToNextApproverSuccess) {
        toast.success(
          this.props.timecard.moveToNextApproverSuccess[0]?.description
        );
        toast.success(
          this.props.timecard.moveToNextApproverSuccess[1]?.description
        );
        this.getTimecardTallies(this.state.showTallisTabPane, true);
      }
      if (this.props.timecard.moveToNextApproverError) {
        handleAPIErr(this.props.timecard.moveToNextApproverError, this.props);
      }
      this.setState({ isLoading: false });
    }
  };

  //moving to previous tab
  moveToPrevApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { tran } = this.state;
    let data = {
      tran,
      comment,
      email,
    };
    if (tran) {
      await this.props.moveToPrevApprover(data);
      if (this.props.timecard.moveToPrevApproverSuccess) {
        toast.success(
          this.props.timecard.moveToPrevApproverSuccess[0]?.description
        );
        toast.success(
          this.props.timecard.moveToPrevApproverSuccess[1]?.description
        );
        this.getTimecardTallies(this.state.showTallisTabPane, true);
      }
      if (this.props.timecard.moveToPrevApproverError) {
        handleAPIErr(this.props.timecard.moveToPrevApproverError, this.props);
      }
      this.setState({ isLoading: false });
    }
  };

  collapseIconsChanged = (name, res) => {
    let collapseIconObj = {
      ...this.state.collapseIcon,
      [name]: res
    };
    this.setState({ collapseIcon: collapseIconObj })
  }

  handleTransferTimecards = async (newUser) => {
    this.setState({ isLoading: true });

    let params = {};

    if (this.state.allSelected) {
      params = {
        newUser,
        selectAll: "Y",
        timecardType: this.state.showTallisTabPane,
        search: this.state.timecardListSearch,
        teamTimecards: this.state.viewTeam,
      };
      if (this.state.batchNo) {
        params['batchList'] = [this.state.batchNo];
      }

    } else {
      params = {
        newUser,
        guid: this.state.selectedMultipleGuid.length > 0
          ? this.state.selectedMultipleGuid
          : [this.state.selectedGuid],
      };
    }

    await this.props.transferTimecards(params);

    if (this.props.timecard?.transferTimecardSuccess) {
      toast.success('Successfully transferred timecards');
    } else if (this.props.timecard.transferTimecardError) {
      toast.error(this.props.timecard.transferTimecardError);
    } else {
      toast.error("Failed to transfer timecard");
    }
    this.setState({ isLoading: false });
  }



  render() {
    let userType = localStorage.getItem("userType")?.toLowerCase();
    let {
      showTallisTabPane,
      activeTimecard,
      activeTimecardTallis,
      openSendEFTmodal,
      openResendSTPFilemodal,
      openSendYearEndSTPFilemodal,
      openSendYearEndSTPFile,
      openTimeCardMoreDetail,
      openDeclineTimeCardmodal,
      sortTCListKeyName,
      viewTeam,
      timecardTallies,
      timecardsList,
      timecardListSearch,
      tran,
      attachmentSize,
      attachments,
      previews,
      toggleRightSidebar,
      dropdownZoomingValue,
      batchList,
      approverGroup,
      approverOptions,
      approvers,
      comments,
      exceptions,
      batchNo,
      allowEdit,
      clonedBatchList
    } = this.state;
    let tab = (showTallisTabPane && showTallisTabPane.toLowerCase()) || "";
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLowerCase();
    let { totalPages, display } = this.state;

    // display pending move button only if the timecard are was for approval by the current user
    let displayPendingMoveBtn = true;
    const getUserDetails = localStorage.getItem("getAccountDetails")
      ? JSON.parse(localStorage.getItem("getAccountDetails"))
      : undefined;

    if (
      getUserDetails &&
      getUserDetails.accountDetails?.userName !== this.state.timeCardUserName
    ) {
      displayPendingMoveBtn = false;
    }

    let approverNextPrevCheck = false;
    if (
      (tab === "pending" || tab === "approve") &&
      (userType === "admin" || userType === "sysadmin")
    ) {
      approverNextPrevCheck = true;
    }

    const exportOptions =
      tab === "draft"
        ? ["Excel Timecard", "Excel Distribution"]
        : ["Excel Timecard", "Excel Distribution", "TPH Payroll"];



    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="dashboard">
          {/* top nav bar */}
          <Header
            props={this.props}
            timecards={true}
            toggleTeamIcon={this.toggleTeamIcon}
            viewTeam={viewTeam}
          />
          {/* end */}

          {/* body part */}

          <div className="dashboard_body_content dash__invoice--content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}

            {/* side menu suppliers*/}
            <aside
              className="side-nav suppliers_side_nav mm_invoice_left_sidebar side__content--invoice"
              id="show-side-navigation1"
            >
              <div className="cus-arro-div2">
                <img
                  src="images/arrow-r.png"
                  className=" img-fluid cus-arro-r"
                  alt="user"
                />
              </div>
              <div className="search">
                <div className="row">
                  <div className="col-auto mb-2 pr-0">
                    <div className="form-group remember_check custom-checkbox-ui">
                      <input
                        type="checkbox"
                        id={"order"}
                        name="checkboxAll"
                        checked={this.state.allSelected}
                        onChange={(e) => this.handleCheckbox(e, "allCheck")}
                      />
                      <label
                        htmlFor={"order"}
                        className="mr-0 custom-box"
                      ></label>
                    </div>
                  </div>
                  <div className="col-auto pr-md-0 align-self-center ml-1">
                    <Dropdown
                      alignRight={false}
                      drop="down"
                      className="analysis-card-dropdwn custom-my-radio user_drop_options"
                    >
                      <Dropdown.Toggle
                        variant="sucess"
                        id="dropdown-basic"
                        className="custom-angle-down"
                      >
                        <img src="images/angle-down.png" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortTimecardList("employeeName")
                          }
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="employeeName"
                              name="employeeName"
                              onChange={() => { }}
                              checked={sortTCListKeyName === "employeeName"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="lastName"
                            >
                              Employee Name
                            </label>
                            {
                              sortTCListKeyName === "employeeName" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortTimecardList("department")
                          }
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="department"
                              name="department"
                              onChange={() => { }}
                              checked={sortTCListKeyName === "department"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="department"
                            >
                              Department{" "}
                            </label>
                            {
                              sortTCListKeyName === "department" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortTimecardList("position")
                          }
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="position"
                              name="position"
                              onChange={() => { }}
                              checked={sortTCListKeyName === "position"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="position"
                            >
                              Position{" "}
                            </label>
                            {
                              sortTCListKeyName === "position" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() => this.handleSortTimecardList("date")}
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="date"
                              name="date"
                              onChange={() => { }}
                              checked={sortTCListKeyName === "date"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="date"
                            >
                              Date{" "}
                            </label>
                            {
                              sortTCListKeyName === "date" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() => this.handleSortTimecardList("group")}
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="group"
                              name="group"
                              onChange={() => { }}
                              checked={sortTCListKeyName === "group"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="group"
                            >
                              Group{" "}
                            </label>
                            {
                              sortTCListKeyName === "group" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() => this.handleSortTimecardList("tran")}
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="tran"
                              name="tran"
                              onChange={() => { }}
                              checked={sortTCListKeyName === "tran"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="tran"
                            >
                              Tran{" "}
                            </label>
                            {
                              sortTCListKeyName === "tran" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        {localStorage.getItem("usePageLoading") == "Y" ||
                        localStorage.getItem("useTCPageLoading") == "Y" ? (
                          <div className="custom-control custom-radio cutm-pr-right">
                            <div className="settings_display_row flex__wrapper-inner">
                              <label className="labelwrapper__iner lable-inner-wrapper">
                                Display Rows Per Page
                              </label>
                              <input
                                className="form-control input__wrapper--inner "
                                type="number"
                                min="1"
                                defaultValue={display}
                                onBlur={this.handleRowsSetting}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Dropdown.Menu>
                    </Dropdown>{" "}
                  </div>
                  <div className="col input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <img
                          src="images/search-icon.png"
                          className="mx-auto"
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What are you looking for"
                      aria-label="What are you looking for"
                      aria-describedby="basic-addon1"
                      name="timecardListSearch"
                      value={timecardListSearch}
                      onChange={(e) =>
                        localStorage.getItem("usePageLoading") == "Y"
                          ? this.handleChangeNewTCListSearch(e)
                          : this.handleChangeTCListSearch(e)
                      }
                      onKeyDown={(e) =>
                        localStorage.getItem("usePageLoading") == "Y"
                          ? this.onNewTCListSearch(e)
                          : this.onTCListSearch(e)
                      }
                    />
                  </div>
                </div>
              </div>

              <ul
                className={
                  Number(totalPages) !== 0
                    ? localStorage.getItem("usePageLoading") == "Y" ||
                    localStorage.getItem("useTCPageLoading") == "Y"
                      ? "suppliers_list list__color-bottom order-ul"
                      : "suppliers_list"
                    : "suppliers_list"
                }
              >
                {timecardsList.map((l, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        l.teamTimecard === "Y"
                          ? timecardsList[i + 1] &&
                          timecardsList[i + 1].teamTimecard &&
                          timecardsList[i + 1].teamTimecard === "Y"
                            ? "teamOrdersBg teamOrdersBorder2 cursorPointer"
                            : "teamOrdersBg teamOrdersBorder cursorPointer"
                          : activeTimecard === l.id
                            ? "active cursorPointer"
                            : "cursorPointer"
                      }
                      id={l.id}
                    >
                      <div className="row">
                        <div className="col-auto mb-2 pr-0 d-flex flex-column justify-content-between">
                          <div className="form-group remember_check">
                            <input
                              type="checkbox"
                              id={"timecard" + i}
                              checked={l.checked}
                              name="checkbox"
                              onChange={(e) => this.handleCheckbox(e, l)}
                            />
                            <label
                              htmlFor={"timecard" + i}
                              className="mr-0"
                            ></label>
                          </div>
                          <div className="tc-status" style={{ backgroundColor: l.codeStatus ?? '' }}></div>

                        </div>
                        <div
                          onClick={() => this.getTimecardSummary(l)}
                          className="col pl-0"
                        >
                          <div className="invioce_data pr-sm-3">
                            <h4>{l.employeeName}</h4>
                            <div className="row">
                              <div className="col data-i">
                                <p> {l.department}</p>
                              </div>
                              <div className="col-auto data-i">
                                <p>{l.position} </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i">
                                <p>
                                  <b>Total Hours:</b> {l.totalHours}
                                </p>
                              </div>
                              <div className="col-auto data-i">
                                <p>
                                  <b>Date:</b>{" "}
                                  {l.weekEndingDate
                                    ? l.weekEndingDate.trim()
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i">
                                <p></p>
                              </div>
                              <div className="col-auto data-i">
                                <div className="text-center cursorPointer">
                                  <p
                                    onClick={(e) =>
                                      this.clickHandlerMoredetails(e, l)
                                    }
                                  >
                                    <p className="more-details-color text__underline-wrapper">
                                      more details
                                    </p>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {Number(totalPages) !== 0 ? (
                localStorage.getItem("usePageLoading") == "Y" ||
                localStorage.getItem("useTCPageLoading") == "Y" ? (
                  <div className="pagination__list-custom">
                    <p className="mb__zero pt-top-container">
                      Showing {this.state.pageStart} to {this.state.pageEnd} of{" "}
                      {this.state.totalTimecards} entries
                    </p>
                    <ReactPaginate
                      tabIndex="-1"
                      previousLabel={"prev"}
                      pageLinkClassName={"pagination__list-custom-link   "}
                      pageClassName={"pagination__list-custom-li "}
                      activeLinkClassName={"pagination__tab-custm"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        "pagination cursorPointer align-center-container"
                      }
                      activeClassName={"active"}
                      forcePage={this.state.page - 1}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </aside>
            {/* {/ end /} */}

            <section id="contents" className="supplier pr-0 pt-0">
              <div className="body_content ordermain-padi body__invoice--top">
                <div className="container-fluid pl-0 ">
                  <div className="main_wrapper ">
                    <div className="row d-flex pl-15">
                      <div className="col-12 w-100 order-tabs p-md-0">
                        {/* Timecard Tallies */}
                        <div className="nav_tav_ul">
                          <ul className="nav nav-tabs">
                            {timecardTallies.map((t, i) => {
                              return (
                                <li
                                  key={i}
                                  className="nav-item cursorPointer"
                                  onClick={
                                    () => this.handleTimecardTallies(t.type, true)
                                    // this.checkTCList_API(t, "tallies")
                                  }
                                >
                                  <a
                                    className={
                                      parseInt(activeTimecardTallis)  === parseInt(t.id)
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                  >
                                    {t.type}{" "}
                                    <span className="stats">{t.count}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="bg-gry content__elem--style mm_top_nav">
                          <div className="w-100 float-left mm_lr_pad ">
                            <div className="mm_tab_left invoice_left">
                              <div className="tab-content">
                                {tab === "draft" && (
                                  <div className="tab-pane container active">
                                    <ul>
                                      <li
                                        onClick={() => {
                                          this.props.history.push(
                                            "/add-edit-timecard",
                                            {
                                              tran: "newTimecard",
                                              page: this.state.page,
                                              selectedGuid: this.state.selectedGuid,
                                              newTc:true
                                            }
                                          );
                                        }}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/add.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> New </Link>{" "}
                                      </li>
                                      <li
                                        onClick={this.editTimecard}
                                        // onClick={()=> this.openModal('openInvoiceDetailModal')}

                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>{" "}
                                      </li>
                                      <li
                                        onClick={() =>
                                          timecardsList.length > 0
                                            ? this.deleteTimecard()
                                            : () => { }
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/delete.svg"
                                          className="invoice-delete-icon img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Delete </Link>
                                      </li>

                                      <li
                                        className="cursorPointer"
                                        onClick={() =>
                                          timecardsList.length > 0
                                            ? this.sendForApproval()
                                            : () => { }
                                        }
                                      >
                                        <img
                                          src="images/send.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Send </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "approve" && (
                                  <div
                                    className={
                                      timecardsList.lenght === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.approveTimecard}
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        onClick={this.holdTimecard}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/move.png"
                                          className=" img-fluid"
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Hold </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.declineTimeCard}
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link> Decline </Link>
                                      </li>
                                      {allowEdit === "Y" ? (
                                        <li
                                          onClick={this.editTimecard}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Edit </Link>{" "}
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                    </ul>
                                  </div>
                                )}
                                {tab === "declined" && (
                                  <div
                                    className={
                                      timecardsList.lenght === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active d-flex"
                                    }
                                  >
                                    <ul>
                                      {(
                                        (userType && userType.toLowerCase() === "op/approver") ||
                                        (userType && userType.toLowerCase() === "operator") ||
                                        (userType && userType.toLowerCase() === "admin")

                                      ) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={() =>
                                            this.handleMoveTimeCard("declined")
                                          }
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move to Drafts </Link>
                                        </li>
                                      )}
                                      {(
                                          (userType && userType.toLowerCase() === "op/approver") ||
                                          (userType && userType.toLowerCase() === "admin") ||
                                          (userType && userType.toLowerCase() === "approver")
                                        )
                                        && (
                                          <li
                                            className="cursorPointer"
                                            onClick={()=> this.handleMoveToApproveTimecard()}
                                          >
                                            <img
                                              src="images/move.png"
                                              className=" img-fluid "
                                              alt="user"
                                            />{" "}
                                            <Link to="#"> Move to Approve </Link>
                                          </li>
                                        )}
                                      {(
                                        (userType && userType.toLowerCase() === "approver") ||
                                        (userType && userType.toLowerCase() === "op/approver") ||
                                        (userType && userType.toLowerCase() === "admin")
                                      ) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.approveTimecard}
                                        >
                                          <img
                                            src="images/tick.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Approve </Link>
                                        </li>
                                      )}
                                      {((userType &&
                                            userType.toLowerCase() ===
                                            "operator") ||
                                          (userType &&
                                            userType.toLowerCase() ===
                                            "op/approver") ||
                                          (userType &&
                                            userType.toLowerCase() ===
                                            "approver")) &&
                                        allowEdit === "Y" && (
                                          <li
                                            className="cursorPointer"
                                            onClick={this.editTimecard}
                                          >
                                            <img
                                              src="images/pencill.png"
                                              className=" img-fluid "
                                              alt="user"
                                            />{" "}
                                            <Link to="#"> Edit </Link>
                                          </li>
                                        )}
                                    </ul>
                                  </div>
                                )}
                                {tab === "hold" && (
                                  <div
                                    className={
                                      timecardsList.lenght === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.approveTimecard}
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.declineTimeCard}
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Decline </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "pending" && (
                                  <div
                                    className={
                                      timecardsList.lenght === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      {(
                                        (userType && userType.toLowerCase() === "operator") ||
                                        (userType && userType.toLowerCase() === "op/approver"
                                          // &&
                                          // displayPendingMoveBtn
                                        ) ||
                                        (userType && userType.toLowerCase() === "admin")

                                      ) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={() => this.handleMoveTimeCard("pending")}
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                                {tab === "approved" && (
                                  <div
                                    className={
                                      timecardsList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      {tab === "approved" && (
                                        <li
                                          onClick={() =>
                                            this.openModal("openModifyModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Modify </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                                {tab === "all" && (
                                  <div
                                    className={
                                      timecardsList.lenght === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li></li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mm_tab_center invoice_right">
                              <div className="slider-panel">
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomOut}
                                    src="images/minus.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomIn}
                                    src="images/add.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Select
                                  className="width-selector"
                                  value={dropdownZoomingValue}
                                  classNamePrefix="custon_select-selector-inner"
                                  options={options}
                                  onChange={this.handleDropdownZooming}
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalCross}
                                    src="images/fulls.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="full_screen"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalArrow}
                                    src="images/twoarow.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="expand"
                                  />{" "}
                                </Link>
                                <span
                                  onClick={this.handlePDFRotate}
                                  className="cursorPointer"
                                >
                                  <img
                                    src="images/undo.png"
                                    className=" img-fluid float-left undo-img"
                                    alt="user"
                                  />
                                </span>

                                <Link
                                  to="#"
                                  className="zom-img float-right ml-md-5 mr-0 pl-2 pr-2 more-d mt-0"
                                >
                                  <img
                                    src="images/more.png"
                                    className=" img-fluid"
                                    alt="user"
                                  />{" "}
                                </Link>

                                <Link
                                  to="#"
                                  className="zom-img float-right mt-0"
                                  onClick={this.moveToNextTimecard}
                                >
                                  <img
                                    src="images/arow-r.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="next"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mtop-1"
                                  onClick={this.moveToPrevTimecard}
                                >
                                  <img
                                    src="images/arow-l.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="prev"
                                  />{" "}
                                </Link>

                                {tab === "approve" || tab === "pending" ? (
                                  <div className="float-right   mt-0 refresh-btn ">
                                    {tab === "approve" || tab === "pending" ? (
                                      <ul>
                                        <li
                                          className="cursorPointer"
                                          onClick={this.getAttachmentsList}
                                        >
                                          <img
                                            src="images/refresh.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />
                                          <Link to="#">Refresh</Link>
                                        </li>
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="side-attachments-2 height-2 mm_invoice_sidebar2 aside__right--height">
                                  <div
                                    onClick={this.regenerateTimecard}
                                    className="main-sec-attach main-bg"
                                  >
                                    Regenerate
                                  </div>

                                  {userType === 'admin' && <div
                                    onClick={() => this.setState({showTransferRecordsModal: true})}
                                    className="main-sec-attach main-bg"
                                  >
                                    Transfer Records
                                  </div>}
                                  <div className="main-sec-attach main-bg" onClick={(e) => this.collapseIconsChanged('download', !this.state.collapseIcon.download)}>
                                    <span
                                      className="invoice-inherit"
                                      data-toggle="collapse"
                                      data-target="#download_timecard_copies"
                                    >
                                      <span className={this.state.collapseIcon.download ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1"}></span>
                                      Download
                                      <div className="float-right">
                                        <span className="mr-2">
                                          {this.state.multipleTrans.length >
                                          0 &&
                                          this.state.multipleTrans.includes(
                                            this.state.tran
                                          )
                                            ? `${this.state.multipleTrans.length} records`
                                            : ""}
                                          {this.state.multipleTrans.length >
                                          0 &&
                                          !this.state.multipleTrans.includes(
                                            this.state.tran
                                          )
                                            ? `${this.state.multipleTrans
                                              .length + 1
                                            } records`
                                            : ""}
                                          {this.state.multipleTrans.length === 0
                                            ? "1 record"
                                            : ""}
                                        </span>
                                        <img
                                          onClick={this.downloadPreview}
                                          src="images/downlod.png"
                                          className=" img-fluid  fa"
                                          alt="user"
                                        />
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="download_timecard_copies"
                                  >
                                    <div className="pl-2 mb-3">
                                      <div className="form-group remember_check d-flex">
                                        <div className="checkSide">
                                          <input
                                            type="checkbox"
                                            id={"includeTimeSheets"}
                                            name={"includeTimeSheets"}
                                            checked={
                                              this.state.downloadOptions
                                                .includeTimeSheets
                                            }
                                            onChange={(e) =>
                                              this.handleDownloadFilterChange(
                                                "includeTimeSheets",
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={"includeTimeSheets"}
                                          ></label>
                                          <span className="text-mar">
                                            Include Timesheets
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pl-2 mb-3">
                                      <div className="form-group remember_check d-flex">
                                        <div className="checkSide">
                                          <input
                                            type="checkbox"
                                            id={"includePayslips"}
                                            name={"includePayslips"}
                                            checked={
                                              this.state.downloadOptions
                                                .includePayslips
                                            }
                                            onChange={(e) =>
                                              this.handleDownloadFilterChange(
                                                "includePayslips",
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={"includePayslips"}
                                          ></label>
                                        </div>
                                        <span className="text-mar">
                                          Include Payslips
                                        </span>
                                      </div>
                                    </div>

                                    <div className="pl-2 mb-3">
                                      <div className="form-group remember_check d-flex">
                                        <div className="checkSide">
                                          <input
                                            type="checkbox"
                                            id={"includeOther"}
                                            name={"includeOther"}
                                            checked={
                                              this.state.downloadOptions
                                                .includeOther
                                            }
                                            onChange={(e) =>
                                              this.handleDownloadFilterChange(
                                                "includeOther",
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={"includeOther"}
                                          ></label>
                                        </div>
                                        <span className="text-mar">
                                          Include Other
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="main-sec-attach main-bg" onClick={(e) => this.collapseIconsChanged('approvals', !this.state.collapseIcon.approvals)}>
                                    <span
                                      className="approvals-inherit"
                                      data-toggle="collapse"
                                      data-target="#Approvals_invoice1"
                                    >
                                      <span className={this.state.collapseIcon.approvals ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1"} ></span>
                                      Approvals
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Approvals_invoice1"
                                  >
                                    {approverOptions.map((a, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div className="form-group remember_check d-flex">
                                            <div className="checkSide">
                                              {/* <input
                                                type="checkbox"
                                                id={i + "timecard"}
                                                name={a.option}
                                                checked={a.checked}
                                                onChange={(e) =>
                                                  this.handleApprovalsFilters(
                                                    e,
                                                    a
                                                  )
                                                }
                                              /> */}
                                              <label htmlFor={i + "timecard"}>
                                                {" "}
                                              </label>
                                            </div>
                                            <span className="text-mar">
                                              {a.option}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div className="main-sec-attach main-bg" >
                                    <span
                                      onClick={(e) => this.collapseIconsChanged('changes', !this.state.collapseIcon.changes)}
                                      className={this.state.collapseIcon.changes ? "fa fa-angle-up float-left mr-2 sideBarAccord1" : "fa fa-angle-down float-left mr-2 sideBarAccord1"}
                                      data-toggle="collapse"
                                      data-target="#Changes_invoice"
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={() =>
                                        this.openModal("openChangesModal")
                                      }
                                    >
                                      Changes
                                      <span className="ml-3 font-weight-bold">
                                        {[].length}
                                      </span>
                                      <span className="fa fa-angle-right"></span>
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Changes_invoice"
                                  >
                                    {[].map((c, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          <p className="m-clr s-bold mb-0">
                                            {c.userName}
                                          </p>
                                          {c.description}
                                          <p className="gry-clr mb-0">
                                            {c.date} {c.time}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div className="main-sec-attach main-bg" >
                                    <span
                                      onClick={(e) => this.collapseIconsChanged('activity', !this.state.collapseIcon.activity)}
                                      className={this.state.collapseIcon.activity ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1"}
                                      data-toggle="collapse"
                                      data-target="#Activity_invoice"
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={() =>
                                        this.openModal("openActivityModal")
                                      }
                                    >
                                      Activity
                                      <span className="fa fa-angle-right"></span>
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Activity_invoice"
                                  >
                                    {this.state.activity?.map((a, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          {a.comment}
                                          <p className="gry-clr mb-0">
                                            {a.date} {a.status} {a.time}{" "}
                                            {a.userName}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>


                                  <div className="main-sec-attach main-bg" onClick={(e) => this.collapseIconsChanged('export', !this.state.collapseIcon.export)} >
                                    <span
                                      className="export_crd"
                                      data-toggle="collapse"
                                      data-target="#export"
                                    >
                                      <span className={this.state.collapseIcon.export ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1"}></span>
                                      Export
                                    </span>
                                  </div>
                                  <div className="collapse" id="export">
                                    {exportOptions.map((op, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="pl-2 mb-3"
                                          onClick={() => this.hanldeExport(op)}
                                        >
                                          <div className="form-group remember_check d-flex">
                                            <span className="text-mar cursorPointer ml-38">
                                              {op}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div className="main-sec-attach main-bg" onClick={(e) => this.collapseIconsChanged('tphPayroll', !this.state.collapseIcon.tphPayroll)}>
                                    <span
                                      className="tph_payroll"
                                      data-toggle="collapse"
                                      data-target="#tphpay"
                                    >
                                      <span className={this.state.collapseIcon.tphPayroll ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1"} ></span>
                                      TPH Payroll
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="tphpay"
                                  >
                                    {[
                                      "Send Reports",
                                      "Lock & Invoice",
                                      "Create and Send EFT/STP Files",
                                      "Send Payslips to employees",
                                      "Post & Upload Data",
                                      "Resend STP File",
                                      "Send Year End STP File",
                                    ].map((name, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="pl-2 mb-3"
                                          onClick={() =>
                                            this.hanldeTPHpayoll(name)
                                          }
                                        >
                                          <div className="form-group remember_check d-flex">
                                            <span className="text-mar cursorPointer ml-38">
                                              {name}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div
                                    onClick={() =>
                                      this.openModal("openReportModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Reports
                                  </div>
                                  <div
                                    className="main-sec-attach main-bg"
                                    onClick={this.balanceTax}
                                  >
                                    Tax Balance
                                  </div>
                                  {tab === "draft" ||
                                  (tab === "approve" &&
                                    userType.toLowerCase() ===
                                    "op/approver") ? (
                                    <div
                                      onClick={() =>
                                        this.openModal("openImportModal")
                                      }
                                      className="main-sec-attach main-bg"
                                    >
                                      Import
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div
                                    onClick={() =>
                                      this.setState({ openMoveModal: true })
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Move
                                  </div>

                                  {/* batch list start here  */}
                                  <div className="main-sec-attach main-bg" onClick={(e) => this.collapseIconsChanged('batchList', !this.state.collapseIcon.batchList)}>
                                    <span
                                      className="batchList-inherit"
                                      data-toggle="collapse"
                                      data-target="#batchlist"
                                    >
                                      <span className={this.state.collapseIcon.batchList ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1"}></span>
                                      Batch List
                                    </span>
                                  </div>
                                  <div className="collapse" id="batchlist">
                                    <div className="pl-2 mb-3">
                                      <div className="text-right pb-2 pr-4">
                                        <span
                                          className="cursorPointer mr-3"
                                          href="#"
                                          onClick={this.insertBatch}
                                        >
                                          <img
                                            src="images/add.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />
                                        </span>
                                        <span
                                          className="cursorPointer"
                                          onClick={this.deleteBatch}
                                        >
                                          <img
                                            src="images/delete.svg"
                                            className="invoice-delete-icon img-fluid "
                                            alt="user"
                                          />
                                        </span>
                                      </div>
                                      <table className="table table-bordered mb-0 order-collapse-table batch-list-table">
                                        <tbody>
                                        <tr>
                                          <th></th>
                                          <th>Description</th>
                                          <th>Batch</th>
                                          <th></th>
                                        </tr>
                                        {clonedBatchList.map((b, i) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div className="form-group remember_check">
                                                  <input
                                                    type="checkbox"
                                                    id={"batch" + i}
                                                    name="batch_checked"
                                                    checked={
                                                      b.checked
                                                    }
                                                    onChange={(e) =>
                                                      this.handleBatchCheckbox(
                                                        e,
                                                        b.batchNo
                                                      )
                                                    }
                                                  />

                                                  <label
                                                    htmlFor={"batch" + i}
                                                  ></label>
                                                </div>
                                              </td>
                                              <td>
                                                <input
                                                  className="border-0"
                                                  name="description"
                                                  value={b.description}
                                                  onBlur={(e) =>
                                                    this.addUpdateBatch(
                                                      e,
                                                      b,
                                                      i
                                                    )
                                                  }
                                                  onChange={(e) =>
                                                    this.handleChangeBatchFields(
                                                      e,
                                                      b,
                                                      i
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                {b.insertBatch ? (
                                                  <input
                                                    className="border-0"
                                                    name="batchNo"
                                                    value={b.batchNo}
                                                    onBlur={(e) =>
                                                      this.addUpdateBatch(
                                                        e,
                                                        b,
                                                        i
                                                      )
                                                    }
                                                    onChange={(e) =>
                                                      this.handleChangeBatchFields(
                                                        e,
                                                        b,
                                                        i
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  b.batchNo
                                                )}
                                              </td>
                                              <td></td>
                                            </tr>
                                          );
                                        })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  {/* end  Batch list  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="img-section-t col-12 pl-0">
                      {/* <img src="images/image6.png" className=" img-fluid" alt="user" />  */}

                      <div
                        className={
                          toggleRightSidebar
                            ? " mm_ordr1 order_pdf_new invo_margin"
                            : " mm_ordr1 order_pdf_expand1 m_auto"
                        }
                        id="overload_image--invoice"
                      >
                        <div
                          id="maped_image"
                          className="order_pfd over_auto_remove"
                          style={{ background: "#fff" }}
                        >
                          {timecardsList.length > 0 && (
                            <div
                              id="demo"
                              className={
                                toggleRightSidebar
                                  ? " carousel slide invoice_carousel mm_invoice_div over_auto_remove invoice-carowsel-new"
                                  : " carousel slide invoice_carousel invoice-carowsel-new "
                              }
                              // className="carousel slide invoice_carousel mm_invoice_div"
                              // data-ride="carousel"
                              data-interval={false}
                            >
                              <ul className="carousel-indicators">
                                <li
                                  data-target="#demo"
                                  data-slide-to="0"
                                  className="active"
                                ></li>
                                <li data-target="#demo" data-slide-to="1"></li>
                                <li data-target="#demo" data-slide-to="2"></li>
                              </ul>
                              <div className="carousel-inner">
                                {previews.length > 0
                                  ? previews.map((p, i) => {
                                    return (
                                      <div
                                        className={
                                          i === 0
                                            ? "carousel-item active "
                                            : "carousel-item "
                                        }
                                        id={i}
                                      >
                                        <div className="text-center">
                                          <div className="invoice_pdf_canvas invoice_pdf_new pdf--buttons pdf__height--content invoice-pdf-height">
                                            {p.file ? (
                                              <>
                                                <Document
                                                  file={
                                                    "data:application/pdf;base64," +
                                                    p.file
                                                  }
                                                  onLoadSuccess={(data) =>
                                                    this.onDocumentLoadSuccess(
                                                      data,
                                                      i
                                                    )
                                                  }
                                                  rotate={this.state.rotate}
                                                >
                                                  <Page
                                                    pageNumber={
                                                      this.state.pageNumber
                                                    }
                                                    scale={this.state.scaling}
                                                    height="372"
                                                    onLoadSuccess={
                                                      this.onLoadSuccessPage
                                                    }
                                                  />
                                                </Document>
                                                <div className="page-controls">
                                                  <button
                                                    type="button"
                                                    disabled=""
                                                    onClick={
                                                      this.goToPrevPage
                                                    }
                                                  >
                                                    ‹
                                                  </button>
                                                  <span>
                                                    {this.state.pageNumber} of{" "}
                                                    {this.state.numPages}
                                                  </span>{" "}
                                                  <button
                                                    type="button"
                                                    onClick={
                                                      this.goToNextPage
                                                    }
                                                  >
                                                    ›
                                                  </button>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : "No Preview Found"}

                                {previews.length > 1 && (
                                  <>
                                    <a
                                      className="carousel-control-prev"
                                      href="#demo"
                                      data-slide="prev"
                                      onClick={this.onSlideChange}
                                    >
                                      <i>
                                        {" "}
                                        <span className="carousel-control-prev-icon"></span>
                                      </i>
                                    </a>
                                    <a
                                      className="carousel-control-next"
                                      href="#demo"
                                      data-slide="next"
                                      onClick={this.onSlideChange}
                                    >
                                      <i>
                                        {" "}
                                        <span className="carousel-control-next-icon"></span>
                                      </i>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="side-attachments mm_invoice_sidebar mm_invoice_sidebar_right aside__right--height">
                        <div
                          className="cus-arro-div"
                          onClick={this.handleRightSidebar}
                        >
                          <img
                            src="images/arrow-r.png"
                            className=" img-fluid cus-arro-r"
                            alt="user"
                          />
                        </div>
                        <div className="side-attack">
                          <div className="main-sec-attach main-bg">
                            {/*Timecard Attachments */}
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Attachments_invoice"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openAttachmentsModal")
                              }
                            >
                              Attachments
                              <span className="ml-3 font-weight-bold">
                                {attachments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              <p className="float-right mr-3">
                                <img
                                  src="images/add.png"
                                  className=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </p>
                            </span>
                          </div>
                          <div
                            className="collapse show"
                            id="Attachments_invoice"
                          >
                            {attachments.map((a, i) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.getAttachment(
                                      a.recordID,
                                      a.contentType,
                                      a.fileName
                                    )
                                  }
                                  key={i}
                                  className="main-sec-attach"
                                >
                                  {a.fileName}{" "}
                                  <span className="fa fa-angle-right"></span>
                                </div>
                              );
                            })}
                          </div>

                          <div className="main-sec-attach main-bg" onClick={(e) => this.collapseIconsChanged('exception', !this.state.collapseIcon.exception)}>
                            {/*Timecard Attachments */}
                            <span
                              className={this.state.collapseIcon.exception ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1"}
                              data-toggle="collapse"
                              data-target="#Exceptions"
                            ></span>
                            <span className="name_attached">
                              Exceptions
                              <span className="ml-3 font-weight-bold">
                                {exceptions.length}
                              </span>
                              {/* <span className="fa fa-angle-right"></span> */}
                              {/* <p className="float-right mr-3">
                                <img
                                  src="images/add.png"
                                  className=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </p> */}
                            </span>
                          </div>
                          <div className={this.state.collapseIcon.exception ? "collapse show" : "collapse"} id="Exceptions">
                            {exceptions.map((a, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {a.userName}
                                  </p>
                                  {a.comment}
                                  <p className="gry-clr mb-0">
                                    {a.date} {a.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                          {/* Exceptions end */}

                          {/* side menue Approvers / Approvals */}

                          <div className="main-sec-attach main-bg">
                            <span
                              className="invoice-inherit"
                              data-toggle="collapse"
                              data-target="#Approvals_invoice"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              Approvals
                            </span>
                          </div>
                          <div className="collapse show" id="Approvals_invoice">
                            {approverGroup && approverGroup.trim() && (
                              <div className="main-sec-mid1">
                                {approverNextPrevCheck && (
                                  <button
                                    type="button"
                                    className="btn-save1 add_comment_btns1"
                                    onClick={() =>
                                      this.openModal(
                                        "openCheckApprovalModal",
                                        "Prev"
                                      )
                                    }
                                  >
                                    Previous
                                  </button>
                                )}
                                {approverGroup}

                                {approverNextPrevCheck && (
                                  <button
                                    type="button"
                                    className="btn-save1 add_comment_btns"
                                    onClick={() =>
                                      this.openModal(
                                        "openCheckApprovalModal",
                                        "Next"
                                      )
                                    }
                                  >
                                    Next
                                  </button>
                                )}
                              </div>
                            )}

                            {approvers.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach cus-check"
                                >
                                  <div className="form-group remember_check">
                                    {a.status === "Approved" ? (
                                      <input type="checkbox" id={i} checked />
                                    ) : (
                                      ""
                                    )}
                                    {a.status === "Declined" ? (
                                      <i
                                        className="fa fa-times circle-check1 float-left ml-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : a.status === "Current" ? (
                                      <i
                                        className="fa fa-circle-thin circle-check float-left ml-1"
                                        aria-hidden="true"
                                        style={{ marginTop: "3px" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor={i}>
                                      {" "}
                                      <span
                                        title={a.approverName}
                                        className={`${
                                          a.status === "Declined"
                                            ? "order-right-color2 selected mm_lcapp"
                                            : a.status === "Current"
                                              ? "order-right-color1 selected mm_lcapp"
                                              : "text-mar"
                                        } approver-name-lbl 
                                            ${
                                          a.status === "Current" ||
                                          a.status === "Declined"
                                            ? "approver-name-lbl-wide"
                                            : ""
                                        }
                                            ${
                                          userType === "admin" &&
                                          a.status === "Current"
                                            ? "approver-name-lbl-medium"
                                            : ""
                                        }`}
                                      >
                                        {a.approverName}
                                      </span>
                                      {a.status === "Declined" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (user declined)
                                        </span>
                                      ) : a.status === "Current" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (current approver)
                                          {userType === "admin" && (
                                            <button
                                              onClick={() => this.resendEmail()}
                                              className="btn-save2"
                                            >
                                              Send Email
                                            </button>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {/* Timecard Comments */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Comments_invoice"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openCommentsModal")
                              }
                            >
                              Comments
                              <span className="ml-3 font-weight-bold">
                                {comments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              {this.state.showTallisTabPane === "Draft" ||
                              this.state.showTallisTabPane === "Pending" ||
                              this.state.showTallisTabPane === "Approve" ||
                              this.state.showTallisTabPane === "Hold" ||
                              this.state.showTallisTabPane === "Declined" ? (
                                <a className="float-right mr-3" href="#">
                                  <img
                                    src="images/add.png"
                                    className=" img-fluid sidebarr_plus "
                                    alt="user"
                                  />
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="collapse show" id="Comments_invoice">
                            {comments.map((c, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {c.userName}
                                  </p>
                                  {c.comment}
                                  <p className="gry-clr mb-0">
                                    {c.date} {c.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end */}
        </div>

        <Attachments
          openAttachmentsModal={this.state.openAttachmentsModal}
          closeModal={this.closeModal}
          addAttachment={this.addAttachment}
          attachments={attachments}
          getAttachment={this.getAttachment}
          attachmentSize={attachmentSize}
          draft={tab === "draft" ? true : false} //to hide/show "Drag Files in or Click to Upload" box
        />

        <Comments
          openCommentsModal={this.state.openCommentsModal}
          closeModal={this.closeModal}
          comments={comments}
          addComment={this.addComment}
          tab={tab}
        />

        {/* <Activity
          openActivityModal={this.state.openActivityModal}
          closeModal={this.closeModal}
          activity={[]}
        /> */}

        <TimecardActivity
          openActivityModal={this.state.openActivityModal}
          closeModal={this.closeModal}
          activity={this.state.activity}
        />

        <Changes
          openChangesModal={this.state.openChangesModal}
          closeModal={this.closeModal}
          changes={[]}
        />

        <Import
          state={this.state}
          closeModal={this.closeModal}
          onImport={this.importTimecard}
        />

        <Report
          openReportModal={this.state.openReportModal}
          closeModal={this.closeModal}
          reportType="Timecards"
          locationProps={this.props}
        />

        <CreateSendEFT
          openSendEFTmodal={openSendEFTmodal}
          closeModal={this.closeModal}
          createEFTFile={this.props.createEFTFile}
          clearTimecardStates={this.props.clearTimecardStates}
          batchNo={batchNo}
        />

        <ResendSTPFile
          stateDate={this.state}
          openResendSTPFilemodal={openResendSTPFilemodal}
          closeModal={this.closeModal}
          resendSTPFile={this.props.resendSTPFile}
          clearTimecardStates={this.props.clearTimecardStates}
        />

        <SendYearEndSTPFile
          openSendYearEndSTPFilemodal={openSendYearEndSTPFilemodal}
          closeModal={this.closeModal}
        />

        <TimeCardMoreDetail
          state={this.state}
          getTimecardSummary={this.getTimecardSummary}
          showTallisTabPane={showTallisTabPane}
          refreshEmployees={this.refreshEmployees}
          getEmployeeList={this.getEmployeeList}
          updateTimecardSummaryState={this.updateTimecardSummaryState}
          openTimeCardMoreDetail={openTimeCardMoreDetail}
          closeModal={this.closeModal}
          refreshTimecards={this.getNewTimecardList}
          employeeList={this.state.employeeList}
        />

        <DeclineTimeCard
          openDeclineTimeCardmodal={openDeclineTimeCardmodal}
          closeModal={this.closeModal}
          tran={tran}
          guid={this.state.selectedGuid}
          locationProps={this.props}
          showTallisTabPane={showTallisTabPane}
          getTimecardTallies={this.getTimecardTallies}
          approvers={approvers}
        />

        <Move
          closeModal={this.closeModal}
          stateDate={this.state}
          moveBatch={this.moveBatch}
        />
        {/*  <ModifyTimeCard
          openModifyModal={this.state.openModifyModal}
          closeModal={this.closeModal}
          modifyPO={this.modifyPO}
        />
      */}

        <ModifyTimecard
          openModifyModal={this.state.openModifyModal}
          closeModal={this.closeModal}
          modifyTimecard={this.modifyTimecard}
          moveToApproveTimecard={this.moveToApproveTimecard}
          moveTimecard={this.moveTimecard}
          tab={tab}
          action={this.state.moveAction}
        />
        <ModileResponsiveMenu props={this.props} active="timecard" />
        <CheckApproval
          openCheckApprovalModal={this.state.openCheckApprovalModal}
          closeModal={this.closeModal}
          state={this.state}
          moveToPrevApprover={this.moveToPrevApprover}
          moveToNextApprover={this.moveToNextApprover}
        />

        {
          userType === 'admin' && this.state.showTransferRecordsModal && (
            <TransferRecords
              closeModal={() => this.setState({ showTransferRecordsModal: false })}
              selectedItemCount={this.state.allSelected ? undefined : this.state.selectedMultipleGuid.length || 1}
              itemNameList={this.state.allSelected ? undefined :
                [...new Set(this.state.selectedMultipleGuid.length ? this.state.selectedMultipleGuid: [...this.state.selectedMultipleGuid, this.state.selectedGuid])].map(guid => {
                  const timecard = this.state.timecardsList.find(t => t.guid === guid);
                  return `TM#${timecard.guid} ${timecard.employeeName}`
                }) }
              onSubmit={this.handleTransferTimecards}
            />
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timecard: state.timecard,
    setup: state.setup,
    pageData: state.pagination.pageData["timecards"],
    user: state.user,
    ws: state.webSocket,
  };
};

export default connect(mapStateToProps, {
  getTimecardTallies: TimecardActions.getTimecardTallies,
  getTimecardList: TimecardActions.getTimecardList,
  getNewTimecardList: TimecardActions.getNewTimecardList,
  insertTimecard: TimecardActions.insertTimecard,
  updateTimecard: TimecardActions.updateTimecard,
  getTimecard: TimecardActions.getTimecard,
  getTimecardSummary: TimecardActions.getTimecardSummary,
  updateSummary: TimecardActions.updateSummary,
  deleteTimecard: TimecardActions.deleteTimecard,
  moveTimecard: TimecardActions.moveTimecard,
  moveToApproverTimecard: TimecardActions.moveToApproverTimecard,
  declineTimecard: TimecardActions.declineTimecard,
  sendForApproval: TimecardActions.sendForApproval,
  holdTimecard: TimecardActions.holdTimecard,
  approveTimecard: TimecardActions.approveTimecard,
  addAttachment: TimecardActions.addAttachment,
  getAttachmentsList: TimecardActions.getAttachmentsList,
  getEmployeeList: TimecardActions.getEmployeeList,
  refreshEmployees: TimecardActions.refreshEmployees,
  addComment: TimecardActions.addComment,
  regenerateTimecard: TimecardActions.regenerateTimecard,
  sendReports: TimecardActions.sendReports,
  lockAndInvoice: TimecardActions.lockAndInvoice,
  createEFTFile: TimecardActions.createEFTFile,
  sendPayslips: TimecardActions.sendPayslips,
  resendSTPFile: TimecardActions.resendSTPFile,
  moveBatch: TimecardActions.moveBatch,
  exportTimecard: TimecardActions.exportTimecard,
  exportDistribution: TimecardActions.exportDistribution,
  exportTPHPayroll: TimecardActions.exportTPHPayroll,
  importTimecard: TimecardActions.importTimecard,
  postData: TimecardActions.postData,
  getAttachment: TimecardActions.getAttachment,
  taxBalance: TimecardActions.taxBalance,
  getBtachList: SetupActions.getBtachList,
  deleteBatch: SetupActions.deleteBatch,
  updateBatch: SetupActions.updateBatch,
  insertBatch: SetupActions.insertBatch,
  clearSetupStates: SetupActions.clearSetupStates,
  clearTimecardStates: TimecardActions.clearTimecardStates,
  modifyTimecard: TimecardActions.modifyTimecard,
  downloadTimecard: TimecardActions.downloadTimecard,
  getAccountDetails: getAccountDetails,
  setWebSocketActiveModule: WebSocketActions.setWebSocketActiveModule,
  moveToNextApprover: TimecardActions.moveToNextApprover,
  moveToPrevApprover: TimecardActions.moveToPrevApprover,
  setLastPage: PaginationActions.setLastPageNumber,
  resetLastPage: PaginationActions.resetLastPage,
  resendEmail: TimecardActions.resendEmail,
  transferTimecards: TimecardActions.transferTimecards
})(Timecards);
