import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { updateApprovalGroup } from "../../../Actions/DocumentActions/DocumentActions";
const DocumentMoreDetails = (props) => {
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let {
    showDocTallisTabPane,
    closeModal,
    updateDocumentSummaryState,
    getDocument,
  } = props;
  showDocTallisTabPane = showDocTallisTabPane
    ? showDocTallisTabPane.toLowerCase()
    : "";

  const dispatch = useDispatch();
  const periodOption = () => {
    if (props.state?.approvalsGroups.length > 0) {
      let newArray = [];
      props.state.approvalsGroups.map((item) =>
        newArray.push({ value: item.approvalGroup, label: item.approvalGroup })
      );
      return newArray;
    }
  };
  const handleChangeType = (data) => {
    setState((prev) => ({
      ...prev,
      approverGroup: data,
    }));
  };
  const onChangeHandlerDate = (date) => {
    setState((prev) => ({
      ...prev,
      docDate: new Date(date).getTime(),
    }));
  };
  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const activeDoc = props?.state?.getDocList.find(
      (doc) => doc.id === props?.state?.activeDoc
    );
    setState((prev) => ({
      ...prev,
      ...props?.state,
      docDescription: activeDoc?.description,
      docDate: new Date(activeDoc?.docDate).getTime(),
    }));
  }, [props]);

  const onSave = async (e) => {
    e.preventDefault();

    let documentSummary = {
      approvalGroup: state.approverGroup.value,
      guid: state.selectedGuid,
    };

    setIsLoading(true);

    await dispatch(updateApprovalGroup(documentSummary));

    updateDocumentSummaryState();

    const { getDocList, activeDoc } = props.state;
    const activeDocumentToUpdate = getDocList.find((fi) => fi.id === activeDoc);

    await getDocument(activeDocumentToUpdate, true);
    closeModal("openDocumentMoreModal");

    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={state?.openDocumentMoreModal}
        onHide={() => closeModal("openDocumentMoreModal")}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <h6 className="text-left def-blue">
                            Documents Details
                          </h6>
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={onSave}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button>
                            <button
                              onClick={() =>
                                props.closeModal("openDocumentMoreModal")
                              }
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>
                            <div className="order-display-main ">
                              <Dropdown
                                alignRight="false"
                                drop="down"
                                className="border-0"
                              >
                                <Dropdown.Toggle id="dropdown-basic-two">
                                  <img
                                    src="images/more.png"
                                    className="img-fluid"
                                    alt="user"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    variant="success"
                                    onClick={() => {}}
                                  >
                                    <button
                                      type="button"
                                      className="btn-save def-blue"
                                    >
                                      <img
                                        src="images/display-icon.png"
                                        className="mr-2"
                                        alt="display-icon"
                                      />
                                      Display
                                    </button>
                                  </Dropdown.Item>

                                  {/* <Dropdown.Item variant="success">
                                    <button
                                      onClick={() => {}}
                                      type="button"
                                      className="btn-save def-blue"
                                    >
                                      <img
                                        src="images/save-check.png"
                                        className="mr-2"
                                        alt="display-icon"
                                      />
                                      Save
                                    </button>
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forget-body">
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Description</label>
                            <div className="modal_input">
                              <input
                                name="docDescription"
                                type="text"
                                className={
                                  showDocTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="docdesc"
                                value={state?.docDescription || ""}
                                onChange={
                                  showDocTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Approval Group</label>
                            <Select
                              className={
                                showDocTallisTabPane !== "draft"
                                  ? "disable_bg disable_border"
                                  : "width-selector"
                              }
                              value={state?.approverGroup}
                              classNamePrefix="custon_select-selector-inner"
                              options={periodOption()}
                              onChange={(e) =>
                                showDocTallisTabPane === "draft"
                                  ? handleChangeType(e)
                                  : ""
                              }
                              isDisabled={showDocTallisTabPane !== "draft"}
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Tran</label>
                            <div className="modal_input">
                              <input
                                name="tran"
                                type="text"
                                className={
                                  showDocTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="doc-tran"
                                value={state?.tran || ""}
                                onChange={
                                  showDocTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" col-12">
                          <div className="form-group custon_select">
                            <label htmlFor="id_dt">Date</label>
                            <div className="modal_input datePickerUP">
                              <DatePicker
                                name="docDate"
                                id="docdate"
                                tabIndex="3334"
                                dateFormat="d MMM yyyy"
                                autoComplete="off"
                                onChange={
                                  showDocTallisTabPane === "draft"
                                    ? onChangeHandlerDate
                                    : null
                                }
                                className={
                                  showDocTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : ""
                                }
                                disabled
                                selected={state?.docDateUnix ?? ""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DocumentMoreDetails;
