import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from "react-datepicker";
import moment from "moment";
import TopNav from "../../Common/TopNav/TopNav";
import AdminHeader from "../../Common/AdminHeader/AdminHeader";
// import VideoTutorials from "../../Common/Alerts/VideoTutorials";
import {
  fetchAuditLogs,
  fetchAuditOptions,
} from "../../../Actions/AuditLogsActions/AuditLogsAction";

import "react-datepicker/dist/react-datepicker.css";
import "./AuditLogs.css";

export default function AuditLogs() {
  const paginationTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const pagingConfig = {
    page: 1,
    paginationSize: 5,
    sizePerPage: 50,
    withFirstAndLast: true,
    showTotal: true,
    paginationTotalRenderer: paginationTotal,
    hideSizePerPage: true,
    firstPageText: "First",
    lastPageText: "Last",
    nextPageText: "Next",
    prePageText: "Previous",
    hidePageListOnlyOnePage: true,
  };

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auditLogs.loading);
  const auditLogs = useSelector((state) => state.auditLogs.logs);
  const index = useSelector((state) => state.auditLogs.index);
  const totalRecords = useSelector((state) => state.auditLogs.totalRecords);
  const [pagination, setPagination] = useState(pagingConfig);
  const categoryOptions = useSelector((state) => state.auditLogs.categories);
  const sortOptions = useSelector((state) => state.auditLogs.sort);
  const [keywordText, setKeywordText] = useState("");
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [sort, setSort] = useState("");
  const [sortOrder, setSortOrder] = useState("Ascend");
  const [currentIndex, setCurrentIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [showCustomPageSize, setShowCustomPageSize] = useState(false);

  const columns = [
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "tran",
      text: "Tran#",
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "userId",
      text: "User Id",
    },
    {
      dataField: "dateTimeUnix",
      text: "TimeStamp",
      formatter: (timestamp) => {
        return moment(timestamp * 1000).format("YYYY/MM/DD hh:mm A");
      },
    },
  ];

  function search() {
    dispatch(
      fetchAuditLogs(
        keyword,
        fromDate,
        toDate,
        category,
        sort,
        sortOrder,
        currentIndex,
        pageSize
      )
    );
  }

  function initPage() {
    dispatch(fetchAuditOptions());
  }

  function updatePagination() {
    const config = {
      ...pagingConfig,
      page: parseInt(currentIndex),
      sizePerPage: parseInt(pageSize),
      totalSize: parseInt(totalRecords),
    };

    setPagination(config);
  }

  const handleTableChange = (_type, { page }) => {
    setCurrentIndex(page);
  };

  function handleSearch() {
    setKeyword(keywordText);
  }

  function handleKeywordChange(e) {
    e.preventDefault();
    setKeywordText(e.target.value);
  }

  function handleKeywordKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      setKeyword(e.target.value);
    }
  }

  function handleCategoryChange() {
    return (e) => setCategory(e.target.value);
  }

  function handleSortOrderChange(e) {
    setSortOrder(e.target.value);
  }

  function handleSortChange(e) {
    setSort(e.target.value);
  }

  function handleToDateChange(date) {
    setToDate(date);
  }

  function handleFromDateChange(date) {
    setFromDate(date);
  }

  function handlePageSizeChange(e) {
    const size = e.target.value;
    if (size === "CUSTOM") {
      setShowCustomPageSize(true);
    } else {
      setShowCustomPageSize(false);
      setPageSize(e.target.value);
    }
  }

  function handleCustomPageSizeKeyboardEvent(e) {
    const updatePageSize = () => {
      const value = parseInt(e.target.value);
      if (!isNaN(value) && value > 0) {
        setPageSize(e.target.value);
      }
    };

    if (e.type === "blur") {
      updatePageSize();
    } else if (e.type === "keydown" && e.code === "Enter") {
      e.preventDefault();
      updatePageSize();
    }
  }

  useEffect(initPage, []);

  useEffect(updatePagination, [auditLogs, index, totalRecords, pageSize]);

  useEffect(search, [
    keyword,
    fromDate,
    toDate,
    category,
    sort,
    sortOrder,
    currentIndex,
    pageSize,
  ]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <div className="user_setup_main">
        <header>
          <TopNav />
          <AdminHeader title={"Audit Logs"} />
          {/* <VideoTutorials /> */}
        </header>
        <div className="table_white_box table_white_box_sass">
          <div className="filter-panel">
            <Row>
              <Col>
                <div className="search-filters-wrapper">
                  <Form>
                    <Row>
                      <Form.Group as={Col}>
                        <InputGroup>
                          <Form.Control
                            placeholder="Search"
                            type="text"
                            onChange={handleKeywordChange}
                            onKeyDown={handleKeywordKeyDown}
                          />
                          <button
                            type="button"
                            className="search-button"
                            onClick={handleSearch}
                          >
                            <img src="images/search-icon.png" alt="Search" />
                          </button>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Control
                          as="select"
                          onChange={handleCategoryChange()}
                        >
                          <option value="">Select</option>
                          {categoryOptions.map((category) => (
                            <option
                              key={category.option}
                              value={category.option}
                            >
                              {category.option}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Row>
                          <Col xs="auto">
                            <Form.Label
                              htmlFor="fromDatePicker"
                              className="col-form-label"
                            >
                              From
                            </Form.Label>
                          </Col>
                          <Col>
                            <DatePicker
                              id="fromDatePicker"
                              className="form-control"
                              selected={fromDate}
                              onChange={handleFromDateChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Row>
                          <Col xs="auto">
                            <Form.Label
                              htmlFor="toDatePicker"
                              className="col-form-label"
                            >
                              To
                            </Form.Label>
                          </Col>
                          <Col>
                            <DatePicker
                              id="toDatePicker"
                              className="form-control"
                              selected={toDate}
                              onChange={handleToDateChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col lg="auto">
                <div className="sort-config-wrapper">
                  <Form>
                    <Row>
                      <Form.Group as={Col} className="sortby-controller">
                        <Form.Control
                          as="select"
                          value={sort}
                          onChange={handleSortChange}
                        >
                          <option value="">Sort By...</option>
                          {sortOptions.map((sortOption) => (
                            <option
                              key={sortOption.option}
                              value={sortOption.option}
                            >
                              {sortOption.option}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Control
                          as="select"
                          value={sortOrder}
                          onChange={handleSortOrderChange}
                        >
                          <option value="Ascend">ASC</option>
                          <option value="Descend">DESC</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Control
                          as="select"
                          onChange={handlePageSizeChange}
                        >
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                          <option value="CUSTOM">Custom</option>
                        </Form.Control>
                      </Form.Group>
                      {showCustomPageSize ? (
                        <Form.Group as={Col}>
                          <Form.Control
                            type="number"
                            // onBlur={handleCustomPageSizeKeyboardEvent}
                            onKeyDown={handleCustomPageSizeKeyboardEvent}
                          ></Form.Control>
                        </Form.Group>
                      ) : null}
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
          <div id="audit-logs-table-wrapper">
            <BootstrapTable
              remote
              striped
              hover
              keyField="id"
              data={auditLogs}
              columns={columns}
              loading={isLoading}
              onTableChange={handleTableChange}
              pagination={paginationFactory(pagination)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
