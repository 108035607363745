import axios from "axios";
import {
  getCognitoJWTTokens,
  getProductJWTTokens,
  refreshCognitoJWTTokens,
  refreshProductJWTTokens,
} from "./authHelpers";

const setJWTSessionToken = async (token) => {
  const baseUrl = localStorage.getItem("API_URL");
  if (baseUrl) {
    await axios({
      url: `${baseUrl}/DPFAPI/UserRequest`,
      method: "post",
      data: {
        actionType: "SetJWTSessionToken",
        token,
      },
    });
  }
};

const axiosAPIGatewayClient = axios.create({
  baseURL: localStorage.getItem("API_GATEWAY_URL"),
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosAPIGatewayClient.interceptors.request.use((config) => {
  // set access tokens to the request if they are available
  // precedence is product token -> cognito token

  const { accessToken } = getProductJWTTokens();
  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: accessToken,
    };
    return config;
  }

  const { accessToken: cognitoAccessToken } = getCognitoJWTTokens();

  if (cognitoAccessToken) {
    config.headers = {
      ...config.headers,
      Authorization: cognitoAccessToken,
    };
    return config;
  }

  return config;
});

axiosAPIGatewayClient.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;

    // try to refresh access tokens on unauthorized
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // retry flag to avoid a token refresh loop

      try {
        // try to refresh the product jwt tokens and retry original request
        await refreshProductJWTTokens();
        let { accessToken: productToken } = getProductJWTTokens();
        await setJWTSessionToken(productToken);
        return Promise.resolve(axios.request(originalRequest));
      } catch (error) {
        try {
          // cognito access token could be expired, try to refresh it
          await refreshCognitoJWTTokens();

          // try to refresh the product jwt tokens again
          await refreshProductJWTTokens();
          let { accessToken: productToken } = getProductJWTTokens();
          await setJWTSessionToken(productToken);
          return Promise.resolve(axios.request(originalRequest));
        } catch (error) {
          // cognito session is also expired. logout the user and return to login

          // clear storage and navigate to sign in
          localStorage.clear();
          window.location = `${window.location.origin}/login`;
          return Promise.reject(error);
        }
      }
    }
  }
);

export default axiosAPIGatewayClient;
